{
  BitmapText
  Container
  Graphics
  Sprite
  Texture
  type TextOptions
} from pixi.js

type { Game } from ../game.civet
type { Scene } from ./scene.civet

{ Button, ShadowedText } from ../ui.civet
{ Settings } from ./settings.civet
{ WorldMap } from ./world-map.civet

export class Title implements Scene
  stage := new Container
  game: Game

  update(_dt: number): void
  draw(_t: number): void

  enter(): void
    @game.playMusic "peg"

  pointerMove(_pos: unknown): void
  action1(): void

  @(@game: Game)
    bgSprite := new Sprite Texture.from "assets/title.png"
    @stage.addChild bgSprite

    titleText := ShadowedText {
      text: "Stay Pegged"
      style:
        fontFamily: "m5x7"
        fontSize: 120
    }, x: 4, y: 4
    titleText.x = 1280 / 2
    titleText.y = 720 / 2
    @stage.addChild titleText

    menuOptions := [{
      text: "New Game"
      onSelect: =>
        @game.replaceScene new WorldMap @game
    }, {
      text: "Continue"
      onSelect: =>
        @game.replaceScene new WorldMap @game
    }, {
      text: "Settings"
      onSelect: =>
        @game.pushScene new Settings @game
    }, {
      text: "Quit"
      onSelect: =>
        window.close()
    }]

    l := menuOptions.length

    for each {text, onSelect}, i of menuOptions
      button := Button {
        text: text
        x: 1280 - 220
        y: 720 - 60 * (l - i)
        width: 200
        height: 40
        fontSize: 30
        onSelect
      }

      @stage.addChild button
