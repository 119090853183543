{
  Container
  Graphics
  GraphicsContext
} from pixi.js

{ rand } from ../util.civet

export class NoiseBackground
  stepDuration := 1/12
  moveBgAt = @stepDuration
  width: number
  height: number
  layers: Container[]
  container: Container
  grainSize = 2

  @(@width: number, @height: number, layerCount: number = 2, grainSize = 3)
    @grainSize = grainSize
    { width, height } := @
    @container := new Container
    @container.alpha = 1/16

    @layers = []
    i .= 0
    while i++ < layerCount
      bg := new Container

      noiseGraphics := new GraphicsContext

      y .= 0
      while y < height
        x .= 0
        while x < width
          if rand(10) is 0
            noiseGraphics.rect x, y, grainSize, grainSize
            noiseGraphics.fill 0xffffff
          x += grainSize
        y += grainSize

      n1 := new Graphics noiseGraphics
      n2 := new Graphics noiseGraphics
      n2.x = width
      n3 := new Graphics noiseGraphics
      n3.y = height
      n4 := new Graphics noiseGraphics
      n4.x = width
      n4.y = height
      bg.addChild n1
      bg.addChild n2
      bg.addChild n3
      bg.addChild n4

      @container.addChild bg
      @layers.push bg

  update = (dt: number) =>
    @moveBgAt -= dt

    if @moveBgAt < 0
      { grainSize } := @
      @moveBgAt += @stepDuration

      @layers.forEach (layer) =>
        x := rand(@width)
        y := rand(@height)
        layer.x = -(x - x % grainSize)
        layer.y = -(y - y % grainSize)
