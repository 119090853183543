{
  type PointData
  type Renderer
  Container
  Graphics
  GraphicsContext
  Rectangle
  Sprite
  Texture
  TextureStyle
} from pixi.js

{ Ball } from ./ball.civet

{ rand, randomItem } from ./util.civet

TextureStyle.defaultOptions.scaleMode = 'nearest'

// Create a master Graphicscontext
export circleContext := new GraphicsContext()
.circle(0, 0, 64)
.fill(0xffffff)

export lineContext := new GraphicsContext()
.lineTo(1, 0)
.stroke(0xffffff)

export function updateBallGraphics(ball: Ball, graphics: Sprite)
  graphics.x = ball.x
  graphics.y = ball.y
  graphics.rotation = ball.rotation
  graphics.visible = true

  graphics.texture = ball.texture

  return graphics

type GraphicsProps = Partial<{
  rotation: number
  scale: PointData
  tint: number
  x: number
  y: number
}>

export function addStreak(container: Container, { rotation, scale, tint, x, y}: GraphicsProps)
  streak := new Graphics lineContext

  streak.rotation = rotation if rotation?
  streak.scale = scale if scale?
  streak.tint = tint if tint?
  streak.x = x if x?
  streak.y = y if y?

  // TODO: actual effect object that gets updated so we can animate properly
  container.addChild(streak)
  setTimeout (=> container.removeChild(streak)), 100

  return streak

export function addBlast(container: Container, x: number, y: number, radius: number, tint: number)
  blast := new Graphics circleContext

  blast.x = x
  blast.y = y
  blast.scale = radius / 64
  blast.tint = tint

  // TODO: actual effect object that gets updated so we can animate properly
  container.addChild(blast)
  setTimeout (=> container.removeChild(blast)), 100

  return blast

export nineSliceBorder := "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAQAAAD8IX00AAAAEklEQVQI12P4DwYMQMQApkAAAKdtD/E89U+YAAAAAElFTkSuQmCC"

hollowCircleContext := new GraphicsContext()
.stroke width: 2, color: 0xffffff
.circle 0, 0, 10
.stroke()

export hollowCircle := =>
  new Graphics hollowCircleContext

export function sheetToTextures(sheet: Texture, width: number, height: number)
  textures := []

  for y of [0...sheet.height / height]
    for x of [0...sheet.width / width]
      textures.push new Texture
        source: sheet.source
        frame: new Rectangle x * width, y * height, width, height

  return textures

export function generateFace(renderer: Renderer)
  eyesTexture := Texture.from "assets/eyes.png"
  mouthsTexture := Texture.from "assets/mouths.png"
  accessoriesTexture := Texture.from "assets/accessories.png"

  container := new Container

  i .= rand(eyesTexture.width / 16)
  eyeTexture := new Texture
    source: eyesTexture.source
    frame: new Rectangle i * 16, 0, 16, 16

  i = rand(mouthsTexture.width / 16)
  mouthTexture := new Texture
    source: mouthsTexture.source
    frame: new Rectangle (i * 16), 0, 16, 16

  i = rand(accessoriesTexture.width / 16)
  accessoryTexture := new Texture
    source: accessoriesTexture.source
    frame: new Rectangle (i * 16), 0, 16, 16

  circle := new Graphics()
    .circle 8, 8, 8
    .fill 0xffffff
    .circle 8, 8, 7.5
    .stroke width: 1, color: 0x000000

  circle.tint = randomItem lighterColors

  container.addChild circle
  container.addChild new Sprite mouthTexture
  container.addChild new Sprite eyeTexture
  container.addChild new Sprite accessoryTexture

  renderer.generateTexture container

export retrowaveColorPalette := [
  0xf36688
  0xda3182
  0x9e316b
  0xbb3ad3
  0x684dda
  0x5033db
  0x261a5a
  0x1a1044
] as const

export lighterColors := [
  0xf36688
  0xda3182
  0x9e316b
  0xbb3ad3
  0x684dda
]
