{
  Application
  Container
} from pixi.js

{
  type LevelData
  type PegData
  makePegGraphic
} from ../scenes/level.civet

{
  circularCollision
} from ../util.civet

{
  round
} := Math

WIDTH := 640
HEIGHT := 360

tools: PegData[] := [
  {type: "peg", x: 0, y: 0, r: 6, multiplier: 0, hits: 3}
  {type: "ghost", x: 0, y: 0, r: 6, multiplier: 0, hits: 1}
]

activeToolIndex .= 0

window.addEventListener 'contextmenu', (e) => e.preventDefault()

window.addEventListener 'pointerdown', (e) =>
  if e.defaultPrevented
    return

  for el of e.composedPath()
    if el <? HTMLElement and (el.tagName is "INPUT" or el.tagName is "TEXTAREA")
      return

  e.preventDefault()

  if e.button is 0
    if pos := screenPos(e)
      action(pos)
  else if e.button is 2
    if pos := screenPos(e)
      erase(pos)

function erase({x, y}: {x: number, y: number})
  x = round(x)
  y = round(y)

  for [peg, graphic] of pegMap
    if circularCollision(x, y, 6, peg.x, peg.y, peg.r)
      removePeg peg
      return

function removePeg(peg: PegData)
  if graphic := pegMap.get peg
    app.stage.removeChild graphic
  pegMap.delete peg

  index := levelData.pegs.indexOf peg
  if index >= 0
    levelData.pegs.splice index, 1

function screenPos(e: MouseEvent)
  return unless canvas
  // get angle from center top of canvas to mouse
  { top, left, width, height } := canvas.getBoundingClientRect()

  x := (e.clientX - left) / (width / WIDTH)
  y := (e.clientY - top) / (height / HEIGHT)

  return {x, y}

pegMap := new Map<PegData, Container>()

levelData: LevelData .=
  pegs: []
  targetScore: 0

function action({x, y}: {x: number, y: number})
  x = round(x)
  y = round(y)

  if x < -10 or x > WIDTH + 10 or y < -10 or y > HEIGHT + 10
    return

  peg := {
    ...tools[activeToolIndex]
    x
    y
  }

  addPeg peg

function addPeg(peg: PegData)
  graphic := makePegGraphic(peg)
  pegMap.set peg, graphic
  app.stage.addChild graphic

  levelData.pegs.push peg

function reset()
  levelData.pegs = []
  levelData.targetScore = 0

  for [peg, graphic] of pegMap
    app.stage.removeChild graphic

  pegMap.clear()

export {}

let canvas: HTMLCanvasElement?
app := new Application

document.addEventListener 'paste', (e) =>
  e.preventDefault()
  const text = e.clipboardData?.getData('text')

  console.log "paste", text
  if text
    try
      reset()
      data := JSON.parse(text)
      for peg of data.pegs
        addPeg peg
      levelData.targetScore = data.targetScore
    catch e
      console.error e

document.addEventListener 'dragover', (e) =>
  e.preventDefault()
document.addEventListener 'dragenter', (e) =>
  e.preventDefault()
document.addEventListener 'drop', (e) =>
  e.preventDefault()

  text := await e.dataTransfer?.files[0]?.text()
  if !text
    console.error "No text data"
    return

  try
    if data := JSON.parse text
      reset()
      for peg of data.pegs
        addPeg peg
      levelData.targetScore = data.targetScore
  catch e
    console.error e

async do
  await app.init
    width: WIDTH
    height: HEIGHT
    resolution: 2
  canvas = app.canvas
  document.body.appendChild app.canvas

  actions := document.createElement 'div'
  actions.style.position = 'absolute'
  actions.style.top = '0'
  actions.style.left = '0'

  document.body.appendChild actions
  Btn := (text: string, onClick: () => void) =>
    button := document.createElement 'button'
    button.textContent = text
    button.addEventListener 'click', onClick
    actions.appendChild button

  Btn "Reset", reset
  Btn "Save", () => {
    data := JSON.stringify(levelData, null, 2)

    localStorage.setItem 'customLevel', data
  }
  Btn "Download", () => {
    data := JSON.stringify(levelData, null, 2)
    const blob = new Blob([data], {type: 'application/json'})
    const url = URL.createObjectURL(blob)
    const a = document.createElement 'a'
    a.href = url
    a.download = 'level.json'
    a.click()
    URL.revokeObjectURL(url)
  }
  Btn "Copy", () => navigator.clipboard.writeText(JSON.stringify(levelData, null, 2))

  actions.appendChild document.createElement 'br'

  // Add radio buttons for tools
  for [i, tool] of tools.entries()
    input := document.createElement 'input'
    input.type = 'radio'
    input.name = 'tool'
    input.value = i.toString()
    input.checked = i is activeToolIndex
    input.addEventListener 'change', (e) =>
      activeToolIndex = i

    h3 := document.createElement 'h3'
    h3.textContent = tool.type

    label := document.createElement 'label'
    label.appendChild h3
    label.appendChild input
    actions.appendChild label

  targetScoreLabel := document.createElement 'label'
  targetScoreInput := document.createElement 'input'
  targetScoreInput.type = 'number'
  targetScoreInput.value = levelData.targetScore.toString()
  targetScoreInput.addEventListener 'input', (e) =>
    levelData.targetScore = parseInt targetScoreInput.value

  targetScoreLabel.textContent = 'Target Score'
  targetScoreLabel.appendChild targetScoreInput

  actions.appendChild targetScoreLabel
