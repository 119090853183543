const Vg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAALdJREFUeJzt0kEKgDAQBMHo//8cXyAEETpo1XkPO9BjAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADx11A+8aC7e7b75KzuWnPUD/JsASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAGxdtCgIoxokH/gAAAABJRU5ErkJggg=="
  , Zg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMRJREFUeJzt2cEJgDAQAMFo/z1rBYJIYDXOvPPIwZJHbgwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAp7b6AhMdN8+9feZV5rhlry/AvwmQlABJCZCUAEkJkJQASQmQlABJfeE3ffZmoNo0rDLHVF5AUgIkJUBSAiQlQFICJCVAUgIkJUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC6cfGIGJzDSAXcAAAAASUVORK5CYII="
  , Og = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQRJREFUeJzt3EEOgyAAAEHa//+5XvoAbDRLceZsIpgNB4iMAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPCrVz2AwGfyuau/TfXepb3rAfBsAiQlQFICJCVAUgIkJUBSAiQlQFL/sOs+e4Iwa/U5P2q+VkBSAiQlQFICJCVAUgIkJUBSAiQlQAAAAAAAALjF0v8LfF19q9Qut2NtcduWs2BSAiQlQFICJCVAUgIkJUBSAiQlQFJL75KftMXJwNhnHlOsgKQESEqApARISoCkBEhKgKQESEqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALCYAyxPCk11IIKCAAAAAElFTkSuQmCC"
  , xg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAKNJREFUeJzt0kEKgDAQBMHo//9s7oKQg9CBVD1g2YEeAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIC3q35gwfPzvd03H7X3rh/gbAIkJUBSAiQlQFICJCVAUgIkJUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD5M8l0CFIaH1a0AAAAASUVORK5CYII="
  , Gg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMNJREFUeJzt2FEKgCAQQEHr/neuEwQS0gucOYCu8PBjxwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB466gHmHAtPu/vb97qvWc9AHsTICkBkhIgKQGSEiApAZISICkBkiq35LMb/9Uzuvebe6f4AUkJkJQASQmQlABJCZCUAEkJkJQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4cAOkEgYno1RAjwAAAABJRU5ErkJggg=="
  , Pg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMRJREFUeJzt2VEKgCAUAEHr/neuEwQRwmbNHECesvihYwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8tdUDTHRMXm/22bx9vsReD8C/CZCUAEkJkJQASQmQlABJCZCUAEmt8Jp+9wdhhb3M9IlzcQOSEiApAZISICkBkhIgKQGSEiApAQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAcOEEHzQEJ1i/68EAAAAASUVORK5CYII="
  , Lg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPJJREFUeJzt28EJAjEQQNFVPGsT9l+RTWgDWoJRA3+J752XZQY+uYRsGwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMC3DvUA7zwu1+fId+f7bWiX2f8btcoesx3rAfhvAiQlQFICJCVAUgIkJUBSAiQlQFKneoBZRm8G9m6VPUY5AUkJkJQASQmQlABJCZCUAEkJkJQASe36vcAnVnkjscoeo5yApARISoCkBEhKgKQESEqApARISoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD96AcqZHIWThXv+AAAAAElFTkSuQmCC"
  , Tg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOZJREFUeJzt2dEJxCAQQMF4XP8tmxJOcsIDM9NA3PDww70uAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgKdGfYBf5pyz+O4YY+u/OWWO3T71AXg3AZISICkBkhIgKQGSEiApAZISIKlvfYBdVh/8o4XEslPmWOUGJCVAUgIkJUBSAiQlQFICJCVAUgIkdcwm5JTNwClzrHIDkhIgKQGSEiApAZISICkBkhIgKQECAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPzpBr52FDoJ2rImAAAAAElFTkSuQmCC"
  , qg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAARBJREFUeJzt3LENwkAQAEGDiKEJ+q+IJqABaOGRHy08M7GDO2v1gS172wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIBPO9QDTPQcvO7bd15ljyHHegD+mwBJCZCUAEkJkJQASQmQlABJCZDULzxNH30zMNvse7PKHlM5AUkJkJQASQmQlABJCZCUAEkJkJQASZ3qAWZ5XK5D153vtw9Pss8qe4xyApISICkBkhIgKQGSEiApAZISICkBkvrq7wXetMpfpVbZY4gTkJQASQmQlABJCZCUAEkJkJQASQkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGCnFx9ODFB8GIciAAAAAElFTkSuQmCC"
  , Mg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOdJREFUeJzt2cENwyAQAEETpf+WSQlGDtJGZKYBc9aKB3ddAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAE+N+gB35pyz+O4YY+u/OWWO3V71AfhvAiQlQFICJCVAUgIkJUBSAiQlQFLv+gB3Vh/yo0XDslPm2M0NSEqApARISoCkBEhKgKQESEqApARI6uc3IatO2TScMscqNyApAZISICkBkhIgKQGSEiApAZISIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAlz4AyBRANKtfJAAAAABJRU5ErkJggg=="
  , jg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAORJREFUeJzt20EKAyEQAEE3//9z8gWTCL2ZVJ1FHGg8uOxaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJ+66gNseG6u253l9H67psxx1KM+AP9NgKQESEqApARISoCkBEhKgKQESOrWr+RvGvFlYM2ZY4sbkJQASQmQlABJCZCUAEkJkJQASQmQ1C+8pk/5l2LKHEe5AUkJkJQASQmQlABJCZCUAEkJkJQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC+9AJIgwo6AsjwSQAAAABJRU5ErkJggg=="
  , Dg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQlJREFUeJzt2VENAjEQQMEFRWhADoKQgwYcgQUSSt6lzAi4dC8v/djOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAL92qg+wyu1xea383v36TP7NLnN86lwfgP8mQFICJCVAUgIkJUBSAiQlQFICJHXoLfnMPi8Du8yxmhuQlABJCZCUAEkJkJQASQmQlABJCZDUFtv0mX1eGnaZ41NuQFICJCVAUgIkJUBSAiQlQFICJCVAUofeks/s8zKwyxyruQFJCZCUAEkJkJQASQmQlABJCZCUAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvvQGsREgTXE9GeIAAAAASUVORK5CYII="
  , Xg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAO9JREFUeJzt20EKwjAQQNFGvP+V496NFQa/1vcOUDLlk0VCjgMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAZ6tewCt77z35vbVOjzz6b64yx7RbvQD+mwBJCZCUAEkJkJQASQmQlABJCZDUvV7AlDduBr7aVeY4yw5ISoCkBEhKgKQESEqApARISoCkBEjqF47dR99SnH2aseavJK4yxyg7ICkBkhIgKQGSEiApAZISICkBkhIgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPAhDyeVEENfDxcqAAAAAElFTkSuQmCC"
  , Yg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQFJREFUeJzt20EKwyAUQEFTev8rpxfowhLLa83MOhANDxd+MgYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwbUe9gAnn5HP/sJeVtvguj3oB3JsASQmQlABJCZCUAEkJkJQASQmQ1E/fkn9oi8nA2GcfU5yApARISoCkBEhKgKQESEqApARISoCknuG7Z2/8eW/190smK05AUgIkJUBSAiQlQFICJCVAUgIkJUBS5SRk9c373SYr/gmBqwRISoCkBEhKgKQESEqApARISoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABe9AC0UB1POvDJpAAAAAElFTkSuQmCC"
  , Hg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQdJREFUeJzt3FEKgzAQQEEtvf+V0yNUS9IXcOYAi8ojH1nwOAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB+dS6YORbMZB9Tm3nNHAZ3CZCUAEkJkJQASQmQlABJCZCUAEm96wf4Zoy5i5XzXLH8medp7+sEJCVAUgIkJUBSAiQlQFICJCVAUgIktf0m5KoFN/6zB05dcey+4bjKCUhKgKQESEqApARISoCkBEhKgKQESKq8Tq/+orX7CuFR38UJSEqApARISoCkBEhKgKQESEqApAQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPzJB129Cln7rnWEAAAAAElFTkSuQmCC"
  , _g = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQFJREFUeJzt20EOgjAQQFFqvP+V8QLGFAN+bN5bsxian24m3TYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAb40D3+6XTcGKptp6XD0FfCJAUgIkJUBSAiQlQFICJCVAUgIkNbabbzj2fW68MY4sdf7fKufiBiQlQFICJCVAUgIkJUBSAiQlQFICJPWsBzjLKpuB2f9YhRuQlABJCZCUAEkJkJQASQmQlABJCZDU7d+EhM5emTjnN9yApARISoCkBEhKgKQESEqApARISoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwI+8AOa2EEs6t4AyAAAAAElFTkSuQmCC"
  , Wg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAARdJREFUeJzt2TEOwjAQAEHD//8MX3AkYMl5pk4RWysXd2sBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA3/aof2DDa/O7O5zlk0bcy7P+Ac4mQFICJCVAUgIkJUBSAiQlQFICJPXXU/KLRmwG1pxzbPECkhIgKQGSEiApAZISICkBkhIgKQGSGjFNv6jaNBy14djlBSQlQFICJCVAUgIkJUBSAiQlQFICJDVp6j5l0zDlHFu8gKQESEqApARISoCkBEhKgKQESEqApO4wTT9qM3DBiHvxApISICkBkhIgKQGSEiApAZISICkBAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/8gaytApg9FYc4gAAAABJRU5ErkJggg=="
  , $g = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAN5JREFUeJzt2bENwzAMAEHH++8cryA7Bj6g7moVIvBQIR4HAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwFOf+gIv+i6e+/eZp8yx5KwvwN4ESEqApARISoCkBEhKgKQESEqApEb8pt9UbRq22nCs8gKSEiApAZISICkBkhIgKQGSEiApAZKa9Os+ZdMwZY4lXkBSAiQlQFICJCVAUgIkJUBSAiQlQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAH10+uAY6nXAnOgAAAABJRU5ErkJggg=="
  , bg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPZJREFUeJzt2zEOwjAQAEHD//9MGh7gSCQLx0ztwhetXMTyWgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMDVHvUGNrw21/3CLJ804rs86w3w3wRISoCkBEhKgKQESEqApARISoCkvvov+UkjbgbWnDm2OAFJCZCUAEkJkJQASQmQlABJCZCUAAEAAAAAAOASI94VvE15SzFlji3ugkkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAmxzqBAZOkzFw+wAAAABJRU5ErkJggg=="
  , Af = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQdJREFUeJzt21EKwjAUAMEq3v/KegN5lcQtOvNdmhCWfPTR4wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD41O3Es89tu+AXjdq6794FvCNAUgIkJUBSAiQlQFICJCVAUgIkdWYSMjWdmEzXrt5Xufq5LG3GDUhKgKQESEqApARISoCkBEhKgKQESOpRb2Bgx7SmWHf1BKY6l6XcgKQESEqApARISoCkBEhKgKQESEqApMqv6dW/GVefIPzVubgBSQmQlABJCZCUAEkJkJQASQmQlAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgC95AYulCVJgcsd6AAAAAElFTkSuQmCC"
  , ef = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAXBJREFUeJzt3UEKwjAQQNEo3v/KunInUiHyh/a9A7Qp/cwiJXQtAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4N9u9QIu5Bndd/Q7vtcL4NoESEqApARISoCkBEhKgKQESEqApEbvkv/o6JeG3c+8+77Tr7eVCUhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEjqUS9go7OcbznLcxxiApISICkBkhIgKQGSEiApAZISICkBkrrUrnvMf0I+MAFJCZCUAEkJkJQASQmQlABJCZCUAEmN3iXnq9H//zjKBCQlQFICJCVAUgIkJUBSAiQlQFICJDV6l5wtRn8xMQFJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJORPCW3J2xAQkJUBSAiQlQFICJCVAUgIkJUBSAgQAAAAAAAAAAAAAAAAAAAAAAABYa631AuejCbMvqWS8AAAAAElFTkSuQmCC"
  , tf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAALRJREFUeJzt0tEJgDAQBcEk/fdsGjA/IqzgTAHH49gxAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJ6a9YAPu16+59c3Vj2AfxMgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABwsAEy/AEoQWrZvwAAAABJRU5ErkJggg=="
  , nf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAALtJREFUeJzt0sEJgDAUBcFo/z1rBcInCCs4cw7khexaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALuOesDANTz39lum905V+z79x2c9gH8TICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8OAGzsgDKMsQZxcAAAAASUVORK5CYII="
  , rf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOBJREFUeJzt2TEOwyAQAEFi+f9fTj7gAjlIm+CZmuJAK5obAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOCuVz3AhPfkuX+4y0pbvMtRD8CzCZCUAEkJkJQASQmQlABJCZCUAEmd9QALbbEZGPP32IIfkJQASQmQlABJCZCUAEkJkJQASQmQ1K9vBUqrNxLe+oIfkJQASQmQlABJCZCUAEkJkJQASQkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOBLHxI6BTx5kWrJAAAAAElFTkSuQmCC"
  , lf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQlJREFUeJzt3LENhDAQAEH4/nuGCpAcAHviZ2ICW6wcGB3bBgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADxtrxdwo2Pxuel7/so+lvzqBfDfBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkPjFX8JDV2YzpRr9jJyApAZISICkBkhIgKQGSEiApAZISIKnRt+TcYvTftpyApARISoCkBEhKgKQESEqApARISoCkfAm5ZibkBU5AUgIkJUBSAiQlQFICJCVAUgIkJUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4CUn+B8FYcR7skIAAAAASUVORK5CYII="
  , of = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAORJREFUeJzt20EKhSAUQNH6+99zrSAwMG75zxk78MnFgdG2AQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAE/b6w1MdAyue/vMq8wx5FdvgP8mQFICJCVAUgIkJUBSAiQlQFICJPWF1/TRLwOzzT6bVeaYyg1ISoCkBEhKgKQESEqApARISoCkBEjq1a/kN63yL8UqcwxxA5ISICkBkhIgKQGSEiApAZISICkBAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABw4QRmdgY65Wy+uwAAAABJRU5ErkJggg=="
  , uf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPBJREFUeJzt28EJwCAQAMEk/fectCCobMCZAsSTxYfgdQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC73fUGBryL1/v7zEfN+9Qb4GwCJCVAUgIkJUBSAiQlQFICJCVAUuUr+eoX/9VWn81p8w5xA5ISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZLa8Q/A34c5R52fG5CUAEkJkJQASQmQlABJCZCUAEkJEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABg0gfprwZOGH4+JQAAAABJRU5ErkJggg=="
  , sf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAbhJREFUeJzt3bFNxEAQQNEzogqgCxIaIKUFaqMFUhogoQugjaMDtMHAt87vFWB5fV8b7Mjn0wkAAAAAAOBSbfUN/LfH1/dzfQ+/eXt6ONRvclXfAMcmQFICJCVAUgIkJUBSAiQlQFICJLX7U/fpycXN7d3k5cZ9f32OXm/vkxU7ICkBkhIgKQGSEiApAZISICkBkhIgqeyUfHXCsffJxd6tTlaqiYkdkJQASQmQlABJCZCUAEkJkJQASQmQ1Pjp9/SE4+V+dhLy/DH7zsW0ar3VxMQOSEqApARISoCkBEhKgKQESEqApARI6nr6gtMTjvN59rMe27Z2kD89MTnaelfZAUkJkJQASQmQlABJCZCUAEkJkJQASf3FPyItHeVXJ/6n/X8b5VDPzw5ISoCkBEhKgKQESEqApARISoCkBEhq/J2QaRc04Vi1tI5t25KJyTQ7ICkBkhIgKQGSEiApAZISICkBkhIgqXJ6sHpEfykTjsqun7MdkJQASQmQlABJCZCUAEkJkJQASQkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPgnP0bwOfSYgppJAAAAAElFTkSuQmCC"
  , af = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAASVJREFUeJzt3FEKwyAUAMFYev8rp0eoUNMNceYAwSeLHwo5DgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOBqo17AhHPx9+4+81bzvuoFsDcBkhIgKQGSEiApAZISICkBkhIgqfKWfOrG/zzXPgyMMT3y6r3Zbd4pTkBSAiQlQFICJCVAUgIkJUBSAiQlQFJX3H678f/NVvvnBCQlQFICJCVAUgIkJUBSAiQlQFICJPWuF/DNg144Zk3NMcZIXkxWcwKSEiApAZISICkBkhIgKQGSEiApAZK6/d+xjue8cFRuvc9OQFICJCVAUgIkJUBSAiQlQFICJCVAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOBPPiUsGGcQ1VBNAAAAAElFTkSuQmCC"
  , cf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOVJREFUeJzt1MENgkAURVEwlGEXFmRtFEQX9qEtTARzCZ6znsX7yc1MEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMC35nrAgd6D785+81XuGHKrB/DfBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCklnrAUZ7ba+jd+rj/eMk+V7ljlB+QlABJCZCUAEkJkJQASQmQlABJCRAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAYKcP9kkIPlWAy0QAAAAASUVORK5CYII="
  , gf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQFJREFUeJzt28ENAiEUQMHVOuzCgqzNgrYL+9htgZiNj+DMmQOfvHAgYdsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvnWrN3ChY3Dd7DOvMseQe70B/psASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkNT0/wpe+2foj8T7+Zh+liutci5uQFICJCVAUgIkJUBSAiQlQFICJCVAUtkr+Sov+bOb/ZzdgKQESEqApARISoCkBEhKgKQESEqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCPnNt8Ek5+sZuyAAAAAElFTkSuQmCC"
  , ff = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPZJREFUeJzt2bERwjAQAEHDEEMT9F8RTUADUALCNnPY7MYKXpobJT9NAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHMd6gEGPAfPbeEua9rFuxzrAfhvAiQlQFICJCVAUgIkJUBSAiQlQFKneoB3Hpfr0Lnz/baLzcA0uOH44F0WDfNtfkBSAiQlQFICJCVAUgIkJUBSAiQlQFI/vwkZtYGNyaobjr3wA5ISICkBkhIgKQGSEiApAZISICkBAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAs9AK91hBjndQaHwAAAABJRU5ErkJggg=="
  , df = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAONJREFUeJzt2TEOg0AMAEGI8v8vhy8YEmnJMVNT2NLqCrxtAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFft9QA/9Bl+d/edV9lj5FUPwLMJkJQASQmQlABJCZCUAEkJkJQASb3rAQaml4GnWeJi4gUkJUBSAiQlQFICJCVAUgIkJUBSAiT1D5eQqVv/8T9huscSFyIvICkBkhIgKQGSEiApAZISICkBkhIgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCXDvZ8BUD4wuwuAAAAAElFTkSuQmCC"
  , Cf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMVJREFUeJzt2UEKgCAUQMHq/neuEwQSwiubWbvwy8OFbhsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAU3u9gYnOwXVvn3mVOYYc9Qb4NwGSEiApAZISICkBkhIgKQGSEiCpL7ymj/4MzDb7bFaZYyo3ICkBkhIgKQGSEiApAZISICkBkhIgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA3LkbkBCdf1cHcAAAAAElFTkSuQmCC"
  , pf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMNJREFUeJzt2cEJgDAQAMEo9t+yViAEERbjzDuPu7DkkzEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAntrqASack+e+sMublriXvR6AfxMgKQGSEiApAZISICkBkhIgKQGSOuoBXrTEz8CY32MJXkBSAiQlQFICJCVAUgIkJUBSAiQlQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAblzuFwQpEmWE/QAAAABJRU5ErkJggg=="
  , Sf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAO1JREFUeJzt2zEOAiEUQMFdC2/rabytzXoFVJJHcKam4CcvFBCOAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOBbZ72BWa7X8xpZd94fS8+8yxyjbvUG+G8CJCVAUgIkJUBSAiQlQFICJCVAUsvfpo++DMw2+6VhlzlmcwKSEiApAZISICkBkhIgKQGSEiApAZJa+pb8E7v8pdhljlFOQFICJCVAUgIkJUBSAiQlQFICJCVAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAfvQGbwRg6qu3u4gAAAABJRU5ErkJggg=="
  , Ef = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQpJREFUeJzt20GKBCEQAEHd///Z+YKwDilOxLkPXZB4KHEMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4Ntm/QOnrLXWzndzzqtnfmWOXX/1D/DbBEhKgKQESEqApARISoCkBEhKgKSu36bv3gycdvqm4ZU5TnMCkhIgKQGSEiApAZISICkBkhIgKQGS2t6SV5v8XbsL/8vHeGmOrUGcgKQESEqApARISoCkBEhKgKQESEqApK5+LzDGO28pXpnjNCcgKQGSEiApAZISICkBkhIgKQGSEiAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwD99AHKnIk3vGaQ3AAAAAElFTkSuQmCC"
  , Qf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMFJREFUeJzt2TEOgCAQAEH0/3/WxtZobBbDzAeA3IYCxgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD4aqs3sJAjWnfqGe/1BlibAEkJkJQASQmQlABJCZCUAEkJkNTUr+SX1X4Q3p73D7N75AYkJUBSAiQlQFICJCVAUgIkJUBSAgQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4MYJlO8DJy2MQTgAAAAASUVORK5CYII="
  , kf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPBJREFUeJzt2UEKgDAMAEH1/3/WFwgVhJU4c+6hgaWHZtsAAAAAAAAAAAAAAAAAAAAAAADgK/b6Ai86F899feYpcyw56gvwbwIkJUBSAiQlQFICJCVAUgIkJUBSI37TH6o2Db/acKzyApISICkBkhIgKQGSEiApAZISICkBkpr06z5l0zBljiVeQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAblye+AZOZUa85QAAAABJRU5ErkJggg=="
  , If = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQtJREFUeJzt2zEOgzAQAEHI//+cfAEpxnuCmZriBKsrbHEcAAAAAAAAAAAAAAAAAAAAAAAAMMVZDxD4Xnzuje9mu089AO8mQFICJCVAUgIkJUBSAiQlQFICJPWk0/7VNxxuTDawAUkJkJQASQmQlABJCZCUAEkJkJQASTnF3+fqzcpqo7+xDUhKgKQESEqApARISoCkBEhKgKQESGr0KflNpv/rMX2+pWxAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIA//QCBBgZhq/2fOAAAAABJRU5ErkJggg=="
  , hf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQtJREFUeJzt2zEOgzAQAEHI//+cfAEpoLW5mZriBKsrbHEcAAAAAAAAAAAAAAAAAAAAAAAAsIqzHmCQ78XnRn2TTz0AswmQlABJCZCUAEkJkJQASQmQlABJjTp1f8jdNxyjbkxsQFICJCVAUgIkJUBSAiQlQFICJCVAUjucpl+9GbjbDu9mezYgKQGSEiApAZISICkBkhIgKQGSEiCpN532r/4vxerzJWxAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIA//QAxBgZhHC/YMwAAAABJRU5ErkJggg=="
  , mf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMxJREFUeJzt0okJgEAMAEHP/nuOJQh6sKAzDeRhjwMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgqVUvcGdmppi71tr6m6/csdtZL8C/CZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABeugBEyAg8+HEooQAAAABJRU5ErkJggg=="
  , Jf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAASVJREFUeJzt2dEJwjAUQNEqjtEtOlBncyC3cA8dwSCBW+s534G+lEs+kmUBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+NalHmCi1+C6o+/5LPsYcq0H4L8JkJQASQmQlABJCZCUAEkJkJQASf3Cbfroy8Bss//NWfYxlROQlABJCZCUAEkJkJQASQmQlABJCZDUrR7gk/3xHFp339ZD3/gvk18k9sdz6GXlvq0zPzudE5CUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA7mDfLJC2ZQS062AAAAAElFTkSuQmCC"
  , Uf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAONJREFUeJzt2TEOgzAQAEGC+P+XkxcgXQFagmdqF2dp5cK3bQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMDdPvUAF/oOzz39zm+5x8heD8DaBEhKgKQESEqApARISoCkBEhKgKSOeoCB6WZgNa/YmHgBSQmQlABJCZCUAEkJkJQASQmQlABJ/cMmZPqTv9rG5NEbjikvICkBkhIgKQGSEiApAZISICkBkhIgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAnfkjNBUAxv42sAAAAAElFTkSuQmCC"
  , Bf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPZJREFUeJzt3MEJhDAUQEFj/z3HBjy4rPqEzBQQgjxyyCduGwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPC0UW/gq+ac8871xhi+9Ym93gBrEyApAZISICkBkhIgKQGSEiApAZJa7nb+6oTj7sHF1cHKahMTJyApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiCppd4f/MLfsd7hBCQlQFICJCVAUgIkJUBSAiQlQFICBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD40wFsfA9OMr1dKQAAAABJRU5ErkJggg=="
  , vf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAP1JREFUeJzt3NEJhDAQQEFz/feca8HDHG/BmQJkkUc+XOJ1AQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP+26gFO2Xvvk89bax19N9Pnq3zqAXg3AZISICkBkhIgKQGSEiApAZISIKnxX9PvbhBOLwYOLy6y+aZvTJyApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApEbfF/jF9L9PTZ+v4gQkJUBSAiQlQFICJCVAUgIkJUBSAgQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAeOgL02YaTo7OuPsAAAAASUVORK5CYII="
  , Kf = [Vg, Zg, Og, xg, Gg, Pg, Lg, Tg, qg, Mg, jg, Dg, Xg, Yg, Hg, _g, Wg, $g, bg, Af, ef, tf, nf, rf, lf, of, uf, sf, af, cf, gf, ff, df, Cf, pf, Sf, Ef, Qf, kf, If, hf, mf, Jf, Uf, Bf, vf]
  , hn = {
  ...Kf
}
  , yf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAphJREFUeJzt3SGOFFEUQNEuMgI0bhRIBArCOtjCLATFQtgC6yCgEEhQODS4YQn8zrzi1kyfoyu/K6mbJ+ql0tvp8tzWN/APW30D/9Oj+ga4bAIkJUBSAiQlQFICJCVAUgIkJUBS9+Gt++jm4vb7+8njxm3P340fOX3gJBOQlABJCZCUAEkJkJQASQmQlABJCZBU+ZZ8acMxvbn48+vL6HnTHj99NXreGZuVpAUTkJQASQmQlABJCZCUAEkJkJQASQmQ1B5vv0c3HEffXBzd6mal2piYgKQESEqApARISoCkBEhKgKQESEqApM55q23D8YBVGxMTkJQASQmQlABJCZCUAEkJkJQASQmQ1NX0gTYc91P13ExAUgIkJUBSAiQlQFICJCVAUgIkJUBS22nxW4/fn9/ufCs8JE9ef1y6zgQkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiS1nXGt/xNh+f8/TottmYCkBEhKgKQESEqApARISoCkBEhKgKSupg/88fXn0nXPXl5P/zR3sPrcppmApARISoCkBEhKgKQESEqApARISoCkzvkmZNXStyPfPrxZOszG5G5WNxwvbj6tHjnajAlISoCkBEhKgKQESEqApARISoCkBEhqj03IqtGNyaqjb1amv82oNhyrTEBSAiQlQFICJCVAUgIkJUBSAiQlQFLlJmTV0sZk1fRmZdoZm4tVh37GJiApAZISICkBkhIgKQGSEiApAZISIKlDvyXfyehmZQcX9UxMQFICJCVAUgIkJUBSAiQlQFICJCVAUn8BXllBNv2/f3cAAAAASUVORK5CYII="
  , Rf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAoxJREFUeJzt3bFtFFEYRlGvRWoowAkBCR2Q0AElUJ5LcAckdEBCQEIBtlOEKYE3+B/u2HtOvHoeaa9eMJ9WPl2cn8f6Af7iVD/A/3RZPwDnTYCkBEhKgKQESEqApARISoCkBEjqObx1H10uHj99njxu3On2ZvzI6QMnuQFJCZCUAEkJkJQASQmQlABJCZCUAEmVb8mXFo7p5eLhy9fR86Zdffwwet6GZSVpwQ1ISoCkBEhKgKQESEqApARISoCkBEhqj7ffowvH0ZeLo1tdVqrFxA1ISoCkBEhKgKQESEqApARISoCkBEhqy1ttC8cLVi0mbkBSAiQlQFICJCVAUgIkJUBSAiQlQFLjS8j9m3f/+Cg8B6/vvq9+1BLC8QmQlABJCZCUAEkJkJQASQmQlABJnS4sHOxgdTFxA5ISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkvIf09nEf0znRREgKQGSEiApAZISICkBkhIgKQGSejV94M/fv5Y+d305/qd5gtXvbZobkJQASQmQlABJCZCUAEkJkJQASQmQ1JbfhKxa+u3It6u3S4dZTJ5mdeF4//Bj9cjRZtyApARISoCkBEhKgKQESEqApARISoCk9lhCVo0uJquOvqxM/zajWjhWuQFJCZCUAEkJkJQASQmQlABJCZCUAEmVS8iqpcVk1fSyMm3DcrHq0N+xG5CUAEkJkJQASQmQlABJCZCUAEkJkNSh35LvZHRZ2cFZfSduQFICJCVAUgIkJUBSAiQlQFICJCVAUn8AZAJAFU1JPSAAAAAASUVORK5CYII="
  , wf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAp1JREFUeJzt3SGOFFEUQNFuMgJJgmIBKBwKjWAJGCSOTRA2gUNiWAICjcKhWABqEiQOlsCrzC9uzfQ5uvK7krp5ol4qfT5dnj/1DfzDub6B/+lefQNcNgGSEiApAZISICkBkhIgKQGSEiCp2/DWfenm4vPrHyuPW+7Fh8erjzz0MzYBSQmQlABJCZCUAEkJkJQASQmQlABJlW/JRxuO1ZuLX7+vl5632oP7D5eet2GzkrRgApISICkBkhIgKQGSEiApAZISICkBktrj7ffSDcfRNxdHN92sVBsTE5CUAEkJkJQASQmQlABJCZCUAEkJkNSWt9o2HHdYtTExAUkJkJQASQmQlABJCZCUAEkJkJQASV2tPtCG43aqnpsJSEqApARISoCkBEhKgKQESEqApARI6nwafuvx6dXXnW+Fu+Tlx2ej60xAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBS5w3X+j8Rxv//cRq2ZQKSEiApAZISICkBkhIgKQGSEiApAZK6Wn3g95/fRtc9efR09U9zA9PntpoJSEqApARISoCkBEhKgKQESEqApARIass3IVOjb0fePn8/OszG5GamG453X95Mj1zajAlISoCkBEhKgKQESEqApARISoCkBEhqj03I1NKNydTRNyurv82oNhxTJiApAZISICkBkhIgKQGSEiApAZISIKlyEzI12phMrd6srLZhczF16GdsApISICkBkhIgKQGSEiApAZISICkBkjr0W/KdLN2s7OCinokJSEqApARISoCkBEhKgKQESEqApARI6i9LtkFtFQZKKAAAAABJRU5ErkJggg=="
  , Ff = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAnNJREFUeJzt3cGt2zAQQEErSAVS/yVKLTiHFJA1TP+nwDNngxagBx64ELg9vs+zfoB/2OoH+Em/6gfguwmQlABJCZCUAEkJkJQASQmQlABJ/Q+n7ksnF+d5rlxuueM4Vi9563dsByQlQFICJCVAUgIkJUBSAiQlQFICJFWeko8mHKsnF/u+L11vteu6lq73wmQlacEOSEqApARISoCkBEhKgKQESEqApARI6hOn30snHHefXNzddLJSTUzsgKQESEqApARISoCkBEhKgKQESEqApF451R5NOJ7Pu1/DwTu2bZzM6Id2QFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFLbw7cefMD02xE7ICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgqdllDn+5T4Tx/R+PYVt2QFICJCVAUgIkJUBSAiQlQFICJCVAUr9XL3hd1+h3+76v/mveMH1vq9kBSQmQlABJCZCUAEkJkJQASQmQlABJvfJNyNToo5DzPEeLmZi8ZzrhOI5juuTSZuyApARISoCkBEhKgKQESEqApARISoCkPjEJmVo6MZm6+2Rl9bcZ1YRjyg5ISoCkBEhKgKQESEqApARISoCkBEiqnIRMLb14ZPVkZbUXJhdTt37HdkBSAiQlQFICJCVAUgIkJUBSAiQlQFK3PiX/kLtf6f5V78QOSEqApARISoCkBEhKgKQESEqApARI6g9ovTkBOtPXAAAAAABJRU5ErkJggg=="
  , Nf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAp1JREFUeJzt3bGN1FAUQNEZtBkBGQlIRBBTAykNINEBDVADDdABEg2QUgMxREiQbEZADCXwrP3menfOia0/lnz1Aj9Zcz5dnj/1DfzDub6B/+lefQNcNgGSEiApAZISICkBkhIgKQGSEiCp2/DWfenm4t2PlyuPW+7t40+rjzz0MzYBSQmQlABJCZCUAEkJkJQASQmQlABJlW/JRxuO1ZuL719/Lj1vtSfPHi09b8NmJWnBBCQlQFICJCVAUgIkJUBSAiQlQFICJLXH2++lG46jby6ObrpZqTYmJiApAZISICkBkhIgKQGSEiApAZISIKktb7VtOO6wamNiApISICkBkhIgKQGSEiApAZISICkBkrpafaANx+1UPTcTkJQASQmQlABJCZCUAEkJkJQASQmQ1Pk0/NbjzefnO98Kd8n7F19G15mApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkzhuu9X8ijP//4zRsywQkJUBSAiQlQFICJCVAUgIkJUBSAiR1tfrAX9e/R9c9eHh/9U9zA9PntpoJSEqApARISoCkBEhKgKQESEqApARIass3IVOjb0def3w6OszG5GamG44Pr75Nj1zajAlISoCkBEhKgKQESEqApARISoCkBEhqj03I1NKNydTRNyurv82oNhxTJiApAZISICkBkhIgKQGSEiApAZISIKlyEzI12phMrd6srLZhczF16GdsApISICkBkhIgKQGSEiApAZISICkBkjr0W/KdLN2s7OCinokJSEqApARISoCkBEhKgKQESEqApARI6i9lskFJ9nhMDQAAAABJRU5ErkJggg=="
  , zf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAoZJREFUeJzt3cFNI0EQQFF75Shgs9gLCXAlhY1tU+BKAlw2C3AaEAI9Upk/xu+drfZI89WHLrXmeLg9H/UDfOFYP8B3+lU/ALdNgKQESEqApARISoCkBEhKgKQESOoaTt1HJxePz6+Ty417eXqYXnLX79gOSEqApARISoCkBEhKgKQESEqApARIqjwlX5pwTE8u7u5/j6437fz+NrrehslK0oIdkJQASQmQlABJCZCUAEkJkJQASQmQ1CVOv0cnHHufXOzd6mSlmpjYAUkJkJQASQmQlABJCZCUAEkJkJQASW051Tbh+MGqiYkdkJQASQmQlABJCZCUAEkJkJQASQmQ1Gl6QROO61S9NzsgKQGSEiApAZISICkBkhIgKQGSEiCp42Hxrsff/7Pfr+Bn+/dnbbJiByQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJHXc8FvfE2H5+x+HxbbsgKQESEqApARISoCkBEhKgKQESEqApE7TC57f1yYhd/fLJ+p8g9X3Ns0OSEqApARISoCkBEhKgKQESEqApARIasudkFXujlyh6bseq+yApARISoCkBEhKgKQESEqApARISoCkLjEJWbU0MXl8fh39073fRZm+m/Hy9LD606QFOyApAZISICkBkhIgKQGSEiApAZISIKlyErJqaWKyanqyMm3D5GLVrt+xHZCUAEkJkJQASQmQlABJCZCUAEkJkNSuT8kvZHSycgE39U7sgKQESEqApARISoCkBEhKgKQESEqApD4Bjbg5ihTr1swAAAAASUVORK5CYII="
  , Vf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAApBJREFUeJzt3TGK1VAUgOEXmUZwByJjZWtrK7gj1+GOhGltba0UcQeC5bgEb5gT/8y876vDfYH8nCKH8LbL9bmvb+AftvoG/qdn9Q1w3QRISoCkBEhKgKQESEqApARISoCkHsNb99HNxf3Hn5PHjds+vRo/cvrASSYgKQGSEiApAZISICkBkhIgKQGSEiCp8i350oZjfHPx9c/sedPePh89bsdmJWnBBCQlQFICJCVAUgIkJUBSAiQlQFICJHXE2+/ZDcfZNxdnt7hZqTYmJiApAZISICkBkhIgKQGSEiApAZISIKk9b7VtOJ6yaGNiApISICkBkhIgKQGSEiApAZISICkBkroZP9GG43GKnpsJSEqApARISoCkBEhKgKQESEqApARIarusfuvx4dvBt8JTsn1+s3SdCUhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESGrbca3/E2H5/z8ui22ZgKQESEqApARISoCkBEhKgKQESEqApG6mD/zx+9fSdbcvXk7/NA+w+tymmYCkBEhKgKQESEqApARISoCkBEhKgKT2fBOyaunbke/v7pYOszF5mNUNx+sv71ePHG3GBCQlQFICJCVAUgIkJUBSAiQlQFICJHXEJmTV6MZk1dk3K9PfZlQbjlUmICkBkhIgKQGSEiApAZISICkBkhIgqXITsmppY7JqerMybcfmYtWpn7EJSEqApARISoCkBEhKgKQESEqApARI6tRvyQ8yulk5wFU9ExOQlABJCZCUAEkJkJQASQmQlABJCZDUX55LPINjyG2cAAAAAElFTkSuQmCC"
  , Zf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAo1JREFUeJzt3bFtFGEQgNFb5JwIiZh23AMJIRnUARkhiXugHWIkIiowJTAnz/Gdfe/Fq/9W2k8T7Gh1x+n2PNY38A9HfQP/06v6BrhtAiQlQFICJCVAUgIkJUBSAiQlQFLP4a376ubi8ce3zePWHfcf14/cPnCTCUhKgKQESEqApARISoCkBEhKgKQESKp8Sz7acKxvLn793D1v29t3q8edsVlJWjABSQmQlABJCZCUAEkJkJQASQmQlABJXeLt9+6G49o3F9duuFmpNiYmICkBkhIgKQGSEiApAZISICkBkhIgqXPeattwvGTRxsQEJCVAUgIkJUBSAiQlQFICJCVAUgIkdbd+og3H8xQ9NxOQlABJCZCUAEkJkJQASQmQlABJCZDUcZp+6/H984VvhZfk+PBldJ0JSEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARI6jjjWv8nwvj/P07DtkxAUgIkJUBSAiQlQFICJCVAUgIkJUBSd+sn/vk9u+71m/Wf5gmmz22ZCUhKgKQESEqApARISoCkBEhKgKQESOqcb0KmZt+OfH0/O83G5GmGG47j08P0xNVmTEBSAiQlQFICJCVAUgIkJUBSAiQlQFKX2IRM7W5Mpq59s7L8bUa14ZgyAUkJkJQASQmQlABJCZCUAEkJkJQASZWbkKnRxmR82PZmZdkZm4vxkdsHbjIBSQmQlABJCZCUAEkJkJQASQmQlABJXfVb8gtZ3axcwE09ExOQlABJCZCUAEkJkJQASQmQlABJCZDUX0XOOKEp8AJkAAAAAElFTkSuQmCC"
  , Of = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAApdJREFUeJzt3bGN1UAUQFF/tAktEEFIQIaogxYohGgLoQXqQBAREEJERgzZ0sIz+77uoj0nHo2/7asJPLL/5TiOu2PXZXm+bdvnu+1RXb8nm5PBWQIkJUBSAiQlQFICJCVAUgIkJUBSl2P4ZPvu++1swhfvzxx7dOjphKPJhudROXH9xlMOxyUdWAFJCZCUAEkJkJQASQmQlABJCZCUAEndTAf++fVlNO7Ek/LVJ+9T0/OobJ/v9nXevn5WQFICJCVAUgIkJUBSAiQlQFICJCVAUme+xDR6ov7789t//Cn8D56+/jgdOmrLCkhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARIavyP6b56xRnTr2hZAUkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASV1OjPV/Ioz//+MYtmUFJCVAUgIkJUBSAiQlQFICJCVAUgIkdbM94Y+vP0fjnr96tn1o7mF637ZZAUkJkJQASQmQlABJCZCUAEkJkJQASZ15J2Rq9O7Itw9vRpPZMbmf6Q7Hy3efplOuNmMFJCVAUgIkJUBSAiQlQFICJCVAUgIkdY2dkKnVHZOph76zsv1uRrXDMWUFJCVAUgIkJUBSAiQlQFICJCVAUgIkVe6ETI12TKa2d1a2ndi5mHrQ99gKSEqApARISoCkBEhKgKQESEqApARI6kE/Jb+S1Z2VK3hU98QKSEqApARISoCkBEhKgKQESEqApARI6i+2Gkmo33wHRgAAAABJRU5ErkJggg=="
  , xf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAqBJREFUeJzt3SGOFFEUQNFuMptgASgcCo1gCRgkjk0QNoFDYlgCAo3CoVgAioQwAhxs4RXzOnfInKMrv7uqbr74P1V1Pp1Of067zsvjbds+32136vrd2xwMjhIgKQGSEiApAZISICkBkhIgKQGSOp+GK9sfXnwdDfj07YMjvz2xuvI+PY/Kges3tXqdtzswA5ISICkBkhIgKQGSEiApAZISICkBkrqaHnj96+fouAMr5asr71M/fn9fHW/b9vluX+dpB1NmQFICJCVAUgIkJUBSAiQlQFICJCVAUkfexDRaUX///NM//hX+B8/ePZ4eOmrLDEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARIavzFdG+94ojpW7TMgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApM4HjvU9Ecbf/zgN2zIDkhIgKQGSEiApAZISICkBkhIgKQGSutoe8Mu3z6PjHt5/tP3T3MD0vm0zA5ISICkBkhIgKQGSEiApAZISICkBkjryTMjU6NmRV0/ejAazY3Iz0x2O1x9fTodcbcYMSEqApARISoCkBEhKgKQESEqApARI6hI7IVOrOyZTt31nZfvZjGqHY8oMSEqApARISoCkBEhKgKQESEqApARIqtwJmRrtmExt76xsO7BzMXWr77EZkJQASQmQlABJCZCUAEkJkJQASQmQ1K1eJb+Q1Z2VC7hT98QMSEqApARISoCkBEhKgKQESEqApARI6i8wKUk+yHfZXAAAAABJRU5ErkJggg=="
  , Gf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAptJREFUeJzt3bGN1UAUQFF/tBkBGREpOTWQ0gASHdAANdAAHSDRACk1EEOEBAkZATG08My+1f1oz4mt+d/21QQzsn05juPPseuyPN627fPddq+u34PNweAsAZISICkBkhIgKQGSEiApAZISIKnLMVzZfvv9xWjAN08+nvntidWV9+l5VE5cv6nV67zdgRmQlABJCZCUAEkJkJQASQmQlABJCZDUzfTAb19+jI47sVK+uvI+NT2Pyvb5bl/n7etnBiQlQFICJCVAUgIkJUBSAiQlQFICJHXmTUyjFfXXn57941/hf/Du+efpoaO2zICkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApMZfTPfWK86YvkXLDEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESOpy4ljfE2H8/Y9j2JYZkJQASQmQlABJCZCUAEkJkJQASQmQ1M32gL9+/h4d9+jxw+2f5ham922bGZCUAEkJkJQASQmQlABJCZCUAEkJkNSZZ0KmRs+OvPrwdDSYHZPbme5wvH/5dTrkajNmQFICJCVAUgIkJUBSAiQlQFICJCVAUnexEzK1umMyde07K9vPZlQ7HFNmQFICJCVAUgIkJUBSAiQlQFICJCVAUuVOyNRox2Rqe2dl24mdi6mrvsdmQFICJCVAUgIkJUBSAiQlQFICJCVAUle9Sn5HVndW7sC9uidmQFICJCVAUgIkJUBSAiQlQFICJCVAUn8B3NdIV7CaJv8AAAAASUVORK5CYII="
  , Pf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAApZJREFUeJzt3bGN1FAUQFHPinShABICEjogoQNKoDxKoAMSOiAhIKEA2BSxtPCsfaO7qz0ntr419tUP/OTx5TiO+2PXZXm9bdu/d9uzun43m4vBWQIkJUBSAiQlQFICJCVAUgIkJUBSl2P4ZPv+46fZgl8+nzn36NTTBUeLDX9H5cT1Gy85PC7pwA5ISoCkBEhKgKQESEqApARISoCkBEhqPAn58+rtaMHbD+9nJx4+Kd+eXNx9/ba63rbp9Zvavs7T6/fy94/RcXZAUgIkJUBSAiQlQFICJCVAUgIkJUBSZ/6JaXViwtM0nXAcw7bsgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCk1r8TAsfhOyE8EQIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSlxPH+p4I4+9/HMO27ICkBEhKgKQESEqApARISoCkBEhKgKRebC/469/f0XGvb9ZPzQNM79s2OyApAZISICkBkhIgKQGSEiApAZISIKkz74RMjd4d+X77ZrSYicnDTCcc7+5+TpdcbcYOSEqApARISoCkBEhKgKQESEqApARI6hqTkKnVicnUY5+sbL+bUU04puyApARISoCkBEhKgKQESEqApARISoCkyknI1GhiMrU9Wdl2YnIx9ajvsR2QlABJCZCUAEkJkJQASQmQlABJCZDUo35KfiWrk5UreFb3xA5ISoCkBEhKgKQESEqApARISoCkBEjqPwPFRRSsLRucAAAAAElFTkSuQmCC"
  , Lf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAnlJREFUeJzt3cGN4kAQQFGz2ghM/iHiFNjDJlDW1OgzmvfOqBH2Vx9cMv04juN97Hosr7dt+/du+1XX78/mYnCXAEkJkJQASQmQlABJCZCUAEkJkNTjGD7Zfr1eowWfz+ed755YffI+/R2VG9dvavU6b3dgByQlQFICJCVAUgIkJUBSAiQlQFICJDWehLzfs4HEdV2jz02flG9PLs7zXF1v2/T6TW1f5+n1ezxmAxg7ICkBkhIgKQGSEiApAZISICkBkhIgqTv/xLQ6MeFnmk44jmFbdkBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUuvnhMBxOCeEH0KApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARIanaYw3/OE2F8/scxbMsOSEqApARISoCkBEhKgKQESEqApARI6u/2gtd1jT53nuf2V/MF0/u2zQ5ISoCkBEhKgKQESEqApARISoCkBEjqzjshU6OXQl6v12gxE5OvmU44ns/ndMnVZuyApARISoCkBEhKgKQESEqApARISoCkvmMSMrU6MZn69MnK9rsZ1YRjyg5ISoCkBEhKgKQESEqApARISoCkBEiqnIRMrR48sj1Z2XZjcjH10ffYDkhKgKQESEqApARISoCkBEhKgKQESOqjn5J/k08/0v1X3RM7ICkBkhIgKQGSEiApAZISICkBkhIgqX+lzkT/4QxwBgAAAABJRU5ErkJggg=="
  , Tf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAotJREFUeJzt3bFx1FAUQFEt4ypsuiBxA07dArXRglM34IQugDaghSd4y12Pz4k1siXd+cF/I+3lOI7fx67L8vm2bV/vtg91/z5tngzOEiApAZISICkBkhIgKQGSEiApAZK6HMOd7aeXt9EJX58fz/ztidWd9+l1VE7cv6nV+7zdgRWQlABJCZCUAEkJkJQASQmQlABJCZDU3fTA+4fPo+NO7JSv7rxPTa+jsn292/d5+/5ZAUkJkJQASQmQlABJCZCUAEkJkJQASZ35EtNoR/3r9x9/+a/wHnz7Mp6EjNqyApISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSGv9iuq9eccb0K1pWQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUpcTx/o9Eca//3EM27ICkhIgKQGSEiApAZISICkBkhIgKQGSuts+4a+fs0nI/cN4R53/YPrctlkBSQmQlABJCZCUAEkJkJQASQmQlABJnXknZGr07sjTy9voZCYm/2Y64Xh9fpyecrUZKyApAZISICkBkhIgKQGSEiApAZISIKlrTEKmVicmU7c+Wdl+N6OacExZAUkJkJQASQmQlABJCZCUAEkJkJQASZWTkKnRxGRqe7Ky7cTkYuqmn7EVkJQASQmQlABJCZCUAEkJkJQASQmQ1E3vkl/J6mTlCj7UM7ECkhIgKQGSEiApAZISICkBkhIgKQGS+gPmfkFX1NSOswAAAABJRU5ErkJggg=="
  , qf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAApFJREFUeJzt3TGKFFEUQNFqMRHcgYhGpqamgjuadbgjwdTU1EgRdyAYjlt4xbzm9jDnxMUvuuryg3pU1+U4jvtj12V5vW3bv3fbk7p+zzYXg7MESEqApARISoCkBEhKgKQESEqApC7H8Mn2/d3v2YKfX5859+jU0wVHiw1/R+XE9RsvOTwu6cAOSEqApARISoCkBEhKgKQESEqApARIaj4J+fRjtuL7F7MTD5+Ur08uvv/bXW/b8PpNrV/n4fW7fHk3Os4OSEqApARISoCkBEhKgKQESEqApARI6sw/Me1OTHiUphOOY9iWHZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQ1P53QuDwnRAeCQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiCpy4ljfU+E8fc/jmFbdkBSAiQlQFICJCVAUgIkJUBSAiQlQFLPtxf89ffP6Lg3L19tn5oHmN63bXZAUgIkJUBSAiQlQFICJCVAUgIkJUBSZ94JmRq9O/Lzw9fRYiYmDzOdcLz99nG65GozdkBSAiQlQFICJCVAUgIkJUBSAiQlQFLXmIRMrU5Mpm59srL9bkY14ZiyA5ISICkBkhIgKQGSEiApAZISICkBkionIVOjicnU9mRl24nJxdRN32M7ICkBkhIgKQGSEiApAZISICkBkhIgqZt+Sn4lq5OVK3hS98QOSEqApARISoCkBEhKgKQESEqApARI6j8b6ULv0YuMfAAAAABJRU5ErkJggg=="
  , Mf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAo1JREFUeJzt3TFu1UAUQFEbpU/FqtgDDSVdsg7oKGnYA9uhRqJiBckWnsn7ukE5px6Nv/2vpvDI9nkcx9Ox61yeb9v2+W57U9fv3eZkcJUASQmQlABJCZCUAEkJkJQASQmQ1HkM72w//fw2m/DD5yvHHh16OuFosuF5VC5cv/GUw3FJB1ZAUgIkJUBSAiQlQFICJCVAUgIkJUBSd+ORv3+Nhl24U756531seB6V7fNdv87L188KSEqApARISoCkBEhKgKQESEqApARI6sqbmGZ31L8//uNP4X9wfvoyHjoZZAUkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJDX/Yrq3XnHB9C1aVkBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFLnhbG+J8L4+x/HsC0rICkBkhIgKQGSEiApAZISICkBkhIgqbv1Gf/+mY27f79+aF5g+r8tswKSEiApAZISICkBkhIgKQGSEiApAZK68kzI1OzZka8fZ7PZMXmZ4Q7H+fBjOuNqM1ZAUgIkJUBSAiQlQFICJCVAUgIkJUBSt9gJmdrdMZl67Tsry89mVDscU1ZAUgIkJUBSAiQlQFICJCVAUgIkJUBS5U7I1GjHZDzZ9s7Ksgs7F+MptyfcZAUkJUBSAiQlQFICJCVAUgIkJUBSAiT1qu+S38jqzsoNvKn/xApISoCkBEhKgKQESEqApARISoCkBEjqGf+6QvdK9ihQAAAAAElFTkSuQmCC"
  , jf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAjxJREFUeJzt27FNA0EQQFEfcgAxFVADFEJDRDREITiiAIjIiCE7SuAs7fFt33vxabWSvybYkafd9sz1Bf4w1Rf4T1f1Bdg2AZISICkBkhIgKQGSEiApAZISIKlzeHUfurmY359HHjfcdPc0/MjRB45kApISICkBkhIgKQGSEiApAZISICkBkipfyRdtOEZvLn6+DkPPG+369n7oeUdsVpIWTEBSAiQlQFICJCVAUgIkJUBSAiQlQFJrvH4P3XCc+ubi1C3drFQbExOQlABJCZCUAEkJkJQASQmQlABJCZDUMa/aNhwXrNqYmICkBEhKgKQESEqApARISoCkBEhKgKT2ow+04ThP1e9mApISICkBkhIgKQGSEiApAZISICkBkpp2C//r8f36uPJVuCQ3Dy+LvjMBSQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASe2Xfvjx9rnmPdgoE5CUAEkJkJQASQmQlABJCZCUAEkJkNR0xLfzarfgEi1qywQkJUBSAiQlQFICJCVAUgIkJUBSAiT1CyKkKDoPzH+wAAAAAElFTkSuQmCC"
  , Df = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAjlJREFUeJzt2btNBDEUQNEdRAo0QREkdEAJlEcJdECyRZBSAJAihhKYQR7ufs6JLcvB1Qv8pt35mesH/GKqH/CfLuoHcN4ESEqApARISoCkBEhKgKQESEqApI7h133o5mJ+eBx53XDT89PwK0dfOJIJSEqApARISoCkBEhKgKQESEqApARIqvwlX7ThGL25+HzZD71vtKv7u6H3rdisJC2YgKQESEqApARISoCkBEhKgKQESEqApLb4/R664Tj0zcWhW7pZqTYmJiApAZISICkBkhIgKQGSEiApAZISIKk1v9o2HCes2piYgKQESEqApARISoCkBEhKgKQESEqApIZvQj5ubv/4FI7B9fvr0qM2IRw+AZISICkBkhIgKQGSEiApAZISIKlpZ8PBBpZuTExAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUpdLD759f235Ds6UCUhKgKQESEqApARISoCkBEhKgKQESGpacXbe7BWcokVtmYCkBEhKgKQESEqApARISoCkBEhKgKR+AJh5JxnKucfbAAAAAElFTkSuQmCC"
  , Xf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAkFJREFUeJzt27tNA0EUQFEvchcgkRFTAymd0AA10ACdOKUGYjIkSMgIiE0J7EqzXH/OiVejkXz1gnnytDk/+/oCf5jqC/yni/oCnDcBkhIgKQGSEiApAZISICkBkhIgqWN4dR+6uXj6uB953HCPV7vRRx70b2wCkhIgKQGSEiApAZISICkBkhIgKQGSKl/JZ204Rm8u3t8+h5432vXN5dDzFmxWkhZMQFICJCVAUgIkJUBSAiQlQFICJCVAUmu8fg/dcBz65uLQzd2sVBsTE5CUAEkJkJQASQmQlABJCZCUAEkJkNSSV20bjhNWbUxMQFICJCVAUgIkJUBSAiQlQFICJCVAUtvRB9pwHKfqdzMBSQmQlABJCZCUAEkJkJQASQmQlABJTZuZ//V4eLld+Sqckue711nfmYCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCktnM//P76WfMenCkTkJQASQmQlABJCZCUAEkJkJQASQmQ1LTg2/1qt+AUzWrLBCQlQFICJCVAUgIkJUBSAiQlQFICJPUL79AoTeR9vhAAAAAASUVORK5CYII="
  , Yf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAkJJREFUeJzt27tNA0EUQFEvckCIRA30QAWUQEJIXYQkLoEKiMiIKIAIiZDMlMCuNMv155x4NRrJVy+YJ0+b87OvL/CHqb7Af7qoL8B5EyApAZISICkBkhIgKQGSEiApAZI6hlf3oZuLl8ePkccNd/d0M/rIg/6NTUBSAiQlQFICJCVAUgIkJUBSAiQlQFLlK/msDcfozcX3z9fQ80a7urweet6CzUrSgglISoCkBEhKgKQESEqApARISoCkBEhqjdfvoRuOQ99cHLq5m5VqY2ICkhIgKQGSEiApAZISICkBkhIgKQGSWvKqbcNxwqqNiQlISoCkBEhKgKQESEqApARISoCkBEhqO/pAG47jVP1uJiApAZISICkBkhIgKQGSEiApAZISIKlpM/O/HruH15Wvwim5f76d9Z0JSEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESGo798P3z7c178GZMgFJCZCUAEkJkJQASQmQlABJCZCUAElNC77dr3YLTtGstkxAUgIkJUBSAiQlQFICJCVAUgIkJUBSv+iWKHHg7nWoAAAAAElFTkSuQmCC"
  , Hf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAiVJREFUeJzt3cFtg0AQQFETpQLov0RogZQQUBZ/Yt47o9VK/prDzsHT63n2+gK/mOoLvNNXfQGeTYCkBEhKgKQESEqApARISoCkBEjqP7y6D91crOs68rjhlmUZfeStf2MTkJQASQmQlABJCZCUAEkJkJQASQmQVPlKfmjDMXpzMc/z0PNG27Zt6HknNitJCyYgKQGSEiApAZISICkBkhIgKQGSEiCpK16/h2447r65uLujm5VqY2ICkhIgKQGSEiApAZISICkBkhIgKQGSOvOqbcPxwaqNiQlISoCkBEhKgKQESEqApARISoCkBEhq+CZk3+/+h+T8xTQdTsYmhPsTICkBkhIgKQGSEiApAZISICkBkppeNhxc4OjGxAQkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIk9X30w23brrwHD2UCkhIgKQGSEiApAZISICkBkhIgKQGSmk58u192Cz7RobZMQFICJCVAUgIkJUBSAiQlQFICJCVAUj8gfCcEanQbWQAAAABJRU5ErkJggg=="
  , _f = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAjtJREFUeJzt3bFNw1AUQNEYZQpgCxoWoGUFZmMFWhZIwxZJ1ggjYEvf3MQ5p7a+vuSrV/gpyrS7P5f6An+Y6gv8p4f6Atw3AZISICkBkhIgKQGSEiApAZISIKlb+Oo+dHPx9nUYedxw3++vo4+86ndsApISICkBkhIgKQGSEiApAZISICkBkiq/ks/acIzeXDw+PQ89b7Tz6Tj0vAWblaQFE5CUAEkJkJQASQmQlABJCZCUAEkJkNQaX7+HbjiufXNx7eZuVqqNiQlISoCkBEhKgKQESEqApARISoCkBEhqyVdtG44NqzYmJiApAZISICkBkhIgKQGSEiApAZISIKn96ANtOG5T9d5MQFICJCVAUgIkJUBSAiQlQFICJCVAUtNu5m89Pn7G/n8F2/b5Mm+zYgKSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGS2s998Hw6rnkP7pQJSEqApARISoCkBEhKgKQESEqApARIalrw7GW1W7BFs9oyAUkJkJQASQmQlABJCZCUAEkJkJQASf0Cul8jjqCNassAAAAASUVORK5CYII="
  , Wf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAjdJREFUeJzt27FNw1AUQNEYpWEIWlqWYCPmYCOWoKVDERsgUZoRsKVvbmKfU1tfX8rVK/5TptPxzPUF/jDVF/hPd/UFODYBkhIgKQGSEiApAZISICkBkhIgqVt4dR+6uZhfLiOPG256fRh+5OgDRzIBSQmQlABJCZCUAEkJkJQASQmQlABJla/kizYcwzcX7z9jzxvt6X7ocSs2K0kLJiApAZISICkBkhIgKQGSEiApAZISIKktXr/HbjiufXNx7RZuVqqNiQlISoCkBEhKgKQESEqApARISoCkBEhqzau2DceeRRsTE5CUAEkJkJQASQmQlABJCZCUAEkJkNR5+Ik2HLcp+t1MQFICJCVAUgIkJUBSAiQlQFICJCVAUtNp6X89nj82vgp7Mr09LvrOBCQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiR1Xvrh5/fXlvfgoExAUgIkJUBSAiQlQFICJCVAUgIkJUBS04pv581uwR4tassEJCVAUgIkJUBSAiQlQFICJCVAUgIk9QuDKiOH1LXMXAAAAABJRU5ErkJggg=="
  , $f = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAjVJREFUeJzt27FNA0EQQFEfchHEtEMZhITUQUhIGbRDjEREBUcJ7El7fNv3XnxareSvCXbk5XQ8a32BPyz1Bf7TXX0Bjk2ApARISoCkBEhKgKQESEqApARI6hpe3aduLtaPt5nHTbc8Pk8/cvaBM5mApARISoCkBEhKgKQESEqApARISoCkylfyoQ3H9M3F1+fc82a7f5h63IbNStKCCUhKgKQESEqApARISoCkBEhKgKQESGqP1++5G45L31xcusHNSrUxMQFJCZCUAEkJkJQASQmQlABJCZCUAEltedW24bhl0cbEBCQlQFICJCVAUgIkJUBSAiQlQFICJHWefqINx3WKfjcTkJQASQmQlABJCZCUAEkJkJQASQmQ1HIa/a/H+8vOV+GWLE+vQ9+ZgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKTOw1/+fO94DY7KBCQlQFICJCVAUgIkJUBSAiQlQFICJLVs+Hbd7RbcoqG2TEBSAiQlQFICJCVAUgIkJUBSAiQlQFK/iHkipfd+/HEAAAAASUVORK5CYII="
  , bf = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAo5JREFUeJzt3bFNA0EQQFEbUQXQBQkNkNICtdECKQ04oQugDSiBtTTmn/F7sbU667422NHa+93l+a4f4Bf7+gH+0lX9AFw2AZISICkBkhIgKQGSEiApAZISIKlzOHUfnVw8vh4mlxv39vQwveSm37EdkJQASQmQlABJCZCUAEkJkJQASQmQVHlKvjThmJ5c3Nzeja437evzY3S9IyYrSQt2QFICJCVAUgIkJUBSAiQlQFICJCVAUqc4/R6dcGx9crF1q5OVamJiByQlQFICJCVAUgIkJUBSAiQlQFICJHXMqbYJxz9WTUzsgKQESEqApARISoCkBEhKgKQESEqApK6nFzThOE/Ve7MDkhIgKQGSEiApAZISICkBkhIgKQGS2u8W73o8v8/+fwX/28v92mTFDkhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESGp/xGf9nwjL//+xW2zLDkhKgKQESEqApARISoCkBEhKgKQESGp5EvL8/rE0Cfn6XJuE3Nwun6jzB6bf28v9nUkI2ydAUgIkJUBSAiQlQFICJCVAUgIkdT294NvTw9Ln3B3Zluq92QFJCZCUAEkJkJQASQmQlABJCZCUAEmNT0J26/dMRu+YrNr6XZTw+46+t1V2QFICJCVAUgIkJUBSAiQlQFICJCVAUqeYhIxavauw6vH1MLretOnvO32HY/VXr1bZAUkJkJQASQmQlABJCZCUAEkJkJQASY2eap+J0TsNJ3BR78QOSEqApARISoCkBEhKgKQESEqApARI6geIv0TFKrlnzQAAAABJRU5ErkJggg=="
  , Ad = [yf, Rf, wf, Ff, Nf, zf, Vf, Zf, bf, Of, xf, Gf, Pf, Lf, Tf, qf, Mf, jf, Df, Xf, Yf, Hf, _f, Wf, $f]
  , mn = {
  ...Ad
}
  , ed = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMJJREFUeJzt2ckNgCAAAEG1/561BUxMFnDmzYNjwwOOAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACY11lPYMA9OG6FtXxpi3256gnwbwIkJUBSAiQlQFICJCVAUgIkJUBSU7+SvzT6M7CLLc7ODUhKgKQESEqApARISoCkBEhKgKQECAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCiHq/ZAydPq8mZAAAAAElFTkSuQmCC"
  , td = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAJ9JREFUeJzt0sENwDAQw7A0++/cjtBfhODICQxYawEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAnPfUAwJvPeDHqE92PYDZBEhKgKQESEqApARISoCkBEhKgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABc6gMmiQEUcvdJsQAAAABJRU5ErkJggg=="
  , nd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMBJREFUeJzt2cERgCAQADG0/561BR+MK5hUcMAODxgDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPiuox5goqse4GVbnN1ZD8C/CZCUAEkJkJQASQmQlABJCZCUAEmt8Jr+9IdjhbXMtMW+uAFJCZCUAEkJkJQASQmQlABJCZCUAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABY1A2kYgMnIaJrQgAAAABJRU5ErkJggg=="
  , rd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOBJREFUeJzt2cENwyAQAEETpf+W8SMN+BFpMcxUcBKre3DXBQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACsa9QDPDDrAV5u6Tf+1ANwNgGSEiApAZISICkBkhIgKQGSEiCpbz3Av8x51sFkjKUPHI/ZgKQESEqApARISoCkBEhKgKQESEqApPb4Tv856xSyydvZgKQESEqApARISoCkBEhKgKQESEqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJu7AallBT0Pl/2MAAAAAElFTkSuQmCC"
  , ld = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAJ9JREFUeJzt0sEJwDAQA0HH/feclJCPYcE3U4FAuxYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOc89YDAWw/4MeqTXQ9gNgGSEiApAZISICkBkhIgKQGSEiAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADA5T4/NgEUnQXpAwAAAABJRU5ErkJggg=="
  , od = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMZJREFUeJzt0tEJwCAQBUFj/z2bBvIprJiZAo7HsWMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOd66gEHW5vv+fWHWQ/g3wRISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACXewGpLQE8geeC+QAAAABJRU5ErkJggg=="
  , id = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQJJREFUeJzt2bsNAjEUAMEHElXRAuXSAlURQAMEhwTaw8zEDvxZObBnAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAID9OtQT2OCxcdwvrOWTltiXYz0B/psASQmQlABJCZCUAEkJkJQASQmQ1K5fyd+09WdgFUucnRuQlABJCZCUAEkJkJQASQmQlABJCZDUEq/p33A/Xz76s3K6Xe31C25AUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAAAAAAAAAAAAAAAAAAAAAAAAAgJmZeQIPSwdO909DOAAAAABJRU5ErkJggg=="
  , ud = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAANZJREFUeJzt2TEOgCAQAEH1/3/WD1Bg4xqYqSkI2VxxHAcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8F9nfYEF3JPnvPXAVV+AvQmQlABJCZCUAEkJkJQASQmQlABJ2c5/x4/JgAlISoCkBEhKgKQESEqApARISoCkBEhqq637S7M/F7O89YAJSEqApARISoCkBEhKgKQESEqApAQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsLgHY18DOvAubYkAAAAASUVORK5CYII="
  , sd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPZJREFUeJzt2zEKAjEQQNFVrPUS3v9Eewm9gFaWwhSBvyzv1Us2hE+aIdsGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABzXpd7ACXyG343O+v14jta7v/ZkvdWuxU/hR4CkBEhKgKQESEqApARISoCkBEjqVm/gBKYThOnEZGQ64Tg6NyApAZISICkBkhIgKQGSEiApAZISIClvQv5bPWlYetZHf+sx5QYkJUBSAiQlQFICJCVAUgIkJUBSAgQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4uS/nUhFhuDKsYwAAAABJRU5ErkJggg=="
  , ad = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAN1JREFUeJzt1MEJwzAUBUEn5Ow0kf4rchNOA04JuRhWSDMFfB5o0bYBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAON61AMCVz3gj6Xe5FkPYG0CJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFKvesCovu/Prff287j13iz8gKQESEqApARISoCkBEhKgKQESEqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJP7AZSsBD5rw6XxAAAAAElFTkSuQmCC"
  , cd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAN1JREFUeJzt28ENgCAQAEG1/561ARN5aJbgTAWHbHhg2DYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAYF57PUDgrAd48Ks9OeoB+DcBkhIgKQGSEiApAZISICkBkhIgqZVu3Uf/cMy+5lXWMcQJSEqApARISoCkBEhKgKQESEqApARIaonb9I+8/XbEt77hBCQlQFICJCVAUgIkJUBSAiQlQFICBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFjcBQOJBDr13FoQAAAAAElFTkSuQmCC"
  , gd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAL5JREFUeJzt2TEOgCAQAEH0/3/WB9hA4yYwU1NcchsKGAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4H9XPQAfz+S5LXZ31wNwNgGSEiApAZISICkBkhIgKQGSEiCpLV7TF83+NFSO2okbkJQASQmQlABJCZCUAEkJkJQASQkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAYHMvNXkCJ8bvmZsAAAAASUVORK5CYII="
  , fd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMJJREFUeJzt2cENgCAQAEG0/561BUyMa2DmzYMLGx4wBgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADA9456Ay+6Jtf9feZV5phy1htgbwIkJUBSAiQlQFICJCVAUgIkJUBSS7ymPzT701DZ6kzcgKQESEqApARISoCkBEhKgKQESEqAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIu7Aa4MAyfjzJQTAAAAAElFTkSuQmCC"
  , dd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAORJREFUeJzt2UEKAjEQAMHor4XFgwg+232BsAexh7XqHMIQmhyStQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAuS71AL/2vL/eR9Ztj9uhs5m+33TXegD+mwBJCZCUAEkJkJQASQmQlABJCZDUKV7T15r/IzF9v4obkJQASQmQlABJCZCUAEkJkJQASQmQ1OhXcj7zEwJfIEBSAiQlQFICJCVAUgIkJUBSAgQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4uR1OuSA6lEpuvgAAAABJRU5ErkJggg=="
  , Cd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAYJJREFUeJzt27FNQzEARdGA2IKGjhJGYQskNmILJmCHlOnSMAcsgISlb3R/4nNqF5Zy5eI/5XAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPbrpr5A4Lu+wB+W+k1u6wuwNgGSEiApAZISICkBkhIgKQGSEiCpa/rqPnXhePt8Hjr39PgwdO71/mPDbX51Fb+dF5CUAEkJkJQASQmQlABJCZCUAEkJkNQlfE2funC8f70MnTuezkPnRpeQUastJl5AUgIkJUBSAiQlQFICJCVAUgIkJUBSd/UFBkz9kn88nYeWldGF4x8Wk10vF7N5AUkJkJQASQmQlABJCZCUAEkJkJQASV3CEjLV7IWDbbyApARISoCkBEhKgKQESEqApARISoCklltCZv+Hw2KyjReQlABJCZCUAEkJkJQASQmQlABJCZDUckuIhWNfvICkBEhKgKQESEqApARISoCkBEhKgKR+AJLyHx2NKLWHAAAAAElFTkSuQmCC"
  , pd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQ5JREFUeJzt29EJwjAUQNFWdCpXcFxXcCo/6goVAjdtzxkgPMglHwlZFgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA4vrUeYIetHuDgpt7jWz0A1yZAUgIkJUBSAiQlQFICJCVAUgIkda8HGGXbrvVgsq5TP3Ds5gQkJUBSAiQlQFICJCVAUgIkJUBSAiR1hOv0oU8c3+dr5HLDPT7v0UtOvcdOQFICJCVAUgIkJUBSAiQlQFICJCVAUlPfkv/pWp9CTrJ3TkBSAiQlQFICJCVAUgIkJUBSAiQlQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAICT+wHIwglQ8OWJHwAAAABJRU5ErkJggg=="
  , Sd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMFJREFUeJzt2TEKwCAQAEGT//85afIAq6zoTC1ywmKhYwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/O+qB5jwTK6bPctp+y3trgfgbAIkJUBSAiQlQFICJCVAUgIkJUBSW7ymf1b/kVh9v4QbkJQASQmQlABJCZCUAEkJkJQASQkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAYHMvtv4IJxX2QqwAAAAASUVORK5CYII="
  , Ed = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOVJREFUeJzt2cEJwzAQAMFT+u/ZaSAfw5EFe6YAIU6LHtIMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMD/nXoDi67Vxa7V5eac9VE/4uw+9QZ4NwGSEiApAZISICkBkhIgKQGSEiCpR7ym37T7xbHvVWfiBiQlQFICJCVAUgIkJUBSAiQlQFICJPWqV/ebtn9MzPoHNyApAZISICkBkhIgKQGSEiApAZISIAAAAAAAAAAAAAAAAAAAAAAAAMDMzHwBG4UGOuE2ev8AAAAASUVORK5CYII="
  , Qd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAN5JREFUeJzt1MENwkAQBMEFQVSkQLhOgaj8MAnwPNEWVAUwWp1aNwMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8H2X+oATOxbveesPrvUB/DcBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISIKlbfcBZ7Y/n0r37a1u69yv8gKQESEqApARISoCkBEhKgKQESEqAAAAAAAAAAAAAAAAAAAAAAAAAADMz8wZkdAQ+UpWMmgAAAABJRU5ErkJggg=="
  , kd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMFJREFUeJzt2cENgCAQAEG0/561BR/oEpkpgHBhwwPGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD43lFv4IFr8nqrz7zVvGe9AfYmQFICJCVAUgIkJUBSAiQlQFICJLX0K/lLZv80zLbVmbgBSQmQlABJCZCUAEkJkJQASQmQlAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAfu4G1bwDJ5JgmqMAAAAASUVORK5CYII="
  , Id = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAR1JREFUeJzt3TEOgkAUQMHVWOslvP+JvIKF9gYvYIEJ8WF2pibsD7xsAQmMAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/71APsGPLxudzrT841gMwNwGSEiApAZISICkBkhIgKQGSEiCpGZ/Or3rD8bxcN130/LitPXSqe2IHJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJHWqB9ir+/KqR5iCHZCUAEkJkJQASQmQlABJCZCUAEkJkNRUX2L6kv+E/IAdkJQASQmQlABJCZCUAEkJkJQASQkQAAAAAAAAAAAAAAAAAAAAAAAAYIwxxhvsqglNI6UONwAAAABJRU5ErkJggg=="
  , hd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAPhJREFUeJzt3DEKxCAUANEY9v5XNlWqbVIIQ8x7F/gKg80HjwMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA3m+Es2c4m39JC2cxFG4CJCVAUgIkJUBSAiQlQFICJCVAUr/6AKvMuXaxMkazJNrlHk95AUkJkJQASQmQlABJCZCUAEkJkJQASW2zCXlq9Wag2lysnlvxApISICkBkhIgKQGSEiApAZISICkBkvI7Fje/Y/E9AiQlQFICJCVAUgIkJUBSAiQlQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIDNXT7qDlNkU+UMAAAAAElFTkSuQmCC"
  , md = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMRJREFUeJzt2cEJwCAQAMGY/ns2LSQgWdCZBk508aHXBQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwv1EvYKGZDJ3vxo6xfKu3OLu7XgBnEyApAZISICkBkhIgKQGSEiApAZLa4jX9o+TH5IOjzsQNSEqApARISoCkBEhKgKQESEqApAQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAsLkHBHkFJ9SI9xoAAAAASUVORK5CYII="
  , Jd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOBJREFUeJzt2zEOhCAUQEHd+99ZL7AFheZ9daY2BMMLBYRtAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADm2usJXOhY/G71n6eP9wq/egJ8mwBJCZCUAEkJkJQASQmQlABJCZDUE07dV28Q+G/0GtsBSQmQlABJCZCUAEkJkJQASQmQlABJjT4lv8n0tx6fejtiByQlQFICJCVAUgIkJUBSAiQlQFICBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHi5E3zKBzooVJUAAAAAAElFTkSuQmCC"
  , Ud = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAANFJREFUeJzt28EJgDAQRcEo9t+yluBFeIuZqeBDHrntWgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAXEc9IHDXA15s9SZnPYC9CZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJXfWAwb6+zZh+i5LwA5ISICkBkhIgKQGSEiApAZISICkBAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPzcA5jkAj6F2mDwAAAAAElFTkSuQmCC"
  , Bd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOhJREFUeJzt27ENAyEQAEFw/z3jxAWc9MFie6aA1yFWBI9YCwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC4164HGDj1AF/u6j1+1QPw3wRISoCkBEhKgKQESEqApARISoCkyr/koxuOc2YXIXvPllJ9b6pax4pacAKSEiApAZISICkBkhIgKQGSEiApAZK6+r3Ahzchz1y9x05AUgIkJUBSAiQlQFICJCVAUgIkJUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAH/cG7ogPOkaeAYcAAAAASUVORK5CYII="
  , vd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAANpJREFUeJzt20EKgCAAAEHr/3+uD3QwCFZy5iwosnhQHAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWNdRLyBwTY77em+qeZd21gtgbwIkJUBSAiQlQFICJCVAUgIkJUBSW926vzT7cjHLXj9wApISICkBkhIgKQGSEiApAZISICkBktrxdt6fkIU4AUkJkJQASQmQlABJCZCUAEkJkJQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH7uBtyFBTpR1LdIAAAAAElFTkSuQmCC"
  , Kd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQRJREFUeJzt1FEJw0AURcGkREYcVVA1RFAcxUdqoD+FhbO0MwIuj+WwywIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAzGutD5jYPXjPW3/wqA/gvwmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASW31AbM6rufQvdd+Dt37FX5AUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQAAAAAAAAAAAAAAAAOArb4pkBGadem8XAAAAAElFTkSuQmCC"
  , yd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAL1JREFUeJzt2cEJwCAQAEGT/ns2NQSCG3SmgoNb7qFjAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACsd9UDfGjWAyy2xe7uegDOJkBSAiQlQFICJCVAUgIkJUBSAiS1xWv6S3//MTlqJy4gKQGSEiApAZISICkBkhIgKQGSEiAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADA5h78sQInVXJchAAAAABJRU5ErkJggg=="
  , Rd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMRJREFUeJzt0sENwDAQw7Ck++/cjNCngh45gQFrLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADgXrseEHjrAR9GffLUA5hNgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApAQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8HMH0N0BPFtsXuoAAAAASUVORK5CYII="
  , wd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAShJREFUeJzt28ENwjAQAEFAvKEJ+q+IJqABaMFBhg3JTAGXSF75YcuHAwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD7cfzCzFf4bT6XrNtp5jBYSoCkBEhKgKQESEqApARISoCkBEiqvI0YPXnnN5IW7ICkBEhKgKQESEqApARISoCkBEhKgKT+4V3G1BuT5/U2c9x0l8d99shVr7EdkJQASQmQlABJCZCUAEkJkJQASQmQ1Ln+gQGjJ/l7e2Oy6huOUXZAUgIkJUBSAiQlQFICJCVAUgIkJUBSmzhNX2jtNya7WhM7ICkBkhIgKQGSEiApAZISICkBkhIgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwMa9ATYxCWSXJjvqAAAAAElFTkSuQmCC"
  , Fd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAHpJREFUeJztwTEBAAAAwqD1T20ND6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4MJCvAAFltxurAAAAAElFTkSuQmCC"
  , Nd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAVJJREFUeJzt29EJwyAAQMFYuv/KdoFCUio8Se4GSEQffigeBwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACwr1EPYJU551z5vTHG0rnZfXyVVz0Ank2ApARISoCkBEhKgKQESEqApARI6l0P4MzqG4Rffh3995Kr87L7jYkdkJQASQmQlABJCZCUAEkJkJQASQmQVHZKXt1w7H4z8LR5sQOSEiApAZISICkBkhIgKQGSEiApAZJa/iYkfMPBH6o3JnZAUgIkJUBSAiQlQFICJCVAUgIkJUBSl0+1d7/h2P2tx2p3WQ87ICkBkhIgKQGSEiApAZISICkBkhIgqXGXE3W+23197YCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkPurWMYBlM+/6AAAAAElFTkSuQmCC"
  , zd = [ed, td, nd, rd, ld, od, id, ud, sd, ad, cd, gd, fd, dd, Cd, pd, Sd, Ed, Qd, kd, Id, hd, md, Jd, Ud, Bd, vd, Kd, yd, Rd, wd, Fd, Nd]
  , Jn = {
  ...zd
}
  , Vd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAHpJREFUeJztwTEBAAAAwqD1T20ND6AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4MJCvAAFltxurAAAAAElFTkSuQmCC"
  , Zd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAK9JREFUeJzt0kENgDAQRNEWNzWCKc6YwghyioQeIBmSfU/AZif5rQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABQR08/sHIf1/zy3jj3X2+utndLP0BtAiRKgEQJkCgBEiVAogRIlACJEiAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwEsPNfEIFIRqPFUAAAAASUVORK5CYII="
  , Od = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAMNJREFUeJzt0gENwlAQBcGCIjQgB0HIqQYctRqaXLK/YUbAyyW32wYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA9/eYHvzsr2Ny7/v+jd/4T1b/x3NyDK4SICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAQIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADAYk5erwgo5A/FhgAAAABJRU5ErkJggg=="
  , xd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAP1JREFUeJzt3DEOgCAQAEEw/v/L+AVjs6gzNcUlbGgIjAEAAAAAAPBVsx6AZ9Za6866OefWe3zUA/BvAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSZz0Ae6jemDgBSQmQlABJCZCUAEkJkJQASQmQlABJuQl5qd1/vbrLCUhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBEhKgKQESEqApARISoCkBAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwO9cXfAIbOeYeREAAAAASUVORK5CYII="
  , Gd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAR9JREFUeJzt20EKhDAQAEGz//9zvHsKRGxdqs4SBJs5OOQ4AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALgaqw/OOefSgWMsn8n33N3Bb+91YI8ASQmQlABJCZCUAEkJkJQASQmQ1O1bCxuTb6q+mwlISoCkBEhKgKQESEqApARISoCkBEgq20as/nnnGdVmygQkJUBSAiQlQFICJCVAUgIkJUBSAiT1+nsZNiZ73n73xgQkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICJCVAUgIkJUBSAiQlQFICBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAPhzJxURGHXpaFDwAAAAAElFTkSuQmCC"
  , Pd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAL9JREFUeJzt0sENQAAQAEF0oxFNeWtKI8rhpQPJIjMF3OUuOwwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA8H1jtfhY9/PJefO2ZLcU/vK/qVgKNwGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALzMBc8vCCjAF+3vAAAAAElFTkSuQmCC"
  , Ld = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAL1JREFUeJzt0tENQAAQBUEUQBP6r0gTNEADPsVKzBRwebnsMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwH2M94KuOZT2fvDfvm1/fmOoB/JsASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJkJQASQmQlABJCZCUAEkJEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB4yQXNegQo83ZjdAAAAABJRU5ErkJggg=="
  , Td = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAL5JREFUeJzt0tENQAAMQEEMwBL2n8gSLMCXDSQPuRugTZs3DAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADwfWO1+FjW88l5875ltxT+8r+pWAo3AZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiApAZISICkBkhIgKQGSEiAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvMwFPs8IKFU4PxsAAAAASUVORK5CYII="
  , qd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAVBJREFUeJzt3LFNA0EQQFGbMrC7IKEBUlpwbbRASgMkdGFoAzpAF4z8V773Cjjtyl8bzEg+HAAAAAAAAO7VsT7Arb28f/7WZ/jPx+vzrn6Th/oA7JsASQmQlABJCZCUAEkJkJQASQmQ1PJT9+nNxePpPPm5cT/f19Hvrb5Z8QKSEiApAZISICkBkhIgKQGSEiApAZLKpuRbNxyrby5Wt3WzUm1MvICkBEhKgKQESEqApARISoCkBEhKgKTGp9/TG463p/PoGS9f16X/Hau6b7Ux8QKSEiApAZISICkBkhIgKQGSEiApAZIa34RsnbxPT/ynTW9M7uW+0/fwApISICkBkhIgKQGSEiApAZISICkBAgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACwO39NvS1g86VnpwAAAABJRU5ErkJggg=="
  , Md = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAVRJREFUeJzt3VEKwjAUAMFEvP+V6wmEFGo30pnvQgou7yMPdY7nOZYeOpYeu9yccyYHR171C/BsAiQlQFICJCVAUgIkJUBSAiQlQFL/cOt+6Uoi3HAk547NP2MTkJQASQmQlABJCZCUAEkJkJQASQmQVHlLvvV3M1ad2HBsvZGomICkBEhKgKQESEqApARISoCkBEhKgKQuv50/dl9dLLLhuIcJSEqApARISoCkBEhKgKQESEqApARIavkWf/cNxw9+fcqG4wYmICkBkhIgKQGSEiApAZISICkBkhIgqXd1sM0FY5iAxARISoCkBEhKgKQESEqApARISoCk5rj4H8lPns3DmYCkBEhKgKQESEqApARISoCkBEhKgAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAfPEB7E4VjTwEFu0AAAAASUVORK5CYII="
  , jd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAQ5JREFUeJzt27EJAjEAQNFTrHUJ95/oltAFtLITTHHwQ3yvPkjIfVIkZNsAAAAAAABWdaoGft7ur5Hvro89m+MKZl/nczEofAiQlABJCZCUAEkJkJQASQmQlABJXeoJ/DL7SX5ldF1mZwckJUBSAiQlQFICJCVAUgIkJUBSAiQ1fHtw9Mn7v91czK76v3ZAUgIkJUBSAiQlQFICJCVAUgIkJUBSw29CRk+2V3mrwHdH32DZAUkJkJQASQmQlABJCZCUAEkJkJQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABgGW94KhxTO3H4vwAAAABJRU5ErkJggg=="
  , Dd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAARBJREFUeJzt28EJwjAAQNEqjuEWDuRsHcgt3ENP3gRzKPwQ3zsXEtJPDgnZNgAAAAAAgFWdqoHvj+dr5Lv9ds3muILZ1/lcDAofAiQlQFICJCVAUgIkJUBSAiQlQFKXegK/zH6SXxldl9nZAUkJkJQASQmQlABJCZCUAEkJkJQASQ3fHhx98v5vNxezq/6vHZCUAEkJkJQASQmQlABJCZCUAEkJkNTwm5DRk+1V3irw3dE3WHZAUgIkJUBSAiQlQFICJCVAUgIkJUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAFjGG72/HFMBsDvxAAAAAElFTkSuQmCC"
  , Xd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAOJJREFUeJzt27ENhDAQAEEgox8KodQvhH4IISJ6AoKXFulnGrjAKwe2bhwi+7Ye1Wy+zctnLOZOxVC4CJCUAEkJkJQASQmQlABJCZCUAEk9fv3+9c9F9fLOvep83YCkBEhKgKQESEqApARISoCkBEhKgKSy3wg7Ie9iJ4S/JEBSAiQlQFICJCVAUgIkJUBSAgQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMicLIsQOvgLclsAAAAASUVORK5CYII="
  , Yd = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKAAAACgCAYAAACLz2ctAAAAAXNSR0IArs4c6QAAAONJREFUeJzt27ENhDAQAEGgDrqgIGr7guiCPiAiegKClxbpZxq4wCsHtm4cIuu2H9Vsvn2WeSzmTsVQuAiQlABJCZCUAEkJkJQASQmQlABJPX79/vXPRfXyzr3qfN2ApARISoCkBEhKgKQESEqApARISoCkst8IOyHvYieEvyRAUgIkJUBSAiQlQFICJCVAUgIEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADInCFLEDoivXHrAAAAAElFTkSuQmCC"
  , Hd = [Vd, Zd, Od, xd, Gd, Pd, Ld, Td, qd, Md, jd, Dd, Xd, Yd]
  , Un = {
  ...Hd
}

function makeImage(url: string) {
  const img = new Image()
  img.src = url
  return img
}

images := document.createElement 'div'
images.style.display = 'grid'
images.style.gridTemplateColumns = 'repeat(4, 1fr)'
images.style.gap = '16px'

document.body.append images

eyes := Kf.map makeImage

images.append ...eyes
images.append ...Ad.map makeImage
images.append ...zd.map makeImage
images.append ...Hd.map makeImage

// TODO: copy to canvas and shrink by 10x to become a spritesheet of16x16 images

function drawToCanvas(imageEls: HTMLImageElement[])
  canvas := document.createElement 'canvas'
  canvas.width = 16 * imageEls.length
  canvas.height = 16
  ctx := canvas.getContext('2d')!

  // set rendering to nearest neighbor
  ctx.imageSmoothingEnabled = false

  imageEls.forEach (img, i) =>
    await imageLoaded img
    ctx.drawImage img, 0, 0, 160, 160, i * 16, 0, 16, 16

  images.insertBefore canvas, eyes[0]

drawToCanvas eyes
drawToCanvas zd.map makeImage
drawToCanvas Hd.map makeImage

function imageLoaded(img: HTMLImageElement)
  new Promise (resolve) =>
    if img.complete
      resolve img
    else
      img.onload = =>
        resolve img


export {}
