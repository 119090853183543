import {
  $C,
  $E,
  $EVENT,
  $EVENT_C,
  $EXPECT,
  $L,
  $N,
  $P,
  $Q,
  $R,
  $R$0,
  $S,
  $T,
  $TEXT,
  $TR,
  $TS,
  $TV,
  $Y,
  ParseError,
  Validator,
} from "@danielx/hera/lib"


const grammar = {
    Template: Template,
Line: Line,
LineBody: LineBody,
RestOfLine: RestOfLine,
DeprecatedEquals: DeprecatedEquals,
Tag: Tag,
OptionalClasses: OptionalClasses,
Classes: Classes,
Class: Class,
OptionalIds: OptionalIds,
Ids: Ids,
Id: Id,
IdError: IdError,
ClassError: ClassError,
TagName: TagName,
OptionalAttributes: OptionalAttributes,
Attribute: Attribute,
AtIdentifier: AtIdentifier,
EqBinding: EqBinding,
Identifier: Identifier,
Indent: Indent,
_: _,
__: __,
Value: Value,
DoubleStringCharacter: DoubleStringCharacter,
SingleStringCharacter: SingleStringCharacter,
EscapeSequence: EscapeSequence,
Number: Number,
EOS: EOS,
EOL: EOL,
EOF: EOF
  };

const $L0 = $L("|");
const $L1 = $L(" ");
const $L2 = $L("=");
const $L3 = $L("");
const $L4 = $L(".");
const $L5 = $L("#");
const $L6 = $L("(");
const $L7 = $L(")");
const $L8 = $L("@");
const $L9 = $L("  ");
const $L10 = $L("\t");
const $L11 = $L("\"");
const $L12 = $L("'");
const $L13 = $L("\\");
const $L14 = $L("\r\n");
const $L15 = $L("\n");
const $L16 = $L("\r");


const $R0 = $R(new RegExp("[^\\n\\r]*", 'suy'));
const $R1 = $R(new RegExp("[a-zA-Z][a-zA-Z0-9-]*", 'suy'));
const $R2 = $R(new RegExp("[ \\t]+", 'suy'));
const $R3 = $R(new RegExp("[ \\t]", 'suy'));
const $R4 = $R(new RegExp(".", 'suy'));
const $R5 = $R(new RegExp("-?[0-9]+\\.[0-9]+", 'suy'));
const $R6 = $R(new RegExp("-?[0-9]+", 'suy'));
const $R7 = $R(new RegExp("[\\s\\S]", 'suy'));


//@ts-ignore
const Template$0 = $TS($S($E(__), $Q(Line)), function($skip, $loc, $0, $1, $2) {

var top = a => a[a.length-1];

function reduceLines(lines) {
  var depth = 0;
  var stack = [[]];
  var firstIndent = 0;

  lines.forEach( ([indent, line]) => {
    if (firstIndent === 0 && indent > depth + 1) {
      firstIndent = indent - 1;
    }
    indent = indent > firstIndent ? indent - firstIndent : indent;

    if (Array.isArray(line)) {
      line[1] = collectAttributes(line[1])
    }

    if (depth+1 === indent) {
      // We're adding to the content of the last element in the current stack
      stack.push(top(top(stack))[2])
    } else if ( indent > depth) {
      throw new Error("Indented too far")
    } else if (indent < depth) {
      stack = stack.slice(0, indent + 1)
    }

    depth = indent
    top(stack).push(line)
  })

  return stack[0]
}

function collectAttributes(attributesArray) {
  return attributesArray.reduce((o, [key, value]) => {
    if (key === "id" || key === "class" || key === "style") {
      var p = o[key] || (o[key] = [])
      p.push(value)
    } else {
      o[key] = value
    }
    return o
  }, {})
}

function pretty(lines) {
  return lines.map(line =>
    JSON.stringify(line)
  )
}

var reduced = reduceLines($2);

if (reduced.length != 1) {
  throw new Error("Must have exactly one root node.");
}

return reduced[0];
});
//@ts-ignore
function Template(ctx, state) { return $EVENT(ctx, state, "Template", Template$0) }

//@ts-ignore
const Line$0 = $T($S(Indent, LineBody, EOS), function(value) {return [value[0], value[1]] });
//@ts-ignore
function Line(ctx, state) { return $EVENT(ctx, state, "Line", Line$0) }

//@ts-ignore
const LineBody$0 = $TS($S(Tag, $E(DeprecatedEquals), _, RestOfLine), function($skip, $loc, $0, $1, $2, $3, $4) {

$1[2].push($4)
return $1
});
//@ts-ignore
const LineBody$1 = $T($S(Tag, $E(_)), function(value) {return value[0] });
//@ts-ignore
const LineBody$2 = $TS($S($EXPECT($L0, "LineBody \"|\""), $E($EXPECT($L1, "LineBody \" \"")), RestOfLine), function($skip, $loc, $0, $1, $2, $3) {

return $3 + "\n";
});
//@ts-ignore
const LineBody$3 = $T($S($E($S(DeprecatedEquals, _)), RestOfLine), function(value) {return value[1] });
//@ts-ignore
const LineBody$$ = [LineBody$0,LineBody$1,LineBody$2,LineBody$3]
//@ts-ignore
function LineBody(ctx, state) { return $EVENT_C(ctx, state, "LineBody", LineBody$$) }

//@ts-ignore
const RestOfLine$0 = $TR($EXPECT($R0, "RestOfLine /[^\\n\\r]*/"), function($skip, $loc, $0, $1, $2, $3, $4, $5, $6, $7, $8, $9) {
// TODO: Handle runs of text with bound content inside
if ($0.slice(0,1) === "@") {
  return {
    bind: $0.slice(1)
  }
} else {
  return $0
}
});
//@ts-ignore
function RestOfLine(ctx, state) { return $EVENT(ctx, state, "RestOfLine", RestOfLine$0) }

//@ts-ignore
const DeprecatedEquals$0 = $TV($EXPECT($L2, "DeprecatedEquals \"=\""), function($skip, $loc, $0, $1) {

console.warn("'= <content>' is deprecated, you can remove the '=' without issue.")
});
//@ts-ignore
function DeprecatedEquals(ctx, state) { return $EVENT(ctx, state, "DeprecatedEquals", DeprecatedEquals$0) }

//@ts-ignore
const Tag$0 = $TS($S(TagName, OptionalIds, OptionalClasses, OptionalAttributes), function($skip, $loc, $0, $1, $2, $3, $4) {

return [
  $1,
  $2.concat($3, $4),
  [],
]
});
//@ts-ignore
const Tag$1 = $TS($S(Ids, OptionalClasses, OptionalAttributes), function($skip, $loc, $0, $1, $2, $3) {

return [
  "div",
  $1.concat($2, $3),
  [],
]
});
//@ts-ignore
const Tag$2 = $TS($S(Classes, OptionalAttributes), function($skip, $loc, $0, $1, $2) {

return [
  "div",
  $1.concat($2),
  [],
]
});
//@ts-ignore
const Tag$$ = [Tag$0,Tag$1,Tag$2]
//@ts-ignore
function Tag(ctx, state) { return $EVENT_C(ctx, state, "Tag", Tag$$) }

//@ts-ignore
const OptionalClasses$0 = Classes
//@ts-ignore
const OptionalClasses$1 = $TV($EXPECT($L3, "OptionalClasses \"\""), function($skip, $loc, $0, $1) {

return []
});
//@ts-ignore
const OptionalClasses$$ = [OptionalClasses$0,OptionalClasses$1]
//@ts-ignore
function OptionalClasses(ctx, state) { return $EVENT_C(ctx, state, "OptionalClasses", OptionalClasses$$) }

//@ts-ignore
const Classes$0 = $P(Class)
//@ts-ignore
function Classes(ctx, state) { return $EVENT(ctx, state, "Classes", Classes$0) }

//@ts-ignore
const Class$0 = $TS($S($EXPECT($L4, "Class \".\""), Identifier), function($skip, $loc, $0, $1, $2) {

return ["class", $2]
});
//@ts-ignore
const Class$1 = $TS($S($EXPECT($L4, "Class \".\""), $N(Identifier)), function($skip, $loc, $0, $1, $2) {

throw "Expected a class name"
});
//@ts-ignore
const Class$2 = IdError
//@ts-ignore
const Class$$ = [Class$0,Class$1,Class$2]
//@ts-ignore
function Class(ctx, state) { return $EVENT_C(ctx, state, "Class", Class$$) }

//@ts-ignore
const OptionalIds$0 = $TV($E(Ids), function($skip, $loc, $0, $1) {

return $1 || []
});
//@ts-ignore
function OptionalIds(ctx, state) { return $EVENT(ctx, state, "OptionalIds", OptionalIds$0) }

//@ts-ignore
const Ids$0 = $TS($S(Id), function($skip, $loc, $0, $1) {

return [ $1 ]
});
//@ts-ignore
function Ids(ctx, state) { return $EVENT(ctx, state, "Ids", Ids$0) }

//@ts-ignore
const Id$0 = $TS($S($EXPECT($L5, "Id \"#\""), Identifier), function($skip, $loc, $0, $1, $2) {

return ["id", $2]
});
//@ts-ignore
const Id$1 = $TS($S($EXPECT($L5, "Id \"#\""), $N(Identifier)), function($skip, $loc, $0, $1, $2) {

throw "Expected an id name"
});
//@ts-ignore
const Id$$ = [Id$0,Id$1]
//@ts-ignore
function Id(ctx, state) { return $EVENT_C(ctx, state, "Id", Id$$) }

//@ts-ignore
const IdError$0 = $TV($EXPECT($L5, "IdError \"#\""), function($skip, $loc, $0, $1) {

throw "Ids must appear before classes and attributes. Elements can only have one id."
});
//@ts-ignore
function IdError(ctx, state) { return $EVENT(ctx, state, "IdError", IdError$0) }

//@ts-ignore
const ClassError$0 = $TV($EXPECT($L4, "ClassError \".\""), function($skip, $loc, $0, $1) {

throw "Classes cannot appear after attributes."
});
//@ts-ignore
function ClassError(ctx, state) { return $EVENT(ctx, state, "ClassError", ClassError$0) }

//@ts-ignore
const TagName$0 = Identifier
//@ts-ignore
function TagName(ctx, state) { return $EVENT(ctx, state, "TagName", TagName$0) }

//@ts-ignore
const OptionalAttributes$0 = $TS($S($EXPECT($L6, "OptionalAttributes \"(\""), $E(__), $P(Attribute), $EXPECT($L7, "OptionalAttributes \")\""), $E(IdError), $E(ClassError)), function($skip, $loc, $0, $1, $2, $3, $4, $5, $6) {

return $3
});
//@ts-ignore
const OptionalAttributes$1 = $TV($EXPECT($L6, "OptionalAttributes \"(\""), function($skip, $loc, $0, $1) {

throw "Invalid attributes"
});
//@ts-ignore
const OptionalAttributes$2 = $TV($EXPECT($L3, "OptionalAttributes \"\""), function($skip, $loc, $0, $1) {

return []
});
//@ts-ignore
const OptionalAttributes$$ = [OptionalAttributes$0,OptionalAttributes$1,OptionalAttributes$2]
//@ts-ignore
function OptionalAttributes(ctx, state) { return $EVENT_C(ctx, state, "OptionalAttributes", OptionalAttributes$$) }

//@ts-ignore
const Attribute$0 = $TS($S(AtIdentifier, $E(__)), function($skip, $loc, $0, $1, $2) {

return [$1.bind, $1]
});
//@ts-ignore
const Attribute$1 = $T($S(EqBinding, $E(__)), function(value) {return value[0] });
//@ts-ignore
const Attribute$2 = $TS($S(Identifier, $E(__)), function($skip, $loc, $0, $1, $2) {

return [$1, ""]
});
//@ts-ignore
const Attribute$$ = [Attribute$0,Attribute$1,Attribute$2]
//@ts-ignore
function Attribute(ctx, state) { return $EVENT_C(ctx, state, "Attribute", Attribute$$) }

//@ts-ignore
const AtIdentifier$0 = $TS($S($EXPECT($L8, "AtIdentifier \"@\""), Identifier), function($skip, $loc, $0, $1, $2) {

return {
  bind: $2
}
});
//@ts-ignore
function AtIdentifier(ctx, state) { return $EVENT(ctx, state, "AtIdentifier", AtIdentifier$0) }

//@ts-ignore
const EqBinding$0 = $T($S(Identifier, $EXPECT($L2, "EqBinding \"=\""), $C(AtIdentifier, Value)), function(value) {return [value[0], value[2]] });
//@ts-ignore
function EqBinding(ctx, state) { return $EVENT(ctx, state, "EqBinding", EqBinding$0) }

//@ts-ignore
const Identifier$0 = $R$0($EXPECT($R1, "Identifier /[a-zA-Z][a-zA-Z0-9-]*/"))
//@ts-ignore
function Identifier(ctx, state) { return $EVENT(ctx, state, "Identifier", Identifier$0) }

//@ts-ignore
const Indent$0 = $TV($Q($C($EXPECT($L9, "Indent \"  \""), $EXPECT($L10, "Indent \"\\\\t\""))), function($skip, $loc, $0, $1) {

return $1.length
});
//@ts-ignore
function Indent(ctx, state) { return $EVENT(ctx, state, "Indent", Indent$0) }

//@ts-ignore
const _$0 = $R$0($EXPECT($R2, "_ /[ \\t]+/"))
//@ts-ignore
function _(ctx, state) { return $EVENT(ctx, state, "_", _$0) }

//@ts-ignore
const __$0 = $P($C($R$0($EXPECT($R3, "__ /[ \\t]/")), EOL))
//@ts-ignore
function __(ctx, state) { return $EVENT(ctx, state, "__", __$0) }

//@ts-ignore
const Value$0 = $TS($S($EXPECT($L11, "Value \"\\\\\\\"\""), $Q(DoubleStringCharacter), $EXPECT($L11, "Value \"\\\\\\\"\"")), function($skip, $loc, $0, $1, $2, $3) {

return $2.join("")
});
//@ts-ignore
const Value$1 = $TS($S($EXPECT($L12, "Value \"'\""), $Q(SingleStringCharacter), $EXPECT($L12, "Value \"'\"")), function($skip, $loc, $0, $1, $2, $3) {

return $2.join("")
});
//@ts-ignore
const Value$2 = Number
//@ts-ignore
const Value$$ = [Value$0,Value$1,Value$2]
//@ts-ignore
function Value(ctx, state) { return $EVENT_C(ctx, state, "Value", Value$$) }

//@ts-ignore
const DoubleStringCharacter$0 = $T($S($N($C($EXPECT($L11, "DoubleStringCharacter \"\\\\\\\"\""), $EXPECT($L13, "DoubleStringCharacter \"\\\\\\\\\""))), $EXPECT($R4, "DoubleStringCharacter /./")), function(value) {return value[1] });
//@ts-ignore
const DoubleStringCharacter$1 = $T($S($EXPECT($L13, "DoubleStringCharacter \"\\\\\\\\\""), EscapeSequence), function(value) {return value[1] });
//@ts-ignore
const DoubleStringCharacter$$ = [DoubleStringCharacter$0,DoubleStringCharacter$1]
//@ts-ignore
function DoubleStringCharacter(ctx, state) { return $EVENT_C(ctx, state, "DoubleStringCharacter", DoubleStringCharacter$$) }

//@ts-ignore
const SingleStringCharacter$0 = $T($S($N($C($EXPECT($L12, "SingleStringCharacter \"'\""), $EXPECT($L13, "SingleStringCharacter \"\\\\\\\\\""))), $EXPECT($R4, "SingleStringCharacter /./")), function(value) {return value[1] });
//@ts-ignore
const SingleStringCharacter$1 = $T($S($EXPECT($L13, "SingleStringCharacter \"\\\\\\\\\""), EscapeSequence), function(value) {return value[1] });
//@ts-ignore
const SingleStringCharacter$$ = [SingleStringCharacter$0,SingleStringCharacter$1]
//@ts-ignore
function SingleStringCharacter(ctx, state) { return $EVENT_C(ctx, state, "SingleStringCharacter", SingleStringCharacter$$) }

//@ts-ignore
const EscapeSequence$0 = $EXPECT($L12, "EscapeSequence \"'\"")
//@ts-ignore
const EscapeSequence$1 = $EXPECT($L11, "EscapeSequence \"\\\\\\\"\"")
//@ts-ignore
const EscapeSequence$2 = $EXPECT($L13, "EscapeSequence \"\\\\\\\\\"")
//@ts-ignore
const EscapeSequence$3 = $TR($EXPECT($R4, "EscapeSequence /./"), function($skip, $loc, $0, $1, $2, $3, $4, $5, $6, $7, $8, $9) {
return "\\" + $0
});
//@ts-ignore
const EscapeSequence$$ = [EscapeSequence$0,EscapeSequence$1,EscapeSequence$2,EscapeSequence$3]
//@ts-ignore
function EscapeSequence(ctx, state) { return $EVENT_C(ctx, state, "EscapeSequence", EscapeSequence$$) }

//@ts-ignore
const Number$0 = $R$0($EXPECT($R5, "Number /-?[0-9]+\\.[0-9]+/"))
//@ts-ignore
const Number$1 = $R$0($EXPECT($R6, "Number /-?[0-9]+/"))
//@ts-ignore
const Number$$ = [Number$0,Number$1]
//@ts-ignore
function Number(ctx, state) { return $EVENT_C(ctx, state, "Number", Number$$) }

//@ts-ignore
const EOS$0 = $S($P($S($E(_), EOL)), _, EOF)
//@ts-ignore
const EOS$1 = $P($S($E(_), EOL))
//@ts-ignore
const EOS$2 = EOF
//@ts-ignore
const EOS$$ = [EOS$0,EOS$1,EOS$2]
//@ts-ignore
function EOS(ctx, state) { return $EVENT_C(ctx, state, "EOS", EOS$$) }

//@ts-ignore
const EOL$0 = $EXPECT($L14, "EOL \"\\\\r\\\\n\"")
//@ts-ignore
const EOL$1 = $EXPECT($L15, "EOL \"\\\\n\"")
//@ts-ignore
const EOL$2 = $EXPECT($L16, "EOL \"\\\\r\"")
//@ts-ignore
const EOL$$ = [EOL$0,EOL$1,EOL$2]
//@ts-ignore
function EOL(ctx, state) { return $EVENT_C(ctx, state, "EOL", EOL$$) }

//@ts-ignore
const EOF$0 = $N($R$0($EXPECT($R7, "EOF /[\\s\\S]/")))
//@ts-ignore
function EOF(ctx, state) { return $EVENT(ctx, state, "EOF", EOF$0) }



const parser = (function() {
  const { fail, validate, reset } = Validator()
  let ctx = { expectation: "", fail }

  return {
    parse: (input, options = {}) => {
      if (typeof input !== "string") throw new Error("Input must be a string")

      const parser = (options.startRule != null)
        ? grammar[options.startRule]
        : Object.values(grammar)[0]

      if (!parser) throw new Error(`Could not find rule with name '${options.startRule}'`)

      const filename = options.filename || "<anonymous>";

      reset()
      Object.assign(ctx, { ...options.events, tokenize: options.tokenize });

      return validate(input, parser(ctx, {
        input,
        pos: 0,
      }), {
        filename: filename
      })
    }
  }
}())

export default parser
export const { parse } = parser

export {
  Template,
  Line,
  LineBody,
  RestOfLine,
  DeprecatedEquals,
  Tag,
  OptionalClasses,
  Classes,
  Class,
  OptionalIds,
  Ids,
  Id,
  IdError,
  ClassError,
  TagName,
  OptionalAttributes,
  Attribute,
  AtIdentifier,
  EqBinding,
  Identifier,
  Indent,
  _,
  __,
  Value,
  DoubleStringCharacter,
  SingleStringCharacter,
  EscapeSequence,
  Number,
  EOS,
  EOL,
  EOF
}

