{
  NineSliceSprite
  Sprite
  Texture
} from 'pixi.js'

export function Scoremeter(borderTexture: Texture): NineSliceSprite & { value: number }
  borderColor := 0x00ffff
  width := 20
  height := 340
  bgColor := 0x000000
  fgColor := 0xff00ff

  border := new NineSliceSprite
    texture: borderTexture
    leftWidth: 1
    topHeight: 1
    rightWidth: 1
    bottomHeight: 1

  border.tint = borderColor
  border.width = width
  border.height = height

  bg := new Sprite Texture.WHITE
  bg.x = bg.y = 1
  bg.width = width - 2
  bg.height = height - 2
  bg.tint = bgColor

  fg := new Sprite Texture.WHITE
  fg.x = fg.y = 1
  fg.width = width - 2
  fg.height = height - 2
  fg.tint = fgColor

  border.addChild bg
  border.addChild fg

  //@ts-expect-error
  return Object.defineProperties border,
    value:
      set: (v) ->
        fg.height = v * (height - 2)
        fg.y = height - fg.height - 1
