{
  clamp
  rand
  rayCircleIntersection
  type Vec2
} from ./util.civet
{
  addStreak
} from ./display.civet
type { Ball } from ./ball.civet
type { Level, PegData } from ./scenes/level.civet

{
  bubbleSpawn
  lightningSpawn
  knifeSpawn
  flameSpawn
  flameThrowerSpawn
  explosiveSpawn
  type ProjectileBase
} from ./projectile.civet

{
  PI
  abs
  atan2
  ceil
  cos
  max
  sin
} := Math

TAU := PI * 2

type ProjectileSpawn = (level: Level, source: Ball) => void

type ItemProps =
  name: string
  basePrice: number
  icon: string
  type?: ItemType
  ammo: number
  knockback: number
  rateOfFire?: number
  cooldown?: number
  maxRange?: number
  pellets?: number
  spread: number
  aimRate?: number
  projectile?: ProjectileSpawn
  hitsPerShot?: number
  startActive?: boolean

export class Item
  name: string
  icon: string
  basePrice: number
  type: ItemType = 'gun'
  active = false
  tint = 0xffff00
  /** The current ammo */
  ammo: number
  /** The base unmodified ammo */
  ammoBase: number
  /** The maximum ammo including skill / item modifications */
  ammoMax: number
  spread: number
  knockback: number
  maxRange = 2000
  pellets = 1
  cooldown = 0.125
  /** Number of peg hits per shot triggered */
  hitsPerShot = 1
  /** Shots per second for automatic weapons. 0 means never shoots automatically.*/
  rateOfFire = 0
  hits = 0
  /** rate of aim rotation in radians per second */
  aimRate = 0
  /** angle offset from ball rotation */
  aimHeading = 0
  /** cooldown time remaining before next shot */
  t = 0
  projectile: ProjectileSpawn?
  startActive = false

  debug =
    targetPeg: undefined as PegData?

  @(props: ItemProps)
    { @name, @icon, @ammo, @spread, @knockback, @basePrice } = props
    if props.cooldown
      @cooldown = props.cooldown
    if props.rateOfFire
      @rateOfFire = props.rateOfFire
    if props.maxRange
      @maxRange = props.maxRange
    if props.pellets
      @pellets = props.pellets
    if props.projectile
      @projectile = props.projectile
    if props.hitsPerShot
      @hitsPerShot = props.hitsPerShot
    if props.type
      @type = props.type
    if props.startActive
      @startActive = props.startActive
    if props.aimRate
      @aimRate = props.aimRate
    @ammoBase = @ammoMax = @ammo

  clone()
    new Item @

  get ammoText(): string
    `${@ammo}/${@ammoMax}`

  get statText(): string
    ```
      Shots: ${@ammoMax}
      Range: ${@maxRange}
    ```

  reset(ball: Ball): void
    @active = @startActive

    ammoMult := ball.ammoMult

    @ammo = @ammoMax = ceil @ammoBase * ammoMult
    @t = 0

  onBulletFire(level: Level, ball: Ball, item: Item): void

  onHit(level: Level, ball: Ball, peg: Vec2, collision: Vec2): void
    if @rateOfFire // automatic
      @active = true
    else // semi-automatic
      @hits++
      return unless @ammo
      return if @t

      if @hits >= @hitsPerShot
        @ammo--
        @t = @cooldown
        @hits -= @hitsPerShot

        i .= 0
        while i++ < @pellets
          if @projectile
            @projectile level, ball
          else
            fireBullet(level, ball, @)

  onUpdate(level: Level, ball: Ball, dt: number): void
    aimRate := @aimRate + ball.aimRate
    if aimRate
      // find targets closest to current heading
      currentAngle := ball.rotation + @aimHeading

      // basis vector
      bx := cos(currentAngle)
      by := sin(currentAngle)

      minAbsAngle .= Infinity
      targetAngle .= 0
      targetPeg .= undefined

      for each peg of level.pegs
        dx := peg.x - ball.x
        dy := peg.y - ball.y

        continue if dx * dx + dy * dy > @maxRange * @maxRange

        x := bx * dx + by * dy
        y := bx * dy - by * dx

        angle := atan2 y, x

        if abs(angle) < minAbsAngle
          minAbsAngle = abs angle
          targetAngle = angle
          targetPeg = peg

      @aimHeading += clamp targetAngle, -aimRate * dt, aimRate * dt
      @debug.targetPeg = targetPeg

    if @rateOfFire // automatic
      return unless @active
      return unless @ammo

      secondsPerShot := 1 / @rateOfFire

      @t += dt
      while @t >= secondsPerShot
        @t -= secondsPerShot
        @ammo--

        i .= 0
        while i++ < @pellets
          if @projectile
            @projectile level, ball
          else
            fireBullet(level, ball, @)
    else // semi-automatic
      if @cooldown
        @t = max @t - dt, 0

type ItemType = 'gun' | 'wizard' | 'active'

export items: Item[] := [
  new Item
    name: "P. Deringer"
    icon: "pistol_001.png"
    basePrice: 5
    ammo: 1
    spread: 0.1
    knockback: 1
    maxRange: 60
  new Item
    name: "S. Deringer"
    icon: "pistol_002.png"
    basePrice: 10
    ammo: 4
    spread: 0.05
    knockback: 1
    maxRange: 100
  new Item
    name: "Banana"
    icon: "banana.png"
    basePrice: 10
    ammo: 1
    spread: 0
    knockback: 0
    projectile: explosiveSpawn
  new Item
    name: "Double Barrel"
    icon: "shotgun_001.png"
    basePrice: 15
    ammo: 2
    spread: 0.2
    knockback: 2
    pellets: 6
    maxRange: 300
  new Item
    name: "Pump Action"
    icon: "shotgun_002.png"
    basePrice: 25
    ammo: 8
    spread: 0.1
    knockback: 2
    pellets: 6
    maxRange: 200
    cooldown: 0.785
  new Item
    name: "Six Shooter"
    icon: "pistol_003.png"
    basePrice: 15
    ammo: 6
    spread: 0.1
    knockback: 2
  new Item
    name: "B. Wand"
    icon: "liquid_drop_006.png"
    basePrice: 20
    ammo: 30
    spread: 0
    knockback: 0
    projectile: bubbleSpawn
  new Item
    name: "Lightning"
    icon: "energy_cell_003.png"
    type: 'wizard'
    basePrice: 30
    ammo: 30
    spread: 0
    knockback: 0
    startActive: true
    rateOfFire: 1.5
    projectile: lightningSpawn
  new Item
    name: "MP5K"
    icon: "rifle_005.png"
    basePrice: 20
    ammo: 20
    rateOfFire: 15 // shots per second
    spread: 0.01
    maxRange: 150
    knockback: 5
  new Item
    name: "UMP-45"
    icon: "rifle_004.png"
    basePrice: 30
    ammo: 25
    rateOfFire: 12.5 // shots per second
    spread: 0.01
    maxRange: 200
    knockback: 5
  new Item
    name: "M4A1"
    icon: "rifle_001.png"
    basePrice: 40
    ammo: 30
    rateOfFire: 10 // shots per second
    spread: 0.02
    knockback: 10
  new Item
    name: "AKM"
    icon: "rifle_003.png"
    basePrice: 40
    ammo: 30
    rateOfFire: 8 // shots per second
    spread: 0.05
    knockback: 20
  new Item
    name: "Tommy Gun"
    icon: "rifle_002.png"
    basePrice: 50
    ammo: 50
    rateOfFire: 15 // shots per second
    spread: 0.1
    knockback: 20
  new Item
    name: "Flamethrower"
    icon: "pepper_red.png"
    basePrice: 60
    ammo: 250
    rateOfFire: 30
    spread: 0.1
    knockback: 0
    projectile: flameThrowerSpawn
  new Item
    name: "Knife"
    icon: "knife_kitchen.png"
    basePrice: 20
    type: "active"
    ammo: 999
    rateOfFire: 1 // shots per second
    spread: 0
    knockback: 0
    startActive: true
    projectile: knifeSpawn
  new Item
    name: "Flame"
    icon: "candle.png"
    basePrice: 20
    type: "active"
    ammo: 999
    rateOfFire: 1 // shots per second
    spread: 0
    knockback: 0
    startActive: true
    projectile: flameSpawn
]

/**
Shot variance [0..1]. 0 is a straight shot, 1 is any angle in the full TAU radians.
*/
type Spread = number

export function calcSpread(spread: Spread): number
  phi := 1024 * spread
  spreadAngle := (rand(phi) - phi / 2) * PI / 512

  return spreadAngle

function fireBullet(level: Level, ball: Ball, item: Item)
  { aimHeading, spread, knockback, maxRange } := item

  level.playSoundAt "shot", ball
  angle := ball.rotation + aimHeading + calcSpread(spread)

  if knockback
    ball.velocity.x += -cos(angle) * knockback
    ball.velocity.y += -sin(angle) * knockback

  ball.onBulletFire level, item

  bulletCollisions ball, angle, level, maxRange

function bulletCollisions(source: Ball, angle: number, level: Level, maxRange: number)
  { effectContainer, pegs } := level

  collisions := []
  for peg of pegs
    if collision := rayCircleIntersection(source, angle, peg)
      if collision.t <= maxRange
        collisions.push collision

  collisions.sort (a, b) => a.t - b.t

  scale := { x: maxRange, y: 2 }

  hit .= false
  projectile: ProjectileBase := {
    source
    //@ts-expect-error TODO: hitPeg returns the same collision in the onHitPeg callback so paramaterize or something
    onHitPeg(level: Level, peg: PegData, collision: { t: number })
      // stop the bullet on the first solid hit
      switch peg.type
        when 'peg'
          scale.x = collision.t
          hit = true
  }

  for each collision of collisions
    { c: peg } := collision
    level.hitPeg peg, projectile, collision

    break if hit

  addStreak effectContainer, {
    rotation: angle
    scale
    tint: 0xffffff
    x: source.x
    y: source.y
  }
