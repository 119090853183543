{
  type Vec2
  bounce
  circularCollision
  physicsUpdate
  rayCircleIntersection
  rand
} from ./util.civet
{
  addBlast
  addStreak
  hollowCircle
  sheetToTextures
} from ./display.civet
type { Ball } from ./ball.civet
type { Level, PegData } from ./scenes/level.civet
{ Container, Graphics, Sprite, Texture } from pixi.js

{
  PI
  atan2
  cos
  floor
  max
  min
  sin
  sqrt
} := Math

//@ts-expect-error
itemData from ../data.sqlite#items

export interface Projectile<T extends Container = Container>
  x: number
  y: number
  radius: number
  active: boolean
  source: Ball

  update(level: Level, dt: number): void
  draw(container: T): void
  resolveCollision(level: Level, collision: Vec2): void
  handleBallCollision(ball: Ball): void
  trigger(level: Level, peg: PegData, collision: Vec2): void

  get container(): T

class BubbleProjectile implements Projectile<Graphics>
  x: number
  y: number
  radius: number
  velocity = { x: 0, y: -20 }
  ageMax = 10
  active = true
  ephemeral = true
  dc = (rand 11) / 10
  age = 0
  warmup = 0.5
  source: Ball

  constructor(@source: Ball, @radius: number)
    { @x, @y } = @source
    @velocity.x = (rand(2) - 0.5) * 10

  update(level: Level, dt: number): void
    @age += dt
    @dc -= dt

    if @age > @ageMax
      @active = false
      return

    if @dc <= 0
      @dc = (rand 11) / 10
      @velocity.x = (rand(2) - 0.5) * 10

    @x += @velocity.x * dt
    @y += @velocity.y * dt

    if @active and @age >= @warmup
      level.handlePegCollisions(@)

  draw(graphics: Graphics)
    graphics.x = @x
    graphics.y = @y
    graphics.scale = min 1, @age / @warmup

  get container()
    hollowCircle()

  resolveCollision(level: Level, collision: Vec2): void

  handleBallCollision(ball: Ball): void
    return if @age < @warmup

    // Bounce ball up on bubbles
    if collision := circularCollision(@x, @y, @radius, ball.x, ball.y, ball.radius)
      if ball.velocity.y > 0 and ball.y < @y
        ball.velocity.y = -ball.velocity.y - 10
        @active = false

  trigger(level: Level, peg: PegData, collision: Vec2): void
    switch peg.type
      when 'peg'
        @active = false

class KnifeProjectile implements Projectile<Sprite>
  x: number
  y: number
  radius = 8
  velocity = { x: 0, y: 0 }
  active = true
  ephemeral = true
  source: Ball
  speed = 800

  constructor(@source: Ball)
    { @x, @y } = @source
    { x: vx, y: vy } := @source.velocity
    m := sqrt vx * vx + vy * vy

    @velocity.x = @speed * vx / m
    @velocity.y = @speed * vy / m

  update(level: Level, dt: number): void
    @x += @velocity.x * dt
    @y += @velocity.y * dt

    if @active
      level.handlePegCollisions(@)

  draw(sprite: Sprite)
    // Account for sprite's rotation
    sprite.rotation = atan2(@velocity.y, @velocity.x) + 3 * PI / 4
    sprite.x = @x
    sprite.y = @y

  get container()
    new Sprite
      anchor: 0.5
      texture: Texture.from("knife_kitchen.png")
      scale: 2

  resolveCollision(level: Level, collision: Vec2): void

  handleBallCollision(ball: Ball): void

  trigger(level: Level, peg: PegData, collision: Vec2): void
    switch peg.type
      when 'peg'
        @active = false


class LightningProjectile implements Projectile
  x: number
  y = -20
  radius = 1000
  active = true
  source: Ball

  constructor(@source: Ball, @x: number)

  update(level: Level, dt: number): void
    closestCollision .= null
    hitPeg .= null

    for peg of level.pegs
      if peg.type is 'ghost'
        continue

      if collision := circularCollision(@x, @y, @radius, peg.x, peg.y, peg.r)
        if not closestCollision or collision.t < closestCollision.t
          closestCollision = collision
          hitPeg = peg

    if closestCollision
      level.hitSolid hitPeg!, @source
      @active = false

      addStreak level.effectContainer, {
        rotation: atan2(closestCollision.y, closestCollision.x) + PI
        scale: {x: closestCollision.t, y: 2}
        tint: 0xffff00
        x: @x
        y: @y
      }

  draw(container: Container)

  get container()
    new Container

  resolveCollision(level: Level, collision: Vec2): void

  handleBallCollision(ball: Ball): void
    return

  trigger(level: Level, peg: PegData, collision: Vec2): void
    switch peg.type
      when 'peg'
        @active = false

class FlameProjectile implements Projectile
  x: number
  y: number
  radius = 50
  active = true
  source: Ball
  age = 0
  textures: Texture[]

  constructor(@source: Ball, @textures: Texture[])
    { @x, @y } = @source

  update(level: Level, dt: number): void
    { @x, @y } = @source

    // Ray coming from center of flame
    for each peg of level.pegs
      if collision := rayCircleIntersection(@source, @source.rotation, peg)
        if collision.t < 100
          level.hitSolid peg, @source

    @active = @source.active
    @age += dt

  draw(sprite: Sprite): void
    { rotation, radius: sourceRadius } := @source
    sprite.x = @x + cos(rotation) * sourceRadius
    sprite.y = @y + sin(rotation) * sourceRadius
    sprite.rotation = @source.rotation

    l := @textures.length
    i := floor @age * 24
    sprite.texture = @textures[i % l]

  get container()
    new Sprite
      width: 128
      height: 58
      anchor: {x: 0, y: 0.5}

  resolveCollision(level: Level, collision: Vec2): void

  handleBallCollision(ball: Ball): void
    return

  trigger(level: Level, peg: PegData, collision: Vec2): void

type AutomaticFirearmProps =
  name: string
  basePrice: number
  icon: string
  type?: ItemType
  ammo: number
  knockback: number
  maxRange?: number
  pellets?: number
  rateOfFire: number
  spread: number
  projectile?: ProjectileSpawn
  active?: boolean

type ProjectileSpawn = (level: Level, source: Ball) => void

type ItemProps =
  name: string
  basePrice: number
  icon: string
  type?: ItemType
  ammo: number
  knockback: number
  rateOfFire?: number
  cooldown?: number
  maxRange?: number
  pellets?: number
  spread: number
  projectile?: ProjectileSpawn
  hitsPerShot?: number
  startActive?: boolean

export class Item
  name: string
  icon: string
  basePrice: number
  type: ItemType = 'gun'
  active = false
  tint = 0xffff00
  ammo: number
  ammoMax: number
  spread: number
  knockback: number
  maxRange = 2000
  pellets = 1
  cooldown = 0.125
  /** Number of peg hits per shot triggered */
  hitsPerShot = 1
  /** Shots per second for automatic weapons. 0 means never shoots automatically.*/
  rateOfFire = 0
  hits = 0
  /** cooldown time remaining before next shot */
  t = 0
  projectile: ProjectileSpawn?
  startActive = false

  @(props: ItemProps)
    {@name, @icon, @ammo, @spread, @knockback, @basePrice} = props
    if props.cooldown
      @cooldown = props.cooldown
    if props.rateOfFire
      @rateOfFire = props.rateOfFire
    if props.maxRange
      @maxRange = props.maxRange
    if props.pellets
      @pellets = props.pellets
    if props.projectile
      @projectile = props.projectile
    if props.hitsPerShot
      @hitsPerShot = props.hitsPerShot
    if props.type
      @type = props.type
    if props.startActive
      @startActive = props.startActive
    @ammoMax = @ammo

  clone()
    new Item
      name: @name
      basePrice: @basePrice
      icon: @icon
      type: @type
      ammo: @ammoMax
      spread: @spread
      knockback: @knockback
      maxRange: @maxRange
      pellets: @pellets
      cooldown: @cooldown
      rateOfFire: @rateOfFire
      hitsPerShot: @hitsPerShot
      projectile: @projectile
      startActive: @startActive

  get ammoText(): string
    `${@ammo}/${@ammoMax}`

  get statText(): string
    ```
      Shots: ${@ammoMax}
      Range: ${@maxRange}
    ```

  reset(): void
    @active = @startActive
    @ammo = @ammoMax
    @t = 0

  onHit(level: Level, ball: Ball, peg: Vec2, collision: Vec2): void
    if @rateOfFire // automatic
      @active = true
    else // semi-automatic
      @hits++
      return unless @ammo
      return if @t

      if @hits >= @hitsPerShot
        @ammo--
        @t = @cooldown
        @hits -= @hitsPerShot

        i .= 0
        while i++ < @pellets
          if @projectile
            @projectile level, ball
          else
            fireBullet(level, ball, @spread, @knockback, @maxRange)

  onUpdate(level: Level, ball: Ball, dt: number): void
    if @rateOfFire // automatic
      return unless @active
      return unless @ammo

      secondsPerShot := 1 / @rateOfFire

      @t += dt
      while @t >= secondsPerShot
        @t -= secondsPerShot
        @ammo--

        i .= 0
        while i++ < @pellets
          if @projectile
            @projectile level, ball
          else
            fireBullet(level, ball, @spread, @knockback, @maxRange)
    else // semi-automatic
      if @cooldown
        @t = max @t - dt, 0

interface ExplosiveProps
  name: string
  fuse:
    seconds: number
    hits: number
  radius: number
  icon: string

/**
An item that explodes after a certain amount of time or hits.
*/
class ExplosiveProjectile implements Projectile
  x: number
  y: number
  rotation = 0
  radius = 8

  /** Throw spread angle */
  spread = 0.125
  blastRadius: number
  velocity = { x: 0, y: 0 }
  gravity = { x: 0, y: 360 }
  rotationVelocity = 0
  fuse = 3
  active = true
  ephemeral = true
  age = 0
  source: Ball
  texture = "banana.png"

  constructor(@source: Ball, @blastRadius: number)
    { @x, @y } = @source
    { rotation } .= @source

    rotation += -PI + calcSpread(@spread)

    launchVelocity := 100

    @velocity.x = cos(rotation) * launchVelocity + @source.velocity.x
    @velocity.y = sin(rotation) * launchVelocity + @source.velocity.y

  update(level: Level, dt: number): void
    @age += dt

    physicsUpdate(@, dt)

    if @age > @fuse
      @explode(level)

    level.handlePegCollisions(@)

  explode(level: Level): void
    explosion(level, @source, @, @blastRadius)
    @active = false

  draw(sprite: Sprite): void
    sprite.x = @x
    sprite.y = @y
    sprite.rotation = @rotation

  get container(): Container
    new Sprite Texture.from @texture

  resolveCollision(level: Level, collision: Vec2): void
    pos := { x: @x + collision.x, y: @y + collision.y }
    level.playSoundAt "boing", pos, 3

    bounce(@, collision)

  handleBallCollision(ball: Ball): void

  trigger(level: Level, peg: PegData, collision: Vec2): void

type ItemType = 'gun' | 'wizard' | 'active'

export interface Item
  name: string
  icon: string
  type: ItemType
  active: boolean
  basePrice: number
  /** Color for bg/label */
  tint: number
  statText: string
  ammoText: string
  reset(): void
  onHit(level: Level, ball: Ball, peg: Vec2, collision: Vec2): void
  onUpdate(level: Level, ball: Ball, dt: number): void
  clone(): Item

export items: Item[] := [
  new Item
    name: "P. Deringer"
    icon: "pistol_001.png"
    basePrice: 5
    ammo: 1
    spread: 0.1
    knockback: 1
    maxRange: 60
  new Item
    name: "S. Deringer"
    icon: "pistol_002.png"
    basePrice: 10
    ammo: 4
    spread: 0.05
    knockback: 1
    maxRange: 100
  new Item
    name: "Banana"
    icon: "banana.png"
    basePrice: 10
    ammo: 1
    spread: 0
    knockback: 0
    projectile: explosiveSpawn
  new Item
    name: "Double Barrel"
    icon: "shotgun_001.png"
    basePrice: 15
    ammo: 2
    spread: 0.2
    knockback: 2
    pellets: 6
    maxRange: 300
  new Item
    name: "Pump Action"
    icon: "shotgun_002.png"
    basePrice: 25
    ammo: 8
    spread: 0.1
    knockback: 2
    pellets: 6
    maxRange: 200
    cooldown: 0.785
  new Item
    name: "Six Shooter"
    icon: "pistol_003.png"
    basePrice: 15
    ammo: 6
    spread: 0.1
    knockback: 2
  new Item
    name: "B. Wand"
    icon: "liquid_drop_006.png"
    basePrice: 20
    ammo: 30
    spread: 0
    knockback: 0
    projectile: bubbleSpawn
  new Item
    name: "Lightning"
    icon: "energy_cell_003.png"
    type: 'wizard'
    basePrice: 30
    ammo: 30
    spread: 0
    knockback: 0
    startActive: true
    rateOfFire: 1.5
    projectile: lightningSpawn
  new Item
    name: "M4A1"
    icon: "rifle_001.png"
    basePrice: 40
    ammo: 30
    rateOfFire: 10 // shots per second
    spread: 0.01
    knockback: 10
  new Item
    name: "Tommy Gun"
    icon: "rifle_002.png"
    basePrice: 50
    ammo: 50
    rateOfFire: 15 // shots per second
    spread: 0.1
    knockback: 20
  new Item
    name: "Flamethrower"
    icon: "pepper_red.png"
    basePrice: 60
    ammo: 1
    spread: 0.1
    knockback: 0
    projectile: flameSpawn
  new Item
    name: "Knife"
    icon: "knife_kitchen.png"
    basePrice: 20
    type: "active"
    ammo: 999
    rateOfFire: 1 // shots per second
    spread: 0
    knockback: 0
    startActive: true
    projectile: knifeSpawn
]

function knifeSpawn(level: Level, source: Ball): void
  level.addProjectile new KnifeProjectile source

function bubbleSpawn(level: Level, source: Ball): void
  level.addProjectile new BubbleProjectile source, 10

function lightningSpawn(level: Level, source: Ball): void
  level.addProjectile new LightningProjectile source, rand(level.game.width)

let flamethrowerTextures: Texture[]?
let flamethrowerLoopTextures: Texture[]?
function flameSpawn(level: Level, source: Ball): void
  flamethrowerTextures ?= sheetToTextures(Texture.from("assets/FX_Fire00_FlameThrower01_20x1.png"), 90, 58)
  flamethrowerLoopTextures ?= sheetToTextures(Texture.from("assets/FX_Fire00_FlameThrower02_Loop_6x6.png"), 128, 58)
  level.addProjectile new FlameProjectile source, flamethrowerLoopTextures

function explosiveSpawn(level: Level, source: Ball): void
  level.addProjectile new ExplosiveProjectile source, 50

/**
Shot variance [0..1]. 0 is a straight shot, 1 is any angle in the full TAU radians.
*/
type Spread = number

function calcSpread(spread: Spread): number
  phi := 1024 * spread
  spreadAngle := (rand(phi) - phi / 2) * PI / 512

  return spreadAngle

function fireBullet(level: Level, ball: Ball, spread: Spread, knockback: number, maxRange: number)
  level.playSoundAt "shot", ball
  angle := ball.rotation + calcSpread(spread)

  if knockback
    ball.velocity.x += -cos(angle) * knockback
    ball.velocity.y += -sin(angle) * knockback

  bulletCollisions ball, angle, level, maxRange

function bulletCollisions(source: Ball, angle: number, level: Level, maxRange: number)
  { effectContainer, pegs, triggerPeg, hitSolid, toRemove } := level

  collisions := []
  for peg of pegs
    if collision := rayCircleIntersection(source, angle, peg)
      if collision.t <= maxRange
        collisions.push collision

  collisions.sort (a, b) => a.t - b.t

  scale := {x: maxRange, y: 2}

  :l for {t, c: peg} of collisions
    switch peg.type
      when 'ghost'
        continue if toRemove.has peg
        toRemove.add peg
        triggerPeg peg, source
      when 'peg'
        scale.x = t
        hitSolid peg, source
        break l

  addStreak effectContainer, {
    rotation: angle
    scale
    tint: 0xffffff
    x: source.x
    y: source.y
  }

function explosion(level: Level, source: Ball, pos: Vec2, radius: number)
  { effectContainer, pegs, triggerPeg, toRemove } := level

  for peg of pegs
    if collision := circularCollision(pos.x, pos.y, radius, peg.x, peg.y, peg.r)
      continue if toRemove.has peg
      triggerPeg peg, source

  addBlast effectContainer, pos.x, pos.y, radius, 0xff0000
