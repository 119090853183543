type { Level, PegData } from ./scenes/level.civet
type { Ball } from ./ball.civet

{
  max
} := Math

export type Skill = {
  name: string
  description: string
  onPegActivate(level: Level, ball: Ball, peg: PegData): void
}

export skills := [{
  name: "Charged Up"
  description: "Reduce electric cooldowns by 0.25s per activation"
  onPegActivate(level: Level, ball: Ball, peg: PegData): void
    for each item of ball.items
      if item.name is "Lightning"
        item.t += 0.25
}, {
  name: "Bookworm"
  description: "+1 XP per activation"
  onPegActivate(level: Level, ball: Ball, peg: PegData): void
    ball.xp += 1
}, {
  name: "Cashback"
  description: "Gain 1 coin per activation"
  onPegActivate(level: Level, ball: Ball, peg: PegData): void
    level.money += 1
}]
