{
  Application
  Assets
} from pixi.js

{
  StarEffect
} from ../fx/star.civet

canvas := document.createElement 'canvas'

app := new Application
app.init
  width: 640
  height: 360
  resolution: 2
  view: canvas
  backgroundColor: 0x000000
.then =>

  effect := StarEffect 320, 180, 11, 7
  { container, update } := effect

  container.tint = 0x00ff00
  container.x = 160
  container.y = 90

  app.stage.addChild container

  app.ticker.add =>
    update(1/60)

  document.body.appendChild app.canvas

c2 := document.createElement 'canvas'
document.body.appendChild c2
c2.style.width = c2.style.height = '256px'

c2.width = c2.height = 64
ctx := c2.getContext('2d')!
ctx.imageSmoothingEnabled = false

ctx.fillStyle = 'rgba(255, 0, 255, 1)'
ctx.fillRect 0, 0, 64, 64

g := ctx.createRadialGradient 32, 32, 32, 32, 32, 0
ctx.fillStyle = g
g.addColorStop 0, 'transparent'
g.addColorStop 0, 'rgba(0, 0, 0, 1)'
g.addColorStop 1/32, 'rgba(0, 0, 0, 1)'
g.addColorStop 1/32, 'rgba(255, 255, 255, 1)'
g.addColorStop 3/32, 'rgba(255, 255, 255, 1)'
g.addColorStop 3/32, 'rgba(0, 0, 0, 1)'
g.addColorStop 4/32, 'rgba(0, 0, 0, 1)'
g.addColorStop 4/32, 'rgba(255, 255, 255, 1)'
g.addColorStop 1, 'rgba(255, 255, 255, 1)'
ctx.arc 32, 32, 32, 0, Math.PI * 2
ctx.fill()

ctx.fillStyle = 'rgba(255, 0, 255, 1)'
ctx.fillRect 0, 0, 64, 64

ctx.beginPath()
g3 := ctx.createRadialGradient 16, 16, 0, 16, 16, 16
ctx.fillStyle = g3
([
  [0, 'blue'],
  [12/16, 'blue']
  [12/16, 'black']
  [13/16, 'black']
  [13/16, 'white']
  [15/16, 'white']
  [15/16, 'black']
  [1, 'black']
  [1, 'transparent'] // helps prevent extra blurring at the edge
] as const).forEach ([pos, color]) =>
  g3.addColorStop pos, color

ctx.arc 16, 16, 16, 0, Math.PI * 2
ctx.fill()

ctx.beginPath()
g4 := ctx.createRadialGradient 12, 6, 0, 16, 16, 12
ctx.fillStyle = g4
// specular highlight
([
  [0, 'rgba(255, 255, 255, 1)'],
  [0.25, 'rgba(255, 255, 255, 0.5)']
  [0.25, 'transparent']
  [0.35, 'rgba(0, 0, 0, 0.75)']
  [0.5, 'rgba(0, 0, 0, 0.75)']
  [0.75, 'transparent']
  [1, 'transparent']
] as const).forEach ([pos, color]) =>
  g4.addColorStop pos, color

ctx.arc 16, 16, 16, 0, Math.PI * 2
ctx.fill()
