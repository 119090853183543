{
  BitmapText
  Container
  Graphics
  Rectangle
  Sprite
  Texture
  type FederatedPointerEvent
  type TextOptions
} from pixi.js

type { Game } from ../game.civet
type { Scene } from ./scene.civet

{ Button, ShadowedText } from ../ui.civet
{ clamp } from ../util.civet

{ Observable } from jadelet
{ type ObservableValue } from @danielx/observable

export settings :=
  masterVolume: 0.5
  musicVolume: 1
  sfxVolume: 1

try
  if savedSettings := localStorage.getItem 'stay-pegged:settings'
    Object.assign settings, JSON.parse savedSettings

export masterVolume := Observable(settings.masterVolume)
masterVolume.observe (value) =>
  settings.masterVolume = value
  localStorage.setItem 'stay-pegged:settings', JSON.stringify settings

export musicVolume := Observable(settings.musicVolume)
musicVolume.observe (value) =>
  settings.musicVolume = value
  localStorage.setItem 'stay-pegged:settings', JSON.stringify settings

export sfxVolume := Observable(settings.sfxVolume)
sfxVolume.observe (value) =>
  settings.sfxVolume = value
  localStorage.setItem 'stay-pegged:settings', JSON.stringify settings

export class Settings implements Scene
  stage := new Container
  game: Game

  update(_dt: number): void
  draw(_t: number): void

  enter(): void

  pointerMove(_pos: unknown): void
  action1(): void

  @(@game: Game)
    bgSprite := new Sprite
      texture: Texture.from "assets/title.png"
      x: -620
      y: -540
    @stage.addChild bgSprite

    settingsText := ShadowedText {
      text: "Settings"
      style:
        fontFamily: "m5x7"
        fontSize: 60
    }, x: 4, y: 4
    settingsText.x = 120
    settingsText.y = 40
    @stage.addChild settingsText

    menuOptions := [{
      text: "Back"
      onSelect: =>
        @game.popScene()
    }]

    l := menuOptions.length

    for each {text, onSelect}, i of menuOptions
      button := Button {
        text: text
        x: 1280 - 220
        y: 720 - 60 * (l - i)
        width: 200
        height: 40
        fontSize: 30
        onSelect
      }

      @stage.addChild button

    masterVolumeControl := SliderControl {
      title: "Master Volume"
      value: masterVolume
      width: 200
    }
    masterVolumeControl.x = 40
    masterVolumeControl.y = 120
    @stage.addChild masterVolumeControl

    musicVolumeControl := SliderControl {
      title: "Music Volume"
      value: musicVolume
      width: 200
    }
    musicVolumeControl.x = 40
    musicVolumeControl.y = 180
    @stage.addChild musicVolumeControl

    sfxVolumeControl := SliderControl {
      title: "SFX Volume"
      value: sfxVolume
      width: 200
    }
    sfxVolumeControl.x = 40
    sfxVolumeControl.y = 240
    @stage.addChild sfxVolumeControl

type SliderControlProps
  title: string
  value: ObservableValue<number>
  width: number

function SliderControl({
  title
  value
  width
}: SliderControlProps): Container
  container := new Container

  titleText := new BitmapText
    text: title
    style:
      fontFamily: "m5x7"
      fontSize: 30
  container.addChild titleText

  slider := Slider {
    value
    width
  }
  slider.y = 40
  container.addChild slider

  return container

type SliderProps
  value: ObservableValue<number>
  width: number

function Slider({
  value
  width
}: SliderProps): Container
  height := 20
  container := new Container

  controlContainer := new Container
  slider := new Graphics()
    .rect 0, 0, width, height
    .fill 0x000000
    .stroke 0xffffff
  controlContainer.addChild slider

  fill := new Graphics()
    .rect 0, 0, 1, height
    .fill 0xffffff
  controlContainer.addChild fill
  fill.width = width * value()

  handle := new Graphics()
    .circle 0, 0, height / 2
    .fill 0xffffff
  controlContainer.addChild handle
  handle.y = height / 2
  handle.x = width * value()

  mask := new Graphics()
    .rect 0, 0, width, height + 2
    .fill 0xffffff
  controlContainer.addChild mask
  controlContainer.mask = mask

  container.addChild controlContainer

  // hit area on outer container to prevent being blocked by mask
  container.hitArea = new Rectangle(-20, -20, width + 40, height + 40)
  container.interactive = true
  container.interactiveChildren = false

  active .= false

  update := (e: FederatedPointerEvent) =>
    e.preventDefault()
    x := e.global.x - container.toGlobal(container).x
    v := x / width
    value clamp v, 0, 1

    handle.x = width * value()
    fill.width = width * value()

  container.on "pointerdown", (e) =>
    active = true
    update(e)

  container.on "pointermove", (e) =>
    if active
      update(e)

  container.on "globalpointermove", (e) =>
    if active
      update(e)

  container.on "pointerup", =>
    active = false

  container.on "pointerupoutside", =>
    active = false

  return container
