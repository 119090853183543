{
  BitmapText
  Container
  Graphics
  Sprite
  Texture
} from pixi.js

type {
  Ball
} from './ball.civet'

export BallSelector := =>
  container := new Container
  slotCount := 3
  slotSprites: Sprite[] := []
  nameTexts: BitmapText[] := []
  xpBars: Sprite[] := []
  size := 32

  i .= 0
  while i < slotCount
    slotContainer := new Container
    bg := new Graphics()
      .rect 0, 0, size, size
      .fill 0x000000
      .stroke width: 2, color: 0xffffff

    slotContainer.addChild bg

    sprite := new Sprite
      x: size / 2
      y: size / 2
    sprite.anchor = 0.5
    slotSprites.push sprite
    slotContainer.addChild sprite

    x := i * ( size + 8 ) + 8
    slotContainer.x = x
    slotContainer.y = 8

    nameText := new BitmapText
      text: "ball"
      style:
        fontFamily: "m5x7"
        fontSize: 15
      x: 0
      y: size
    nameTexts.push nameText
    slotContainer.addChild nameText

    xpBar := new Sprite Texture.WHITE
    xpBar.width = 2
    xpBar.height = 0
    xpBar.tint = 0x80aa80
    xpBar.x = size - 4
    xpBars.push xpBar
    slotContainer.addChild xpBar

    container.addChild slotContainer
    i++

  {
    container
    updateTextures: (balls: Ball[]) =>
      slotSprites.forEach (slotSprite, i) =>
        if ball := balls[i]
          slotSprite.texture = ball.texture
          nameTexts[i].text = ball.name
          xpBar := xpBars[i]
          xpBar.height = ball.xpProgress * ( size - 4 )
          xpBar.y = size - 2 - xpBar.height
        else
          slotSprite.texture = Texture.EMPTY
          nameTexts[i].text = ""
          xpBars[i].height = 0
  }
