{
  Container
  Graphics
  BitmapText
  Rectangle
  Sprite
  Texture
} from pixi.js

type { Ball, Game, GameState } from ../game.civet

{ Header } from ./scene.civet
{ Button } from ../ui.civet

{
  type Item
  items
} from ../item.civet

{
  shuffle
  rand
  type Vec2
} from ../util.civet

{
  Observable
  type ObservableValue
} from @danielx/observable

{
  floor
  max
} := Math

type Model =
  x: ObservableValue<number>
  y: ObservableValue<number>
  saleItem: SaleItem

type SaleItem
  item: Item
  cost: number

export class Shop
  stage := new Container
  game: Game
  //@ts-expect-error set in enter
  state: GameState
  interactive = false
  header: Header

  inventoriesContainer := new Container()
  saleItems: SaleItem[]

  activeModel: Model?

  activeDrag: undefined |
    startPos: Vec2?
    initialX: number
    initialY: number
    model: Model

  update(_dt: number): void
    @header.state = @state
  draw(t: number): void
    @header.draw(t)

  enter(@state: GameState): void
    @renderShopItems()

    @game.playMusic 'bossa'
    @game.playSound 'shop_bells'

    @interactive = true

    @stage.addChild @inventoriesContainer
    @renderInventory()

  renderInventory()
    @inventoriesContainer.removeChildren()

    @state.balls.forEach (ball, i) =>
      ballInventory := makeBallInventory ball
      ballInventory.x = 20
      ballInventory.y = 300 + i * 100

      @inventoriesContainer.addChild ballInventory

  exit(): GameState
    @state

  pointerDown(e: PointerEvent, pos: Vec2): void
    if model := @activeModel
      x := model.x()
      y := model.y()
      @activeDrag = {
        startPos: pos
        initialX: x
        initialY: y
        model
      }

  pointerMove(e: PointerEvent, pos: Vec2): void
    if @activeDrag && @activeDrag.startPos
      dx := pos.x - @activeDrag.startPos.x
      dy := pos.y - @activeDrag.startPos.y

      @activeDrag.model.x floor @activeDrag.initialX + dx
      @activeDrag.model.y floor @activeDrag.initialY + dy

  pointerUp(e: PointerEvent, pos: Vec2): void
    if @activeDrag
      // Find target ball
      if ball := @ballAtPos(pos)
        saleItem := @activeDrag.model.saleItem
        { item, cost } := saleItem

        if @state.money >= cost
          // Remove bought item
          @saleItems = @saleItems.filter (i) => i.item !== saleItem.item
          @state.money -= cost
          ball.items.push item.clone()

      @renderShopItems()
      @renderInventory()

    @activeDrag = undefined

  action1(): void

  ballAtPos(pos: Vec2): Ball?
    @state.balls[floor (pos.y - 300) / 100]

  @(@game: Game)
    bg := new Graphics()
      .rect 0, 0, 1280, 720
      .fill 0xaeaeae
    @stage.addChild bg

    @header := new Header @game.state, "Shop"
    @stage.addChild @header.container

    leaveButton := Button
      text: "Leave"
      y: 720 - 44 - 20
      x: 640
      width: 200
      height: 40
      fontSize: 30
      onSelect: =>
        @game.popScene()

    leaveButton.x = (1280 - leaveButton.width) / 2
    @stage.addChild leaveButton

    @saleItems = shuffle(items.slice()).slice(0, 4).map (item) =>
      cost := item.basePrice + rand(6) + rand(6) + rand(6)
      { item, cost }

    @stage.addChild @saleItemsContainer

  saleItemsContainer := new Container

  renderShopItems()
    itemSpacing := 120

    @saleItemsContainer.removeChildren()

    @saleItems.forEach (saleItem, i) =>
      { item, cost } := saleItem

      priceMult := @state.balls.map(.priceMult).reduce((*), 1)
      adjustedCost := max 3, floor cost * priceMult

      itemContainer := new Container

      itemGfx := new Graphics()
        .rect 0, 0, 44, 44
        .fill 0x00ff00
      itemGfx.x = 20 + i * itemSpacing
      itemGfx.y = 60
      itemContainer.addChild itemGfx

      itemText := new BitmapText
        text: item.name,
        style:
          fontFamily: 'm5x7',
          fontSize: 15
          fill: 0x000000

      itemText.x = 20 + i * itemSpacing
      itemText.y = 110

      itemContainer.addChild itemText

      icon := new Sprite Texture.from item.icon
      icon.anchor.set(0.5)
      icon.scale.set(2)

      icon.x = 20 + i * itemSpacing + 22
      icon.y = 60 + 22

      itemContainer.addChild icon

      costText := new BitmapText
        text: '$' + cost,
        style:
          fontFamily: 'm5x7',
          fontSize: 15
          fill: 0x000000

      costText.x = 20 + i * itemSpacing
      costText.y = 130

      itemContainer.addChild costText

      if adjustedCost !== cost
        crossOut := new Graphics()
          .moveTo 20 + i * itemSpacing, costText.y + 5
          .lineTo 20 + i * itemSpacing + costText.width, costText.y + costText.height
          .stroke color: 0xff0000, width: 2

        itemContainer.addChild crossOut

        costText2 := new BitmapText
          text: '$' + adjustedCost,
          style:
            fontFamily: 'm5x7',
            fontSize: 15
            fill: 0x000000

        costText2.x = 20 + i * itemSpacing
        costText2.y = 150

        itemContainer.addChild costText2

      itemContainer.interactive = true
      // itemContainer.hitArea = new Rectangle(0, 0, 44, 44)

      itemContainer.on 'pointerdown', (e) =>
        return if e.defaultPrevented

        x := Observable itemContainer.x
        y := Observable itemContainer.y

        x.observe (x) => itemContainer.x = x
        y.observe (y) => itemContainer.y = y

        @activeModel = {
          x
          y
          saleItem: {
            item
            cost: adjustedCost
          }
        }

      @saleItemsContainer.addChild itemContainer

function makeBallInventory(ball: Ball)
  inventoryContainer := new Container

  ball.items.forEach (item, i) =>
    itemGfx := new Graphics()
      .rect 0, 0, 44, 44
      .fill 0x00ff00
    itemGfx.x = 50 + i * 50
    itemGfx.y = 0
    inventoryContainer.addChild itemGfx

    icon := new Sprite Texture.from item.icon
    icon.anchor.set(0.5)
    icon.scale.set(2)

    icon.x = 50 + i * 50 + 22
    icon.y = 0 + 22

    inventoryContainer.addChild icon

  // Add ball character and name
  ballSprite := new Sprite
    texture: ball.texture,
    anchor: 0.5
    scale: 2

  ballSprite.x = 25
  ballSprite.y = 22
  inventoryContainer.addChild ballSprite

  return inventoryContainer
