type { Level, PegData } from ./scenes/level.civet
type { Ball } from ./ball.civet
type { Item } from ./item.civet

{
  Texture
} from pixi.js

{
  lightningSpawn
} from ./projectile.civet

{
  noop
} from ./util.civet

{
  max
} := Math

type SkillProps = Partial<Skill> & {
  name: string
  description: string
}

export class Skill
  name: string
  description: string
  tint = 0x808000
  icon = "alembic.png"

  reset: (ball: Ball) => void = noop
  onUpdate: (level: Level, ball: Ball, dt: number) => void = noop
  onBulletFire: (level: Level, ball: Ball, item: Item) => void = noop
  onPegActivate: (level: Level, ball: Ball, peg: PegData) => void = noop
  ammoMultMod = 1
  priceMultMod = 1
  wallJumpAmount = 0
  aimRate = 0

  @(props: SkillProps)
    let restProps
    { @name, @description, ...restProps } = props

    Object.assign @, restProps

export skills := [{
  name: "Charged Up"
  description: "Reduce electric cooldowns by 0.25s per activation"
  icon: "battery_small.png"
  onPegActivate(level: Level, ball: Ball, peg: PegData): void
    for each item of ball.items
      if item.name is "Lightning"
        item.t += 0.25
}, {
  name: "Bookworm"
  description: "+1 XP per activation"
  icon: "scroll.png"
  onPegActivate(level: Level, ball: Ball, peg: PegData): void
    ball.xp += 1
}, {
  name: "Cashback"
  description: "Gain 1 coin per activation"
  icon: "credit_card_001.png"
  onPegActivate(level: Level, ball: Ball, peg: PegData): void
    level.gainMoney 1, ball
}, {
  name: "Extended Mag"
  description: "Increase ammo capacity"
  icon: "bullet_023.png"
  ammoMultMod: 4/3
}, {
  name: "Coupon Code"
  description: "Reduce shop prices by 10%"
  icon: "credit_card_002.png"
  priceMultMod: 0.9
}, {
  name: "Wall Jump"
  description: "Jump up when hitting a wall (x3)"
  icon: "boots_001.png"
  wallJumpAmount: 3
}, {
  name: "Blink"
  description: "Teleport when going off the bottom"
  icon: "boots_002.png"
  charges: 1
  reset()
    @charges = 1
  onUpdate(level: Level, ball: Ball)
    if @charges and ball.y > level.height
      level.playSoundAt "boop", ball
      ball.y -= 600
      @charges -= 1
}, {
  name: "Burst Fire"
  description: "Convert auto to three-round burst"
  icon: "bullet_004.png"
  count: 0
  reset(): void
    @count = 0
  onBulletFire(level: Level, ball: Ball, item: Item): void
    @count += 1
    if @count >= 3
      @count -= 3
      if item.rateOfFire and item.active
        item.active = false
}, {
  name: "Fire at will"
  description: "Activate gun weapons without hitting pegs"
  icon: "bullet_005.png"
  t: 0
  onUpdate(level: Level, ball: Ball, dt: number): void
    @t += dt
    cooldown := 1
    if @t >= cooldown
      @t -= cooldown
      for each item of ball.items
        if item.type is "gun" and item.ammo > 0
          item.onHit level, ball, ball, { x: 0, y: 0 }
          break
}, {
  name: "Aimbot"
  description: "Automatically aim at pegs"
  icon: "crosshair.png"
  aimRate: 20
}, {
  name: "Blitzkrieg"
  description: "Lightning strike every third bullet fired"
  icon: "energy_cell_003.png"
  count: 0
  onBulletFire(level: Level, ball: Ball): void
    @count += 1
    if @count >= 3
      @count -= 3
      lightningSpawn level, ball
}].map new Skill .
