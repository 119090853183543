{ Container } from pixi.js
type { GameState } from ../game.civet
type { Vec2 } from ../util.civet

export interface Scene
  stage: Container

  update(dt: number): void
  draw(t: number): void

  enter(gameState: GameState): void
  exit?(): GameState

  pointerDown?(e: PointerEvent, pos: Vec2): void
  pointerMove?(e: PointerEvent, pos: Vec2): void
  pointerUp?(e: PointerEvent, pos: Vec2): void
  action1(): void
