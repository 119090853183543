{
  Container
  Graphics
  GraphicsContext
} from pixi.js

{ rand } from ../util.civet

{ abs } := Math

halftoneContext := new GraphicsContext
r := 24
y .= 0
while y < r
  x .= 0
  while x < r
    halftoneContext.circle 2 * x * r + r, 2 * y * r + r, abs(12-x)+1
    halftoneContext.fill 0xffffff
    x += 1
  y += 1

colorChangeTimes := [
  1
  3
  5
  6
  7
  8
  12
]

retrowaveColorPalette := [
  0xf36688
  0xda3182
  0x9e316b
  0xbb3ad3
  0x684dda
  0x5033db
  0x261a5a
  0x1a1044
]

export class HalftoneBackground
  width: number
  height: number
  layers: Container[]
  colorChangeTimes: number[] = []
  container: Container

  @(@width: number, @height: number, layerCount: number = 8)
    @container := new Container
    @container.alpha = 1/8

    @layers = []
    i .= 0
    while i++ < layerCount
      layer := new Graphics halftoneContext

      @changeLayer layer

      @colorChangeTimes.push colorChangeTimes[rand(colorChangeTimes#)]

      @container.addChild layer
      @layers.push layer

  changeLayer = (layer: Container) =>
    layer.tint = retrowaveColorPalette[rand(retrowaveColorPalette#)]
    layer.rotation = rand(1024) * Math.PI / 512
    layer.x = rand(@width)
    layer.y = rand(@height)
    layer.scale = (rand(4) + 1) / 8

  update = (dt: number) =>
    @layers.forEach (layer, i) =>
      if @colorChangeTimes[i] > 0
        @colorChangeTimes[i] -= dt
      else
        @colorChangeTimes[i] += colorChangeTimes[rand(colorChangeTimes#)]
        @changeLayer layer
