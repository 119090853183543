{
  Application
  Assets
  BitmapText
  Rectangle
} from pixi.js

{
  dt
  type Vec2
} from ./util.civet

type { Scene } from ./scenes/scene.civet
{ Ball } from ./ball.civet
{ Editor } from ./scenes/editor.civet
{ WorldMap } from ./scenes/world-map.civet
{ Shop } from ./scenes/shop.civet

{
  init: audioInit
  setConvolution
} from ./audio.civet

{
  generateFace
  nineSliceBorder
} from ./display.civet

export type GameState
  balls: Ball[]
  level: number
  money: number

/**
* Wrapper over pixi Application. Holds the game state.
*/
export class Game
  // Display
  app: Application
  height = 720
  width = 1280
  resolution = 1

  // Game state
  sceneIndex = -1
  scenes: Scene[] := []

  // data that persists between levels
  state: GameState =
    balls: []
    level: 1
    money: 0

  //@ts-expect-error set in init
  debugText: BitmapText
  debug = ""

  @()
    @app := new Application

  init = () =>
    { app } := @
    init := app.init {
      @width
      @height
      @resolution
    }

    await.all [
      Assets.load "assets/m5x7.fnt"
      Assets.load nineSliceBorder
      Assets.load "assets/spritesheet.json"
      Assets.load "assets/eyes.png"
      Assets.load "assets/mouths.png"
      Assets.load "assets/accessories.png"
      Assets.load "assets/FX_Fire00_FlameThrower01_20x1.png"
      Assets.load "assets/FX_Fire00_FlameThrower02_Loop_6x6.png"
      audioInit()
      init
    ]

    setConvolution "BatteryBenson"

    @debugText := new BitmapText
      text: ""
      style:
        fontFamily: "m5x7"
        fontSize: 30
      x: 10
      y: 30

    // Need to add a generous hitArea to pick up move events
    // bigger than the canvas so we can track move events outside the canvas
    app.stage.interactive = true
    app.stage.hitArea = new Rectangle(-@width,-@height, 3 * @width, 3 * @height)

    app.stage.addChild @debugText

    t .= 0

    @reset()

    app.ticker.add (ticker) =>
      // TODO: Decouple update and render
      @update(dt)
      @draw(t)
      t += dt

    addEventListener 'keydown', (e: KeyboardEvent) =>
      switch e.key
        when "F1"
          e.preventDefault()
          @goToEditor()

  update = (dt: number): void =>
    @currentScene.update(dt)

  draw = (t: number): void =>
    if @debug
      @debugText.visible = true
      @debugText.text = @debug
    else
      @debugText.visible = false

    @currentScene.draw(t)

  action1 = () =>
    @currentScene.action1()

  pointerMove = (e: PointerEvent, pos: Vec2): void =>
    @currentScene.pointerMove?(e, pos)

  pointerDown = (e: PointerEvent, pos: Vec2): void =>
    @currentScene.pointerDown?(e, pos)

  pointerUp = (e: PointerEvent, pos: Vec2): void =>
    @currentScene.pointerUp?(e, pos)

  gameOver = () =>
    @reset()

  get currentScene(): Scene
    return @scenes[@sceneIndex]

  nextLevel = () =>
    if @currentScene
      @state = @currentScene.exit()
      @app.stage.removeChild @currentScene.stage

    @sceneIndex++
    if @sceneIndex >= @scenes.length
      @sceneIndex = 0

    if @currentScene
      @app.stage.addChild @currentScene.stage
      @currentScene.enter(@state)

  replaceScene = (scene: Scene) =>
    if @currentScene
      @state = @currentScene.exit()
      @app.stage.removeChild @currentScene.stage

    @scenes[@sceneIndex] = scene

    @app.stage.addChild @currentScene.stage
    @currentScene.enter(@state)

  popScene = () =>
    if @currentScene
      @state = @currentScene.exit()
      @app.stage.removeChild @currentScene.stage

    @scenes.pop()
    @sceneIndex = @scenes.length - 1
    if @sceneIndex < 0
      throw new Error "No more scenes to pop"

    if @currentScene
      @app.stage.addChild @currentScene.stage
      @currentScene.enter(@state)

  pushScene = (scene: Scene) =>
    if @currentScene
      @state = @currentScene.exit()
      @app.stage.removeChild @currentScene.stage

    @sceneIndex = @scenes.push(scene) - 1

    @app.stage.addChild @currentScene.stage
    @currentScene.enter(@state)

  goToEditor = () =>
    if scene := @currentScene
      @app.stage.removeChild scene.stage

    @sceneIndex = @scenes.push(new Editor) - 1

    @app.stage.addChild @currentScene.stage
    @currentScene.enter(@state)

  reset = () =>
    if scene := @currentScene
      @app.stage.removeChild scene.stage

    balls := [0...3].map => new Ball generateFace(@app.renderer)

    @state = {
      balls
      level: 1
      money: 0
    }

    @scenes.length = 0
    @sceneIndex = -1
    @scenes.push
      // new Shop @
      // TODO: title screen
      new WorldMap @

    @nextLevel()

export { Ball }
