{
  BitmapText
  Container
  Graphics
  Sprite
  type FederatedPointerEvent
  type SpriteOptions
  type TextOptions
} from pixi.js

{
  Observable
  type ObservableValue
} from @danielx/observable

Jadelet from jadelet

{ type Scene } from ./scene.civet

{
  circleContext
  lineContext
} from ../display.civet

type { GameState } from ../game.civet

{ Button } from ../ui.civet

{
  dist
  arcCircleIntersection
  circularCollision
  rayCircleIntersection
  type Vec2
} from ../util.civet

{
  playMusic
} from ../audio.civet

{
  PI
  floor
} := Math
TAU := 2 * PI

type SpritePart =
  type: "Sprite"
  properties: SpriteOptions

type TextPart =
  type: "BitmapText"
  properties: TextOptions

type Part =
  | SpritePart
  | TextPart

type Model = Record<string, ObservableValue<any>>

export class Compositor implements Scene
  stage = new Container
  //@ts-expect-error set in enter
  state: GameState

  parts: Part[] = []
  displayObjects = new Map<Part, Container>
  activeModel: Model?
  activeDrag: undefined |
    startPos: Vec2?
    initialX: number
    initialY: number
    model: Model

  border = new Graphics()
    .rect(0, 0, 1, 1)
    .stroke color: 0x8080ff, pixelLine: true

  @()
    @border.scale = 100
    @stage.addChild @border

    addEventListener 'keydown', (e: KeyboardEvent) =>
      let handled = false
      if e.metaKey or e.ctrlKey
        switch e.key
          when "z"
            handled = true
            if e.shiftKey
              console.log 'redo'
            else
              console.log 'undo'
          when "s"
            handled = true
            @save()
          when "o"
            handled = true
            @load()
          when "e"
            handled = true
            @export()

      if handled
        e.preventDefault()
        return

      return unless @activeModel
      // prevent typing in input fields
      if { tagName } := e.target
        if ["INPUT", "TEXTAREA"].includes tagName as string
          return

      mult .= 1
      mult = 10 if e.shiftKey
      mult = 100 if e.ctrlKey or e.metaKey

      d := mult

      switch e.key
        when "ArrowUp"
          @activeModel.y @activeModel.y() - d
        when "ArrowDown"
          @activeModel.y @activeModel.y() + d
        when "ArrowLeft"
          @activeModel.x @activeModel.x() - d
        when "ArrowRight"
          @activeModel.x @activeModel.x() + d

  update(dt: number): void
  draw(t: number): void

  enter(gameState: GameState): void
    @state = gameState

    @addPart
      type: "BitmapText"
      properties:
        text: "Compositor"
        x: 50
        y: 50
        style:
          fontFamily: "m5x7"
          fontSize: 15

    playMusic ""

  exit(): GameState
    @state

  pointerDown(e: PointerEvent, pos: Vec2): void
    if model := @activeModel
      x := model.x()
      y := model.y()
      @activeDrag = {
        startPos: pos
        initialX: x
        initialY: y
        model
      }

  pointerMove(e: PointerEvent, pos: Vec2): void
    if @activeDrag && @activeDrag.startPos
      dx := pos.x - @activeDrag.startPos.x
      dy := pos.y - @activeDrag.startPos.y

      @activeDrag.model.x floor @activeDrag.initialX + dx
      @activeDrag.model.y floor @activeDrag.initialY + dy

  pointerUp(e: PointerEvent, pos: Vec2): void
    @activeDrag = undefined

  action1(): void

  reset()
    for each part of @parts
      if display := @displayObjects.get part
        @stage.removeChild display

    @parts.length = 0
    @displayObjects.clear()

  save()
    localStorage.setItem "parts", JSON.stringify @parts

  load()
    @reset()
    try
      @parts = JSON.parse localStorage.getItem("parts") ?? "[]"

    for each part of @parts
      @addPart part

  addPart(part: Part)
    display := @makeDisplay part
    @displayObjects.set part, display
    @stage.addChild display

  makeDisplay = (part: Part): Container =>
    let display

    switch part.type
      when "BitmapText"
        bt := new BitmapText part.properties

        bt.interactive = true
        bt.addEventListener "pointerdown", (e: FederatedPointerEvent) =>
          model :=
            text: Observable(part.properties.text as string)
            x: Observable(part.properties.x or 0)
            y: Observable(part.properties.y or 0)

          // TODO: linkage between display and part
          @activeModel = model

          empty editorDiv

          editorDiv.appendChild Field {
            name: "text"
            type: "text"
            value: model.text
          }
          editorDiv.appendChild Field {
            name: "x"
            type: "number"
            value: model.x
          }
          editorDiv.appendChild Field {
            name: "y"
            type: "number"
            value: model.y
          }

          model.text.observe (text) =>
            // NOTE: this font displays a weird character when using \n but not for \r
            part.properties.text = bt.text = text.replace(/\n/g, "\r")
            @updateBorder(bt)
          model.x.observe (x) =>
            part.properties.x = bt.x = x
            @updateBorder(bt)
          model.y.observe (y) =>
            part.properties.y = bt.y = y
            @updateBorder(bt)

          @updateBorder(bt)

        display = bt

      when "Sprite"
        display = new Sprite part.properties
      // TODO
      // when "Container"
      //   display = new Container
      // when "Graphics"
      //   display = new Graphics part.properties
      // when "Button"
      //   display = new Button part.properties

    return display

  updateBorder(display?: Container)
    if !display
      @border.visible = false
      return

    @border.x = display.x
    @border.y = display.y
    @border.scale.x = display.width
    @border.scale.y = display.height

  export()
    // copy to clipboard
    navigator.clipboard.writeText JSON.stringify @parts

function empty(el: HTMLElement): void
  while child := el.firstChild
    el.removeChild child

editorDiv := document.createElement "div"
editorDiv.className = "editor"
document.body.appendChild editorDiv

type FieldType = "text" | "number"

type FieldParams =
  name: string
  type: FieldType
  value: ObservableValue<string> | ObservableValue<number>


function Field({type, value, name}: FieldParams): HTMLElement
  let el: HTMLInputElement | HTMLTextAreaElement
  let updating = false

  switch type
    when "text"
      el = document.createElement "textarea"

      el.value = value().toString()

      el.addEventListener "input", (e) =>
        updating = true
        value el.value
        updating = false

      value.observe (v) ->
        return if updating
        el.value = v.toString()

    when "number"
      el = document.createElement "input"
      el.type = "number"

      el.value = value().toString()

      el.addEventListener "input", (e) =>
        updating = true
        value parseFloat el.value
        updating = false

      value.observe (v) ->
        return if updating
        el.value = v.toString()

  return LabelTemplate {
    name
    el
  }

LabelTemplate := Jadelet.exec ```
  label
    h2 @name
    @el
```
