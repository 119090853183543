{
  BitmapText
  Container
  Sprite
  Texture
} from pixi.js
type { GameState } from ../game.civet
type { Vec2 } from ../util.civet

{ floor } := Math

export interface Scene
  stage: Container

  update(dt: number): void
  draw(t: number): void

  enter(gameState: GameState): void
  exit?(): GameState

  pointerDown?(e: PointerEvent, pos: Vec2): void
  pointerMove?(e: PointerEvent, pos: Vec2): void
  pointerUp?(e: PointerEvent, pos: Vec2): void
  action1(): void

export class Header
  container: Container
  state: GameState

  title: string
  moneyText: BitmapText
  titleText: BitmapText

  @(@state: GameState, @title: string)
    @container := new Container

    bg := new Sprite
      texture: Texture.WHITE
      width: 1280
      height: 36
      tint: 0x000000
      alpha: 0.875
    @container.addChild bg

    border := new Sprite
      texture: Texture.WHITE
      width: 1280
      height: 2
      tint: 0xffffff
      y: 36
    @container.addChild border

    @moneyText := new BitmapText
      text: '$',
      style:
        fontFamily: 'm5x7',
        fontSize: 30
        fill: 0xffffff
      y: 0
    @container.addChild @moneyText

    @titleText := new BitmapText
      text: @title,
      style:
        fontFamily: 'm5x7',
        fontSize: 30
        fill: 0xffffff
      y: 0
    @container.addChild @titleText

  update()
  draw(_t: number)
    @titleText.text = @title
    @titleText.x = floor 1280 / 2 - @titleText.width / 2

    @moneyText.text = '$' + @state.money
    // Right justify
    @moneyText.x = 1280 - @moneyText.width - 8
