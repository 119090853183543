{ Container, type Texture } from pixi.js

type { Vec2 } from ./util.civet
type { Item } from ./item.civet
type { Skill } from ./skill.civet
type { Level, PegData } from ./scenes/level.civet

type { ProjectileBase } from ./projectile.civet

{
  bounce
  physicsUpdate
} from ./util.civet

{
  cos
  min
  sin
} := Math

/**
A thrown ball in the playfield.
*/
export class Ball implements ProjectileBase
  name = "Ball"
  age = 0
  x = 320
  y = 0
  radius = 16
  rotation = 0
  rotationVelocity = 0
  velocity: Vec2 = { x: 0, y: 0 }
  items: Item[] = []
  skills: Skill[] = []
  active = true
  launchVelocity = 450
  gravity = { x: 0, y: 600 }
  xp = 0
  lvl = 1

  wallJumpAmount = 0

  source = @

  texture: Texture

  constructor(@texture: Texture, @name: string)

  reset(): void
    @x = 320
    @y = 0
    @rotation = 0
    @rotationVelocity = 0
    @velocity.x = 0
    @velocity.y = 0
    @active = true
    @wallJumpAmount = @skills.map(.wallJumpAmount).reduce((+), 0)

    for each item of @items
      item.reset(@)

    for each skill of @skills
      skill.reset(@)

  get aimRate(): number
    @skills.map(.aimRate).reduce((+), 0)

  get ammoMult(): number
    @skills.map(.ammoMultMod).reduce((*), 1)

  get priceMult(): number
    @skills.map(.priceMultMod).reduce((*), 1)

  get xpProgress(): number
    min 1, @xp / @xpToNextLevel

  get xpToNextLevel(): number
    XP_AMOUNTS[@lvl - 1]

  addItem(item: Item): void
    @items.push item.clone()

  addSkill(skill: Skill): void
    // TODO: per skill instances / state?
    @skills.push skill

  launch(position: Vec2, angle: number)
    @x = position.x
    @y = position.y
    @velocity.x = cos(angle) * @launchVelocity
    @velocity.y = sin(angle) * @launchVelocity

  update(level: Level, dt: number): void
    if physicsUpdate(@, dt)
      // hit wall
      if @velocity.y > -100 and @wallJumpAmount >= 1
        @wallJumpAmount--
        @velocity.y = -400

    for each item of @items
      item.onUpdate(level, @, dt)

    for each skill of @skills
      skill.onUpdate(level, @, dt)

    // Deactivate ball when below the playfield
    if @y > level.game.height + 100
      @active = false

    @age += dt

  onBulletFire(level: Level, item: Item): void
    for each item of @items
      item.onBulletFire(level, @, item)

    for each skill of @skills
      skill.onBulletFire(level, @, item)

  onHitPeg(level: Level, peg: PegData, collision: Vec2): void
    // Bounce on solid pegs
    switch peg.type
      when "peg"
        pos := { x: @x + collision.x, y: @y + collision.y }
        level.playSoundAt "boing", pos, 3

        bounce(@, collision)

    // Trigger item effects on solid and ghost pegs
    shooting .= false
    // items/powerups
    for each item of @items
      // Only one gun item can be active at a time
      if item.type is 'gun'
        if shooting is false and item.ammo > 0
          shooting = true
          item.onHit(level, @, peg, collision)
        else
          // already shooting or out of ammo, go to next item
      else // other items trigger always
        item.onHit(level, @, peg, collision)

  onPegActivate(level: Level, peg: PegData): void
    for each skill of @skills
      skill.onPegActivate(level, @, peg)

  /** placeholder until projectile and ball update/draw is unified */
  container = new Container
  /** placeholder until projectile and ball update/draw is unified */
  draw(): void

XP_AMOUNTS := [
  10
  25
  75
  150
  250
  500
  1000
  2500
]

export NAMES := [
  // A Scanner Darkly
  [
    "Barris"
    "Bob"
    "Luckman"
    "Donna"
    "Fred"
    "Arctor"
    "Hank"
    "Bruce"
  ]

  // Rick and Morty
  [
    "Morty"
    "Rick"
    "Summer"
    "Beth"
    "Jerry"
    "Tammy"
  ]

  // Dostoevsky
  [
    "Sonya"
    "Marmeladov"
    "Luzhin"
    "Pulcheria"
    "Dounia"
    "Porfiry"
    "Zametov"
    "Nastasya"
  ]

  // The Wire
  [
    "McNulty"
    "Bunk"
    "Stringer"
    "Avon"
    "Omar"
    "Bodie"
    "D'Angelo"
    "Kima"
    "Daniels"
    "Bubbles"
    "Marlo"
  ]

  // X-Com
  [
    "Vahlen"
    "Shen"
    "Bradford"
    "Central"
    "Annette"
    "Zhang"
    "Van Doorn"
    "Friendly"
    "Furies"
    "Falcon"
    "Valkyrie"
    "Armand"
  ]

  // The Expanse
  [
    "Holden"
    "Naomi"
    "Amos"
    "Alex"
    "Miller"
    "Dawes"
    "Julie"
  ]

  // Firefly Series
  [
    "Mal"
    "Zoe"
    "Wash"
    "Jayne"
    "Kaylee"
    "Simon"
    "River"
    "Inara"
    "Book"
  ]

  // Blippi
  [
    "Steezy"
    "Stevin"
    "Blip"
    "Grossman"
  ]

  [ // C Town
    "Adam"
    "Nick"
    "Stav"
  ]
]
