{
  Application
  Container
  Graphics
} from pixi.js

{
  PI
  round
} := Math

TAU := 2 * PI

WIDTH := 640
HEIGHT := 360

type { Tool, GameObject } from ../scenes/editor/object-editor.civet
{ calcCollisions, ObjectEditor } from ../scenes/editor/object-editor.civet

tools := [
  { type: "circle", x: 0, y: 0, r: 6 }
  { type: "arcCollider", x: 0, y: 0, r: 100, startAngle: -TAU / 16, endAngle: TAU / 16 }
  { type: "circleCollider", x: 0, y: 0, r: 100 }
  { type: "rayCollider", x: 0, y: 0, r: 100, angle: 0 }
].map((object) => ({
  type: "create"
  object
}) as Tool)
.concat { type: "edit" }

activeToolIndex .= 0

window.addEventListener 'contextmenu', (e) => e.preventDefault()

window.addEventListener 'pointerdown', (e) =>
  if e.defaultPrevented
    return

  for el of e.composedPath()
    if el <? HTMLElement and (el.tagName is "INPUT" or el.tagName is "TEXTAREA")
      return

  e.preventDefault()

  if pos := screenPos(e)
    action(pos, e)

function screenPos(e: MouseEvent)
  return unless canvas
  // get angle from center top of canvas to mouse
  { top, left, width, height } := canvas.getBoundingClientRect()

  x := (e.clientX - left) / (width / WIDTH)
  y := (e.clientY - top) / (height / HEIGHT)

  return {x, y}

objects := Array<GameObject>()
displayObjects := new Map<GameObject, Container>

function action({x, y}: {x: number, y: number}, e: PointerEvent): void
  x = round(x)
  y = round(y)

  tool := tools[activeToolIndex]


  calcCollisions(objects, displayObjects)


export {}

let canvas: HTMLCanvasElement?
app := new Application

document.addEventListener 'paste', (e) =>
  e.preventDefault()
  // TODO: handle paste

document.addEventListener 'dragover', (e) =>
  e.preventDefault()
document.addEventListener 'dragenter', (e) =>
  e.preventDefault()
document.addEventListener 'drop', (e) =>
  e.preventDefault()
  // TODO: handle file drop

let attachEditor: (object: GameObject) => void

async do
  await app.init
    width: WIDTH
    height: HEIGHT
    resolution: 2
  canvas = app.canvas
  document.body.appendChild app.canvas

  // backgound to pick up move events
  app.stage.interactive = true
  app.stage.addChild new Graphics()
    .rect(0, 0, WIDTH, HEIGHT)
    .fill(0x000000)

  actions := document.createElement 'div'
  actions.style.position = 'absolute'
  actions.style.top = '0'
  actions.style.left = '0'

  document.body.appendChild actions
  Btn := (text: string, onClick: () => void) =>
    button := document.createElement 'button'
    button.textContent = text
    button.addEventListener 'click', onClick
    actions.appendChild button

  Btn "Reset", reset

  actions.appendChild document.createElement 'br'

  // Add radio buttons for tools
  for [i, tool] of tools.entries()
    input := document.createElement 'input'
    input.type = 'radio'
    input.name = 'tool'
    input.value = i.toString()
    input.checked = i is activeToolIndex
    input.addEventListener 'change', (e) =>
      activeToolIndex = i

    h3 := document.createElement 'h3'
    h3.textContent = tool.type is "create" ? tool.object.type : tool.type

    label := document.createElement 'label'
    label.appendChild h3
    label.appendChild input
    actions.appendChild label

  let container
  { attach: attachEditor, container } = ObjectEditor(objects, displayObjects, updateDisplay)
  app.stage.addChild container
