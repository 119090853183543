{ Container, type Texture } from pixi.js

type { Vec2 } from ./util.civet
type { Item } from ./item.civet
type { Level, PegData } from ./scenes/level.civet

{ bounce, physicsUpdate, randomItem, rand } from ./util.civet

{
  abs
  cos
  min
  sin
} := Math

/**
A thrown ball in the playfield.
*/
export class Ball
  name = randomItem NAMES
  x = 320
  y = 0
  radius = 16
  rotation = 0
  rotationVelocity = 0
  velocity: Vec2 = { x: 0, y: 0 }
  items: Item[] = []
  active = true
  launchVelocity = 450
  gravity = { x: 0, y: 600 }
  ephemeral = false
  xp = 0
  lvl = 1

  source = @

  texture: Texture

  constructor(@texture: Texture)

  get xpProgress(): number
    min 1, @xp / @xpToNextLevel

  get xpToNextLevel(): number
    XP_AMOUNTS[@lvl - 1]

  launch(position: Vec2, angle: number)
    @x = position.x
    @y = position.y
    @velocity.x = cos(angle) * @launchVelocity
    @velocity.y = sin(angle) * @launchVelocity

  update(level: Level, dt: number): void
    physicsUpdate(@, dt)

    for each item of @items
      item.onUpdate?(level, @, dt)

  resolveCollision(level: Level, collision: Vec2): void
    pos := { x: @x + collision.x, y: @y + collision.y }
    level.playSoundAt "boing", pos, 3

    bounce(@, collision)

  trigger(level: Level, peg: PegData, collision: Vec2)
    shooting .= false
    // items/powerups
    for each item of @items
      if item.type is 'gun'
        if shooting is false and item.ammo > 0
          shooting .= true
          item.onHit(level, @, peg, collision)
        else
          // already shooting or out of ammo, go to next item
      else // other items trigger always
        item.onHit(level, @, peg, collision)


  handleBallCollision(): void

  /** placeholder until projectile and ball update/draw is unified */
  container = new Container
  /** placeholder until projectile and ball update/draw is unified */
  draw(): void

XP_AMOUNTS := [
  10
  25
  75
  150
  250
  500
  1000
  2500
]

NAMES := [
  // A Scanner Darkly
  "Barris"
  "Bob"
  "Luckman"
  "Donna"
  "Fred"
  "Arctor"
  "Hank"
  "Bruce"

  // Rick and Morty
  "Morty"
  "Rick"
  "Summer"
  "Beth"
  "Jerry"
  "Tammy"

  // Dostoevsky
  "Sonya"
  "Marmeladov"
  "Luzhin"
  "Pulcheria"
  "Dounia"
  "Porfiry"
  "Zametov"
  "Nastasya"

  // The Wire
  "McNulty"
  "Bunk"
  "Stringer"
  "Avon"
  "Omar"
  "Bodie"
  "D'Angelo"
  "Kima"
  "Daniels"
  "Bubbles"
  "Marlo"

  // X-Com
  "Vahlen"
  "Shen"
  "Bradford"
  "Central"
  "Annette"
  "Zhang"
  "Van Doorn"
  "Friendly"
  "Furies"
  "Falcon"
  "Valkyrie"
  "Armand"

  // The Expanse
  "Holden"
  "Naomi"
  "Amos"
  "Alex"
  "Miller"
  "Dawes"
  "Julie"

  // Firefly Series
  "Mal"
  "Zoe"
  "Wash"
  "Jayne"
  "Kaylee"
  "Simon"
  "River"
  "Inara"
  "Book"

  "Steezy"

  "Adam"
  "Nick"
  "Stav"
]
