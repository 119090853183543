{ Observable } from jadelet

{ rand } from './util.civet'

ctx := new AudioContext

{
  masterVolume
  musicVolume
  sfxVolume
} from './scenes/settings.civet'

masterGainNode := ctx.createGain()
masterGainNode.gain.value = masterVolume()
masterGainNode.connect ctx.destination

masterVolume.observe (value) =>
  adjustGainToValue masterGainNode, value

musicGainNode := ctx.createGain()
musicGainNode.gain.value = musicVolume()
musicGainNode.connect masterGainNode

musicVolume.observe (value) =>
  adjustGainToValue musicGainNode, value

sfxGainNode := ctx.createGain()
sfxGainNode.gain.value = sfxVolume()
sfxGainNode.connect masterGainNode

sfxVolume.observe (value) =>
  adjustGainToValue sfxGainNode, value

function adjustGainToValue(gainNode: GainNode, value: number)
  if 'cancelAndHoldAtTime' in gainNode.gain
    gainNode.gain.cancelAndHoldAtTime ctx.currentTime
  gainNode.gain.linearRampToValueAtTime value, ctx.currentTime + 0.01

let sfxConvolutionNode: ConvolverNode?
export function setConvolution(name: string)
  if buf := sounds[name]
    convNode := ctx.createConvolver()
    convNode.buffer = buf
    convNode.connect sfxGainNode
    sfxConvolutionNode = convNode
  else
    throw new Error `No convolution buffer found for ${name}`

export playSound := (buffer: AudioBuffer | string, start = 0, volume = 1, pan = 0) =>
  ctx.resume()

  source := ctx.createBufferSource()
  if buffer <? AudioBuffer
    source.buffer = buffer
  else
    // randomize boings
    if buffer is "boing"
      buffer += rand(7)

    if buf := sounds[buffer]
      source.buffer = buf

  gainNode := ctx.createGain()
  gainNode.gain.value = volume

  panNode := ctx.createStereoPanner()
  panNode.pan.value = pan

  source.connect gainNode
  gainNode.connect panNode

  if sfxConvolutionNode
    panNode.connect sfxConvolutionNode

  panNode.connect sfxGainNode

  source.start start + ctx.currentTime

let sounds: {[k: string]: AudioBuffer}, loading: Promise<void>
export function init()
  unless loading
    loading = loadSounds()

  return loading

soundFiles :=
  peg: 'assets/peg.mp3'
  azure: 'assets/Azure.ogg'
  circle: 'assets/circle.ogg'
  boop: 'assets/boop.wav'
  shot: 'assets/shot-suppressed.ogg'
  boing0: 'assets/boing0.wav'
  boing1: 'assets/boing1.wav'
  boing2: 'assets/boing2.wav'
  boing3: 'assets/boing3.wav'
  boing4: 'assets/boing4.wav'
  boing5: 'assets/boing5.wav'
  boing6: 'assets/boing6.wav'
  BatteryBenson: 'assets/BatteryBenson.wav'

function loadSounds()
  sounds = Object.fromEntries await.all Object.entries(soundFiles).map async ([name, url]) =>
    buffer := await fetch url
    |> await .arrayBuffer()
    |> ctx.decodeAudioData
    |> await

    [name, buffer] as const

  console.log "Sounds loaded"

  return

type TrackName = keyof typeof soundFiles

type MusicInfo =
  name: TrackName
  gain: GainNode
  source: AudioBufferSourceNode

prevTrack: MusicInfo? .= undefined
export playMusic := (name: TrackName | "") =>
  if prevTrack
    { gain, source, name: prevName } := prevTrack

    if name is prevName
      return

    gain.gain.setValueAtTime 1, ctx.currentTime
    gain.gain.linearRampToValueAtTime 0, ctx.currentTime + 0.1

    source.stop ctx.currentTime + 0.1

    prevTrack = undefined

  return unless name

  gain := ctx.createGain()
  gain.connect musicGainNode

  source := ctx.createBufferSource()
  source.buffer = sounds[name]
  source.loop = true
  source.start ctx.currentTime
  source.connect gain

  prevTrack = {
    name
    gain
    source
  }
