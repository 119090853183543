in vec2 vTextureCoord;
in vec4 vColor;

uniform sampler2D uTexture;
uniform float uTime;

void main(void) {
  vec2 uvs = vTextureCoord.xy;

  const float PI = 3.14159265359;

  float t = abs(sin(uTime * PI / 30.));

  gl_FragColor = vec4(t * .22, 0.18, 0.25, 1.0);

}
