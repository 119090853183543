{
  Application
  Assets
  BitmapText
  Rectangle
} from pixi.js

{
  dt
  type Vec2
  randomItem
  shuffle
} from ./util.civet

type { Scene } from ./scenes/scene.civet
{ Ball, NAMES } from ./ball.civet
{ Compositor } from ./scenes/compositor.civet
{ Editor } from ./scenes/editor.civet
{ Title } from ./scenes/title.civet
{ Shop } from ./scenes/shop.civet
{ Level } from ./scenes/level.civet

{ items } from ./item.civet
{ skills } from ./skill.civet

{
  init: audioInit
  setConvolution
  playMusic
  playSound
} from ./audio.civet

{
  generateFace
  nineSliceBorder
} from ./display.civet

{ getLevelData } from ./scenes/editor.civet

{ present } from ./views/palette.civet

export type GameState
  balls: Ball[]
  level: number
  money: number

/**
* Wrapper over pixi Application. Holds the game state.
*/
export class Game
  // Display
  app: Application
  height = 720
  width = 1280
  resolution = 1

  // Game state
  sceneIndex = -1
  scenes: Scene[] := []

  // data that persists between levels
  state: GameState =
    balls: []
    level: 1
    money: 0

  //@ts-expect-error set in init
  debugText: BitmapText
  debug = ""

  @()
    @app := new Application

  init = () =>
    { app } := @
    init := app.init {
      @width
      @height
      @resolution
    }

    await.all [
      Assets.load "assets/m5x7.fnt"
      Assets.load nineSliceBorder
      Assets.load "assets/spritesheet.json"
      Assets.load "assets/title.png"
      Assets.load "assets/eyes.png"
      Assets.load "assets/mouths.png"
      Assets.load "assets/accessories.png"
      Assets.load "assets/FX_Fire00_FlameThrower01_20x1.png"
      Assets.load "assets/FX_Fire00_FlameThrower02_Loop_6x6.png"
      audioInit()
      init
    ]

    setConvolution "BatteryBenson"

    @debugText := new BitmapText
      text: ""
      style:
        fontFamily: "m5x7"
        fontSize: 30
      x: 10
      y: 30

    // Need to add a generous hitArea to pick up move events
    // bigger than the canvas so we can track move events outside the canvas
    app.stage.interactive = true
    app.stage.hitArea = new Rectangle(-@width,-@height, 3 * @width, 3 * @height)

    app.stage.addChild @debugText

    t .= 0

    @reset()

    app.ticker.add (ticker) =>
      // TODO: Decouple update and render
      @update(dt)
      @draw(t)
      t += dt

    addEventListener 'keydown', (e: KeyboardEvent) =>
      switch e.key
        when "F1"
          e.preventDefault()
          @goToEditor()
        when "F2"
          e.preventDefault()
          @goToCompositor()
        when "F3"
          e.preventDefault()
          @goToShop()

        when "P", "p"
          if e.ctrlKey or e.metaKey
            e.preventDefault()
            // command palette
            @showPalette()

  showPalette()
    present [
      { name: "Editor", trigger: @goToEditor }
      { name: "Compositor", trigger: @goToCompositor }
      { name: "Shop", trigger: @goToShop }
      { name: "Reset", trigger: @reset }
      { name: "Level Select", trigger: () => {
        present getLevelData().map { name } => {
          name
          trigger: =>
            @pushScene new Level @, name
        }
      }}
      { name: "Add Item", trigger: () => {
        present items.map (item) =>
          { name: item.name, trigger: () => {
            @state.balls[0].addItem item
          }}
      }}
      { name: "Add Skill", trigger: () => {
        present skills.map (skill) =>
          { name: skill.name, trigger: () => {
            @state.balls[0].skills.push skill
          }}
      }}
    ]

  update = (dt: number): void =>
    @currentScene.update(dt)

  draw = (t: number): void =>
    if @debug
      @debugText.visible = true
      @debugText.text = @debug
    else
      @debugText.visible = false

    @currentScene.draw(t)

  action1 = () =>
    @currentScene.action1()

  pointerMove = (e: PointerEvent, pos: Vec2): void =>
    @currentScene.pointerMove?(e, pos)

  pointerDown = (e: PointerEvent, pos: Vec2): void =>
    @currentScene.pointerDown?(e, pos)

  pointerUp = (e: PointerEvent, pos: Vec2): void =>
    @currentScene.pointerUp?(e, pos)

  gameOver = () =>
    @reset()

  get currentScene(): Scene
    return @scenes[@sceneIndex]

  endScene = (scene = @currentScene) =>
    if scene
      if scene.exit?
        @state = scene.exit()
      @app.stage.removeChild scene.stage

  /**
  * Replace the current scene with a new scene
  */
  replaceScene = (scene: Scene) =>
    @endScene()

    @scenes[@sceneIndex] = scene

    @app.stage.addChild @currentScene.stage
    @currentScene.enter(@state)

  /**
  * Pop the current scene off the stack
  * and enter the previous scene.
  */
  popScene = () =>
    @endScene()

    @scenes.pop()
    @sceneIndex = @scenes.length - 1
    if @sceneIndex < 0
      throw new Error "No more scenes to pop"

    @app.stage.addChild @currentScene.stage
    @currentScene.enter(@state)

  /**
  * Push a new scene onto the stack, exiting the current scene and entering the new scene.
  */
  pushScene = (scene: Scene) =>
    @endScene()

    @sceneIndex = @scenes.push(scene) - 1

    @app.stage.addChild @currentScene.stage
    @currentScene.enter(@state)

  /**
  * Replace all scenes in the scene stackt with a new scene.
  * Exits the current scene and enters the new scene.
  */
  replaceAllScenes = (scene: Scene) =>
    @endScene()

    @scenes.length = 0
    @sceneIndex = 0
    @scenes[@sceneIndex] = scene

    @app.stage.addChild @currentScene.stage
    @currentScene.enter(@state)

  private goToEditor = () =>
    @replaceAllScenes new Editor

  private goToCompositor = () =>
    @replaceAllScenes new Compositor

  private goToShop = () =>
    @pushScene new Shop @

  playMusic = playMusic
  playSound = playSound

  reset = () =>
    if scene := @currentScene
      @app.stage.removeChild scene.stage

    nameGroup := shuffle randomItem(NAMES).slice()

    balls := [0...3].map (i) =>
      new Ball generateFace(@app.renderer), nameGroup[i]

    @state = {
      balls
      level: 1
      money: 0
    }

    @scenes.length = 0
    @sceneIndex = -1

    @pushScene new Title @

export { Ball }
