{
  BitmapText
  Container
  type TextOptions
} from pixi.js

{
  type Vec2
} from ./util.civet

export * from ./ui/button.civet

export function ShadowedText(options: TextOptions, offset: Vec2)
  container := new Container

  titleTextShadow := new BitmapText Object.assign {}, options, {
    style: Object.assign {}, options.style, {
      fill: 0x000000
    }
    x: offset.x
    y: offset.y
  }
  titleTextShadow.anchor.set(0.5)
  container.addChild titleTextShadow

  titleText := new BitmapText options
  titleText.anchor.set(0.5)
  container.addChild titleText

  return container
