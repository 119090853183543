{ Observable } from jadelet

{ floatToWav } from './lib/wav-pcm.civet'
{ rand } from './util.civet'

ctx := new AudioContext

SettingsTemplate := require("./settings.jadelet")

export settings :=
  masterVolume: 0.5
  musicVolume: 1
  sfxVolume: 1

try
  if savedSettings := localStorage.getItem 'stay-pegged:settings'
    Object.assign settings, JSON.parse savedSettings

masterGainNode := ctx.createGain()
masterGainNode.gain.value = settings.masterVolume
masterGainNode.connect ctx.destination

masterVolume := Observable(settings.masterVolume)
masterVolume.observe (value) =>
  adjustGainToValue masterGainNode, value
  settings.masterVolume = value
  localStorage.setItem 'stay-pegged:settings', JSON.stringify settings

musicGainNode := ctx.createGain()
musicGainNode.gain.value = settings.musicVolume
musicGainNode.connect masterGainNode

musicVolume := Observable(settings.musicVolume)
musicVolume.observe (value) =>
  adjustGainToValue musicGainNode, value
  settings.musicVolume = value
  localStorage.setItem 'stay-pegged:settings', JSON.stringify settings

sfxGainNode := ctx.createGain()
sfxGainNode.gain.value = settings.sfxVolume
sfxGainNode.connect masterGainNode

sfxVolume := Observable(settings.sfxVolume)
sfxVolume.observe (value) =>
  adjustGainToValue sfxGainNode, value
  settings.sfxVolume = value
  localStorage.setItem 'stay-pegged:settings', JSON.stringify settings

function adjustGainToValue(gainNode: GainNode, value: number)
  if 'cancelAndHoldAtTime' in gainNode.gain
    gainNode.gain.cancelAndHoldAtTime ctx.currentTime
  gainNode.gain.linearRampToValueAtTime value, ctx.currentTime + 0.01

document.body.appendChild SettingsTemplate {
  masterVolume
  musicVolume
  sfxVolume
  log: console.log
}

let sfxConvolutionNode: ConvolverNode?
export function setConvolution(name: string)
  if buf := sounds[name]
    convNode := ctx.createConvolver()
    convNode.buffer = buf
    convNode.connect sfxGainNode
    sfxConvolutionNode = convNode
  else
    throw new Error `No convolution buffer found for ${name}`

export playSound := (buffer: AudioBuffer | string, start = 0, volume = 1, pan = 0) =>
  ctx.resume()

  source := ctx.createBufferSource()
  if buffer <? AudioBuffer
    source.buffer = buffer
  else
    // randomize boings
    if buffer is "boing"
      buffer += rand(7)

    if buf := sounds[buffer]
      source.buffer = buf

  gainNode := ctx.createGain()
  gainNode.gain.value = volume

  panNode := ctx.createStereoPanner()
  panNode.pan.value = pan

  source.connect gainNode
  gainNode.connect panNode

  if sfxConvolutionNode
    panNode.connect sfxConvolutionNode

  panNode.connect sfxGainNode

  source.start start + ctx.currentTime

let sounds: {[k: string]: AudioBuffer}, loading: Promise<void>
export function init()
  unless loading
    loading = loadSounds()

  return loading

function loadSounds()
  sounds = Object.fromEntries await.all Object.entries(
    azure: 'assets/Azure.ogg'
    circle: 'assets/circle.ogg'
    boop: 'assets/boop.wav'
    shot: 'assets/shot-suppressed.ogg'
    boing0: 'assets/boing0.wav'
    boing1: 'assets/boing1.wav'
    boing2: 'assets/boing2.wav'
    boing3: 'assets/boing3.wav'
    boing4: 'assets/boing4.wav'
    boing5: 'assets/boing5.wav'
    boing6: 'assets/boing6.wav'
    BatteryBenson: 'assets/BatteryBenson.wav'
  ).map async ([name, url]) =>
    buffer := await fetch url
    |> await .arrayBuffer()
    |> ctx.decodeAudioData
    |> await

    [name, buffer] as const

  console.log "Sounds loaded"

  return

type MusicInfo =
  gain: GainNode
  source: AudioBufferSourceNode

prevTrack: MusicInfo? .= undefined
export playMusic := (name: string) =>
  if prevTrack
    { gain, source } := prevTrack

    gain.gain.setValueAtTime 1, ctx.currentTime
    gain.gain.linearRampToValueAtTime 0, ctx.currentTime + 0.1

    source.stop ctx.currentTime + 0.1

    prevTrack = undefined

  return unless name

  gain := ctx.createGain()
  gain.connect musicGainNode

  source := ctx.createBufferSource()
  source.buffer = sounds[name]
  source.loop = true
  source.start ctx.currentTime
  source.connect gain

  prevTrack = {
    gain
    source
  }

button := document.createElement 'button'
button.textContent = 'Sound Test'
button.style.position = 'absolute'
button.style.top = '0'
button.style.right = '12rem'
button.addEventListener 'click', =>
  playSound "shot", 0, 1, 0
document.body.append button

function splitBoings()
  boingsData := sounds["boings"].getChannelData(0)
  // split out the boings

  { length } := boingsData
  let i = 0, s = 0, e = 0
  parts := []
  while i < length
    while i < length and boingsData[i] is 0
      i++
    e = s = i

    // end if trailing 10 samples are 0
    while i < length and i < e + 100
      while i < length and boingsData[i] is not 0
        e = i
        i++
      i++

    if e - s > 1000
      parts.push [s, e]

  parts.map ([s, e], i) =>
    buffer := boingsData.slice(s, e)
    uint8Data := floatToWav {
      sampleRate: ctx.sampleRate
      channelData: [ buffer ]
    }

    blob := new Blob [ uint8Data ], type: "audio/wav"

    // download
    a := document.createElement 'a'
    a.href = URL.createObjectURL blob
    a.download = `boing${i}.wav`

    // document.body.appendChild a
    // a.click()
