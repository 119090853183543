{
  Container
  Graphics
  BitmapText
  Rectangle
  Sprite
  Texture
} from pixi.js

{ Header } from ./scene.civet
{ Button } from ../ui.civet

type { Ball, Game, GameState } from ../game.civet

{
  type Item
  items
} from ../item.civet

{
  shuffle
  rand
  type Vec2
} from ../util.civet

{
  Observable
  type ObservableValue
} from @danielx/observable

{
  floor
  max
} := Math

type Model =
  x: ObservableValue<number>
  y: ObservableValue<number>
  saleItem: SaleItem

type SaleItem
  item: Item
  cost: number

export class Encounter
  stage := new Container
  //@ts-expect-error set in enter
  state: GameState
  interactive = false
  header: Header

  activeDrag: undefined |
    startPos: Vec2?
    initialX: number
    initialY: number
    model: Model

  update(_dt: number): void
    @header.state = @state

  draw(t: number): void
    @header.draw t

  enter(@state: GameState): void

    @game.playMusic 'tristram'
    @game.playSound 'peg_a_while'

    @interactive = true

  exit(): GameState
    @state

  pointerDown(e: PointerEvent, pos: Vec2): void

  pointerMove(e: PointerEvent, pos: Vec2): void

  pointerUp(e: PointerEvent, pos: Vec2): void

  action1(): void

  @(@game: Game, @title: string)
    bgSprite := new Sprite Texture.from "assets/sage.png"
    bgSprite.scale = 2
    @stage.addChild bgSprite

    @header := new Header @game.state, @title
    @stage.addChild @header.container

    leaveButton := Button
      text: "Leave"
      y: 720 - 44 - 20
      x: 640
      width: 200
      height: 40
      fontSize: 30
      onSelect: =>
        @game.popScene()

    leaveButton.x = (1280 - leaveButton.width) / 2
    @stage.addChild leaveButton
