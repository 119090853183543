// https://stackoverflow.com/a/42632646/68210
// With added patch for off by one clipping error

export function floatToWav( { sampleRate=48000, bitDepth=16, channelData: buffers }: { sampleRate?: number, bitDepth?: number, channelData: Float32Array[] } ) {
  recordedBuffers := []
  bytesPerSample := bitDepth / 8

  numberOfChannels := buffers.length
  bufferLength := buffers[0].length
  var reducedData = new Uint8Array( bufferLength * numberOfChannels * bytesPerSample );

  // Interleave
  for ( var i = 0; i < bufferLength; i++ ) {
    for ( var channel = 0; channel < numberOfChannels; channel++ ) {

      outputIndex := ( i * numberOfChannels + channel ) * bytesPerSample;
      sample .= buffers[ channel ][ i ];

      // Check for clipping
      if ( sample > 1 ) {
        sample = 1;
      }

      else if ( sample < -1 ) {
        sample = -1;
      }

      // bit reduce and convert to uInt
      switch ( bytesPerSample ) {
        case 4:
          sample = sample * 2147483648;
          if ( sample > 0 ) {
            sample *= 2147483647;
          } else {
            sample *= 2147483648;
          }
          reducedData[ outputIndex ] = sample;
          reducedData[ outputIndex + 1 ] = sample >> 8;
          reducedData[ outputIndex + 2 ] = sample >> 16;
          reducedData[ outputIndex + 3 ] = sample >> 24;
          break;

        case 3:
          if ( sample > 0 ) {
            sample *= 8388607;
          } else {
            sample *= 8388608;
          }
          reducedData[ outputIndex ] = sample;
          reducedData[ outputIndex + 1 ] = sample >> 8;
          reducedData[ outputIndex + 2 ] = sample >> 16;
          break;

        case 2:
          if ( sample > 0 ) {
            sample *= 32767;
          } else {
            sample *= 32768;
          }
          reducedData[ outputIndex ] = sample;
          reducedData[ outputIndex + 1 ] = sample >> 8;
          break;

        case 1:
          // TODO: Not sure about this, I think 8bit is stored as unsigned 0-255
          reducedData[ outputIndex ] = ( sample + 1 ) * 128;
          break;

        default:
          throw "Only 8, 16, 24 and 32 bits per sample are supported";
      }
    }
  }

  recordedBuffers.push( reducedData );

  dataLength := recordedBuffers.length * recordedBuffers[0].length
  headerLength := 44
  wav := new Uint8Array( headerLength + dataLength )
  view := new DataView( wav.buffer )

  view.setUint32( 0, 1380533830, false ); // RIFF identifier 'RIFF'
  view.setUint32( 4, 36 + dataLength, true ); // file length minus RIFF identifier length and file description length
  view.setUint32( 8, 1463899717, false ); // RIFF type 'WAVE'
  view.setUint32( 12, 1718449184, false ); // format chunk identifier 'fmt '
  view.setUint32( 16, 16, true ); // format chunk length
  view.setUint16( 20, 1, true ); // sample format (raw)
  view.setUint16( 22, numberOfChannels, true ); // channel count
  view.setUint32( 24, sampleRate, true ); // sample rate
  view.setUint32( 28, sampleRate * bytesPerSample * numberOfChannels, true ); // byte rate (sample rate * block align)
  view.setUint16( 32, bytesPerSample * numberOfChannels, true ); // block align (channel count * bytes per sample)
  view.setUint16( 34, bitDepth, true ); // bits per sample
  view.setUint32( 36, 1684108385, false); // data chunk identifier 'data'
  view.setUint32( 40, dataLength, true ); // data chunk length

  for (var i = 0; i < recordedBuffers.length; i++ ) {
    wav.set( recordedBuffers[i], i * recordedBuffers[i].length + headerLength );
  }

  return wav;
};
