{
  Container
  Graphics
  BitmapText
  Rectangle
  Sprite
  Texture
} from pixi.js

type { Ball, Game, GameState } from ../game.civet
type { Item } from ../item.civet

{ items } from ../item.civet

{ Level } from ./level.civet
{ shuffle, rand, clamp } from ../util.civet
{ floor } := Math

export class Shop
  stage := new Container
  game: Game
  //@ts-expect-error set in enter
  state: GameState
  interactive = false
  moneyText: BitmapText

  inventoriesContainer := new Container()

  update(_dt: number): void
  draw(_t: number): void
    @moneyText.text = '$' + @state.money

    @moneyText.x = 1280 - @moneyText.width - 20

  enter(@state: GameState): void
    @state.money = rand 100
    @interactive = true

    @stage.addChild @inventoriesContainer
    @renderInventory()

  renderInventory()
    @inventoriesContainer.removeChildren()

    @state.balls.forEach (ball, i) =>
      ballInventory := makeBallInventory ball
      ballInventory.x = 20
      ballInventory.y = 300 + i * 100

      @inventoriesContainer.addChild ballInventory

  exit(): GameState
    @state

  pointerMove(_pos: unknown): void
  action1(): void

  @(@game: Game)
    table := new Graphics()
      .rect 0, 0, 1280, 720
      .fill 0xaeaeae
    @stage.addChild table

    text := new BitmapText
      text: 'Shop',
      style:
        fontFamily: 'm5x7',
        fontSize: 30
        fill: 0x000000

    text.x = 1280 / 2 - text.width / 2
    text.y = 20

    @stage.addChild text

    @moneyText := new BitmapText
      text: '$',
      style:
        fontFamily: 'm5x7',
        fontSize: 30
        fill: 0x000000

    @moneyText.y = 20

    @stage.addChild @moneyText

    itemSpacing := 120

    shuffle(items.slice()).slice(0, 4).forEach (item, i) =>
      itemContainer := new Container

      cost := rand(25) + 5

      itemGfx := new Graphics()
        .rect 0, 0, 44, 44
        .fill 0x00ff00
      itemGfx.x = 20 + i * itemSpacing
      itemGfx.y = 60
      itemContainer.addChild itemGfx

      itemText := new BitmapText
        text: item.name,
        style:
          fontFamily: 'm5x7',
          fontSize: 15
          fill: 0x000000

      itemText.x = 20 + i * itemSpacing
      itemText.y = 110

      itemContainer.addChild itemText

      icon := new Sprite Texture.from item.icon
      icon.anchor.set(0.5)
      icon.scale.set(2)

      icon.x = 20 + i * itemSpacing + 22
      icon.y = 60 + 22

      itemContainer.addChild icon

      costText := new BitmapText
        text: '$' + cost,
        style:
          fontFamily: 'm5x7',
          fontSize: 15
          fill: 0x000000

      costText.x = 20 + i * itemSpacing
      costText.y = 130

      itemContainer.addChild costText

      itemContainer.interactive = true
      // itemContainer.hitArea = new Rectangle(0, 0, 44, 44)

      itemContainer.on 'pointerdown', (e) =>
        return if e.defaultPrevented

        if @state.money >= cost
          @state.money -= cost
          @state.balls[0].items.push item.clone()

          @renderInventory()

      leaveButton := new Graphics()
        .rect 0, 0, 320, 44
        .fill 0x00ff00

      leaveButton.x = 1280 / 2 - 320 / 2
      leaveButton.y = 720 - 44 - 20

      leaveText := new BitmapText
        text: 'Leave',
        style:
          fontFamily: 'm5x7',
          fontSize: 15
          fill: 0x000000

      leaveText.x = 1280 / 2 - leaveText.width / 2
      leaveText.y = 720 - 44 - 20

      leaveButton.interactive = true

      leaveButton.on 'pointerdown', () =>
        @game.popScene()

      @stage.addChild leaveButton
      @stage.addChild leaveText

      @stage.addChild itemContainer

function makeBallInventory(ball: Ball)
  inventoryContainer := new Container

  ball.items.forEach (item, i) =>
    itemGfx := new Graphics()
      .rect 0, 0, 44, 44
      .fill 0x00ff00
    itemGfx.x = 50 + i * 50
    itemGfx.y = 0
    inventoryContainer.addChild itemGfx

    icon := new Sprite Texture.from item.icon
    icon.anchor.set(0.5)
    icon.scale.set(2)

    icon.x = 50 + i * 50 + 22
    icon.y = 0 + 22

    inventoryContainer.addChild icon

  // Add ball character and name
  ballSprite := new Sprite
    texture: ball.texture,
    anchor: 0.5
    scale: 2

  ballSprite.x = 25
  ballSprite.y = 22
  inventoryContainer.addChild ballSprite

  return inventoryContainer
