{ Game } from ./game.civet

main := =>
  game := new Game
  await game.init()

  { canvas } := game.app
  document.body.appendChild canvas

  // release when pressing space
  window.addEventListener 'keydown', (e) =>
    {key} := e

    switch key
      "F11"
        e.preventDefault()
        if document.fullscreenElement
          document.exitFullscreen()
        else
          game.app.canvas.requestFullscreen()

      ' '
        game.action1()

  // release when clicking
  window.addEventListener 'pointerdown', (e) =>
    if e.defaultPrevented
      return

    for el of e.composedPath()
      if el <? HTMLElement and (el.tagName is "INPUT" or el.tagName is "TEXTAREA")
        return

    pos := getPos(e)
    game.pointerMove(e, pos)
    game.pointerDown(e, pos)
    game.action1()

  // aim with mouse
  window.addEventListener 'pointermove', (e) =>
    game.pointerMove(e, getPos(e))

  window.addEventListener 'pointerup', (e) =>
    game.pointerUp(e, getPos(e))

  window.addEventListener 'contextmenu', (e) =>
    e.preventDefault()

  function getPos(e: PointerEvent)
    // get angle from center top of canvas to mouse
    { top, left, width, height } := canvas.getBoundingClientRect()

    x := (e.clientX - left) / (width / game.width)
    y := (e.clientY - top) / (height / game.height)

    return {x, y}

// get query param
const urlParams = new URLSearchParams(window.location.search)

switch urlParams.get('exp')
  'bg'
    import './exp/bg-pattern.civet'
  'editor'
    import './exp/editor2.civet'
  'img'
    import './exp/img.civet'
  'phys'
    import './exp/phys.civet'
  else
    main()
