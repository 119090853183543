{
  BitmapText
  Container
  Graphics
  Rectangle
  type FederatedPointerEvent
} from pixi.js

{ floor } := Math

type ButtonProperties
  text: string
  x: number
  y: number
  width: number
  height: number
  fontSize?: number
  onSelect: (e: FederatedPointerEvent) =>

export function Button(properties: ButtonProperties)
  { text, x, y, width, height, onSelect, fontSize=16 } := properties

  button := new Container
    cursor: "pointer"
    interactive: true
    interactiveChildren: false
    hitArea: new Rectangle(0, 0, width, height)
    x: x
    y: y

  button.on "pointerdown", (e) =>
    console.log("button pointerdown", e)
    return if e.defaultPrevented
    e.preventDefault()

    onSelect(e)

  border := new Graphics()
  .rect 0, 0, width, height
  .fill 0x000000
  .stroke
    width: 2
    color: 0xffffff

  bitmapText := new BitmapText
    text: text
    style: {
      fontFamily: "m5x7"
      fontSize
    }
    interactive: false
    x: 10

  bitmapText.y = floor((height - bitmapText.height) / 2)

  button.addChild border
  button.addChild bitmapText

  return button
