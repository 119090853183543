Observable from @danielx/observable
Jadelet from jadelet

type Option = {
  name: string
  trigger: () => void
}

// TODO: .jadelet files have observable mismatch (maybe cjs vs esm?)
// so they need to be inlined to get observables to trigger right
PaletteTemplate := Jadelet.exec ```
  form.palette(@submit)
    input(@value)
    @options
```

OptionTemplate := Jadelet.exec ```
  .option(@class @click @mousemove) @name
```

export present := (options: Option[]) =>
  palette := Palette options

  document.body.appendChild palette

  palette

export default Palette := (options: Option[]) =>
  value := Observable ""
  /** Options filtered by value */
  filteredOptions := ->
    v := value()

    options.filter (option) =>
      option.name.toLowerCase().includes v.toLowerCase()

  activeOption := Observable filteredOptions()[0]

  value.observe (v) =>
    activeOption(filteredOptions()[0])

  submit := (e: Event) =>
    e.preventDefault()

    if option := activeOption()
      close()
      option.trigger()

  model := {
    submit

    value
    options: ->
      a := activeOption()

      filteredOptions()
      .map (option) =>
        OptionTemplate
          name: option.name
          class: "active" if option is a
          mousemove: (e: MouseEvent) =>
            e.preventDefault()
            activeOption(option)
          click: (e: MouseEvent) =>
            activeOption(option)
            submit(e)
  }

  el: HTMLElement := PaletteTemplate model

  keydownHandler := (e: KeyboardEvent) =>
    switch e.key
      "ArrowDown"
        e.preventDefault()
        options := filteredOptions()
        activeOptionIndex := options.indexOf activeOption()
        activeOption(options[activeOptionIndex + 1] or options[0])
      "ArrowUp"
        e.preventDefault()
        options := filteredOptions()
        activeOptionIndex := options.indexOf activeOption()
        activeOption(options[activeOptionIndex - 1] or options[options.length - 1])
      "Escape"
        e.preventDefault()
        close()

  el.addEventListener 'keydown', keydownHandler
  setTimeout =>
    el.querySelector('input')!.focus()

  close := () =>
    el.remove()

  return el
