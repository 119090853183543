{
  Container
  Graphics
  BitmapText
  Rectangle
  Sprite
  Texture
} from pixi.js

type { Ball } from ../ball.civet
type { Game, GameState } from ../game.civet
type { Item } from ../item.civet
{ shuffle } from ../util.civet

{ StarEffect } from ../fx/star.civet

{
  PI
  abs
  min
  sin
} := Math

TAU := PI * 2

export class Reward
  stage := new Container
  //@ts-expect-error set in enter
  state: GameState

  game: Game

  items: Item[]
  numChoices: number
  next: () => void

  update(_dt: number): void
  draw(_t: number): void

  enter(@state: GameState): void
    @stage.scale = 2
    if @state.balls.every .xpProgress < 1
      @next()

    @levelUpScreen @state.balls

  exit(): GameState
    @stage.removeChildren()
    @state

  pointerMove(_pos: unknown): void
  action1(): void

  @(@game: Game, @items: Item[], @numChoices: number, @next: =>)

  itemPickScreen(ball: Ball, items: Item[], numChoices: number, next: =>)
    width := numChoices * 120 + 20

    choices := shuffle(items.slice()).slice(0, numChoices)

    choicesCard := new Container

    choicesCard.x = 320
    choicesCard.y = 180
    choicesCard.zIndex = 1

    background := new Graphics()
      .rect -width/2, -120, width, 240
      .fill 0x000000
      .stroke width: 2, color: 0xffffff
    choicesCard.addChild background

    chooseText := new BitmapText
      text: "Choose an item"
      style:
        fontFamily: "m5x7"
        fontSize: 30
      x: 0
      y: -80
    chooseText.anchor.set(0.5)
    choicesCard.addChild chooseText

    choices.forEach (item, i) =>
      itemChoice := new Container

      itemChoice.x = i * 120 - width / 2 + 70
      itemChoice.y = 0

      background := new Graphics()
        .rect -50, -40, 100, 80
        .fill item.tint
        .stroke width: 2, color: 0xffffff
      background.alpha = 0.75

      itemChoice.addChild background

      icon := new Sprite Texture.from item.icon
      icon.anchor.set(0.5)
      icon.scale.set(2)
      itemChoice.addChild icon

      itemChoice.addChild new BitmapText
        text: item.name
        style:
          fontFamily: "m5x7"
          fontSize: 15
        x: -36
        y: -36

      statLines := new BitmapText
        // HACK: why does \n create a weird character at the start of the line?
        text: item.statText.replace(/\n/g, "\r")
        style:
          fontFamily: "m5x7"
          fontSize: 15
        x: -36
        y: 40

      itemChoice.addChild statLines

      itemChoice.interactive = true

      itemChoice.on 'pointerdown', (e) =>
        e.preventDefault()
        ball.addItem item
        next()

      choicesCard.addChild itemChoice

    return choicesCard

  levelUpScreen = (balls: Ball[]) =>
    width := balls.length * 120 + 20
    levelUpCard := new Container

    levelUpCard.x = 320
    levelUpCard.y = 180
    levelUpCard.zIndex = 1

    background := new Graphics()
      .rect -width/2, -120, width, 240
      .fill 0x000000
      .stroke width: 2, color: 0xffffff
    levelUpCard.addChild background

    levelUpText := new BitmapText
      text: "Level up!"
      style:
        fontFamily: "m5x7"
        fontSize: 30
      x: 0
      y: -100
    levelUpText.anchor.set(0.5)
    levelUpCard.addChild levelUpText

    balls.forEach (ball, i) =>
      ballCard := new Container

      ballCard.x = i * 120 - 120
      ballCard.y = 0

      bgEffect := StarEffect(100, 80, 7, 5)
      background := bgEffect.container
      background.x = -50
      background.y = -40
      background.alpha = 0.75

      lastTime .= performance.now() / 1000
      background.onRender = (): void =>
        t := performance.now() / 1000
        dt := t - lastTime
        lastTime = t
        bgEffect.update min 5/60, dt

        if ball.xp >= ball.xpToNextLevel
          background.tint = (abs(sin(t * TAU * 2 / 3) * 0.10 + 0.90) * 0xff) << 8
        else
          background.tint = 0x000000

      ballCard.addChild background

      stroke := new Graphics()
        .rect -50, -40, 100, 80
        .stroke width: 2, color: 0xffffff
      ballCard.addChild stroke

      icon := new Sprite ball.texture
      icon.anchor.set(0.5)
      icon.scale.set(2)
      ballCard.addChild icon

      ballCard.addChild new BitmapText
        text: ball.name
        style:
          fontFamily: "m5x7"
          fontSize: 15
        x: -36
        y: -36

      levelText := new BitmapText
        text: `LV ${ball.lvl}`
        style:
          fontFamily: "m5x7"
          fontSize: 15
        x: -36
        y: 20
      ballCard.addChild levelText

      xpText := new BitmapText
        text: `${ball.xp}/${ball.xpToNextLevel}`
        style:
          fontFamily: "m5x7"
          fontSize: 15
        x: -36
        y: 36
      ballCard.addChild xpText

      ballCard.interactive = true
      ballCard.hitArea = new Rectangle(-50, -40, 100, 80)
      ballCard.interactiveChildren = false
      ballCard.on 'pointerdown', (e) =>
        e.preventDefault()

        if ball.xp >= ball.xpToNextLevel
          ball.xp -= ball.xpToNextLevel
          ball.lvl += 1

          xpText.text = `${ball.xp}/${ball.xpToNextLevel}`
          levelText.text = `LV ${ball.lvl}`

          // TODO animate level up
          pickScreen := @itemPickScreen ball, @items, @numChoices, =>
            @stage.removeChild pickScreen

            if balls.every .xpProgress < 1
              @stage.removeChild levelUpCard
              @next()

          @stage.addChild pickScreen

      levelUpCard.addChild ballCard

    @stage.addChild levelUpCard
