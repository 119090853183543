{ Filter, GlProgram, Sprite, Texture } from pixi.js

fragment from ./custom.frag
vertex from ./custom.vert

/**
Experimenting with custom shaders
*/
export filter := new Filter
  glProgram: new GlProgram {
    fragment
    vertex
  }
  resources:
    timeUniforms:
      uTime: { value: 0.0, type: 'f32' },
