{
  type Vec2
  bounce
  circularCollision
  noop
  physicsUpdate
  rand
} from ./util.civet

{
  addBlast
  addStreak
  gfxTextures
} from ./display.civet
type { Ball } from ./ball.civet
type { Level, PegData } from ./scenes/level.civet
{
  Sprite
  Texture
  type PointData
} from pixi.js

{ calcSpread } from ./item.civet

{
  PI
  atan2
  cos
  floor
  max
  min
  random
  sign
  sin
  sqrt
} := Math

TAU := PI * 2

export type ProjectileBase =
  x: number
  y: number
  radius: number
  source: Ball
  onHitPeg: (level: Level, peg: PegData, collision: Vec2) => void

export class Projectile implements ProjectileBase
  source: Ball
  x = 0
  y = 0
  radius = 8
  rotation = 0
  rotationVelocity = 0
  velocity =
    x: 0
    y: 0
  gravity =
    x: 0
    y: 0

  age = 0
  active = true
  expires = 10

  alpha = 1
  spriteRotationOffset = 0
  spriteScale: PointData | number = 1
  rotationTracksVelocity = false

  animationTimeOffset = 0
  textures: Texture[] = []
  fps = 24

  collides = true

  onUpdate: (this: Projectile, level: Level, dt: number) => void = noop
  onExpire = noop
  onHitWall = noop
  onHitPeg: (this: Projectile, level: Level, peg: PegData, collision: Vec2) => void = noop
  onBallCollision = noop

  @(props: Partial<Projectile> & { source: Ball })
    let otherProps: Partial<Projectile>
    { @source, ...otherProps } = props

    Object.keys(otherProps).forEach (key) =>
      // @ts-expect-error TODO: gate based on key
      @[key] = props[key]

  update(level: Level, dt: number): void
    @onUpdate.call(@, level, dt)

    if @age >= @expires
      @onExpire.call(@)
      @active = false

    return unless @active

    hitWall := physicsUpdate(@, dt)
    if hitWall
      @onHitWall.call(@)

    if @active and @collides
      level.handlePegCollisions(@)

    @age += dt

  draw(container: Sprite): void
    container.x = @x
    container.y = @y

    rotation := if @rotationTracksVelocity
      atan2(@velocity.y, @velocity.x)
    else
      @rotation

    container.scale = @spriteScale
    container.rotation = rotation + @spriteRotationOffset
    container.alpha = @alpha

    // texture animation
    frame := floor((@age + @animationTimeOffset) * @fps) % @textures.length
    container.texture = @textures[frame]

  get container(): Sprite
    new Sprite
      texture: @textures[0]
      anchor: { x: 0.5, y: 0.5 }

export function knifeSpawn(level: Level, source: Ball): void
  { x, y } := source
  { x: vx, y: vy } := source.velocity
  m := sqrt vx * vx + vy * vy

  speed := 800

  level.addProjectile new Projectile {
    source
    x
    y
    velocity: {
      x: speed * vx / m
      y: speed * vy / m
    }
    rotationTracksVelocity: true
    spriteRotationOffset: 3 * PI / 4
    spriteScale: 2
    textures: [Texture.from("knife_kitchen.png")]
    onHitWall: ->
      @active = false

    onHitPeg: (level: Level, peg: PegData, collision: Vec2) ->
      switch peg.type
        when 'peg'
          @active = false
  }

export function bubbleSpawn(level: Level, source: Ball): void
  { x, y } := source

  data := {
    source
    x
    y
    age: 0
    active: true
    warmup: 0.5
    expires: 10
    velocity:
      x: 0
      y: -20
    radius: 10
    spriteScale: 0
    dc: 0
    textures: [ gfxTextures.hollowCircle ]
    onUpdate: (level: Level) ->
      // Randomly change x direction every so often
      if @dc <= 0
        @dc = (rand 11) / 10
        @velocity.x = (rand(2) - 0.5) * 10

      @spriteScale = min 1, @age / @warmup

      return if @age < @warmup

      for each ball of level.activeBalls
        // Bounce ball up on bubbles
        if collision := circularCollision(@x, @y, @radius, ball.x, ball.y, ball.radius)
          if ball.velocity.y > 0 and ball.y < @y
            ball.velocity.y = -ball.velocity.y - 10
            @active = false

      return

    onHitPeg: (level: Level, peg: PegData, collision: Vec2) ->
      switch peg.type
        when 'peg'
          @active = false
  }

  level.addProjectile new Projectile data

export function lightningSpawn(level: Level, source: Ball): void
  x := rand(level.width)
  y := -20
  radius := 1000

  closestCollision .= null
  hitPeg .= null

  for peg of level.pegs
    if peg.type is 'ghost'
      continue

    if collision := circularCollision(x, y, radius, peg.x, peg.y, peg.r)
      if !closestCollision or collision.t < closestCollision.t
        closestCollision = collision
        hitPeg = peg

  if closestCollision
    level.hitPeg hitPeg!, { source, x, y, radius, onHitPeg: noop }, closestCollision

    addStreak level.effectContainer, {
      rotation: atan2(closestCollision.y, closestCollision.x) + PI
      scale: {x: closestCollision.t, y: 2}
      tint: 0xffff00
      x
      y
    }

export function flameThrowerSpawn(level: Level, source: Ball): void
  { age, x, y, velocity, rotation } := source

  displacement := 160

  level.addProjectile new Projectile {
    source
    expires: 4 / 60
    x: x + displacement * cos(rotation)
    y: y + displacement * sin(rotation)
    radius: 16
    spriteScale: 2
    rotation: rotation
    textures: gfxTextures.flamethrowerLoop
    animationTimeOffset: age
    onUpdate: (level: Level, dt: number) ->
      if @age
        @alpha /= 2

  }

export function flameSpawn(level: Level, source: Ball): void
  { x, y, velocity } := source

  direction := sign(velocity.x) or 1

  level.addProjectile new Projectile {
    source
    x
    y
    velocity: {
      x: 500 * direction
      y: 0
    }
    spriteScale: 2
    textures: gfxTextures.flame
    onHitWall: ->
      @active = false
    onHitPeg: (level: Level, peg: PegData, collision: Vec2) ->
      switch peg.type
        when 'peg'
          @active = false

  }

export function explosiveSpawn(level: Level, source: Ball): void
  { x, y, velocity: {x: vx, y: vy} } := source

  { rotation } .= source
  rotation += -PI + calcSpread(0.125)
  launchVelocity := 100

  level.addProjectile new Projectile {
    source
    x
    y
    expires: 3
    rotation: random() * TAU
    velocity: {
      x: vx + cos(rotation) * launchVelocity
      y: vy + sin(rotation) * launchVelocity
    }
    gravity: { x: 0, y: 360 }
    spriteScale: 2
    textures: [Texture.from "banana.png"]

    onExpire: (this: Projectile) ->
      explosion(level, @source, @, 100)

    onHitPeg: (this: Projectile, level: Level, peg: PegData, collision: Vec2) ->
      // TODO: peg.solid?
      switch peg.type
        when 'peg'
          pos := { x: @x + collision.x, y: @y + collision.y }
          level.playSoundAt "boing", pos, 3

          bounce(@, collision)
  }

function explosion(level: Level, source: Ball, pos: Vec2, radius: number)
  level.handlePegCollisions {
    x: pos.x
    y: pos.y
    radius
    source
    onHitPeg: noop
  }

  addBlast level.effectContainer, pos.x, pos.y, radius, 0xff0000
