{
  Container
  Graphics
  type FederatedPointerEvent
} from pixi.js

{ type Scene } from ./scene.civet

{
  circleContext
  lineContext
} from ../display.civet

{ present } from ../views/palette.civet

type { Tool as EditTool, GameObject } from ./editor/object-editor.civet
{ calcCollisions, ObjectEditor } from ./editor/object-editor.civet

type { GameState } from ../game.civet

{ Button } from ../ui.civet

{
  dist
  arcCircleIntersection
  circularCollision
  rayCircleIntersection
  type Vec2
} from ../util.civet

{
  playMusic
} from ../audio.civet

type LevelData = {
  name: string
  objects: GameObject[]
}

interface Tool
  down(pos: Vec2, editor: Editor): void
  move(pos: Vec2, editor: Editor): void
  up(pos: Vec2, editor: Editor): void

{ PI } := Math
TAU := 2 * PI

nullTool :=
  down: =>
  move: =>
  up: =>

objectBases := [
  { type: "circle", x: 0, y: 0, r: 12 }
  { type: "arcCollider", x: 0, y: 0, r: 100, startAngle: -TAU / 16, endAngle: TAU / 16 }
  { type: "circleCollider", x: 0, y: 0, r: 100 }
  { type: "rayCollider", x: 0, y: 0, r: 100, angle: 0 }
] as const

createTool := {
  object: objectBases[0] as GameObject
  down({x, y}: Vec2, {objects, displayObjects, attachEditor, stage}: Editor)
    obj := { ...@object, x, y }

    attachEditor obj

    objects.push obj

    display := makeDisplay obj
    displayObjects.set obj, display

    stage.addChild display
  move() {}
  up() {}
}

editTool := {
  down({x, y}: Vec2, {objects, displayObjects, attachEditor, stage}: Editor)
    :l for obj of objects
      switch obj.type
        when "rayCollider"
          if rayCircleIntersection obj, obj.angle, { x, y, r: 3 }
            if dist(obj, { x, y }) < obj.r
              attachEditor obj
              break l
        when "circleCollider", "circle"
          if circularCollision(obj.x, obj.y, obj.r, x, y, 3)
            attachEditor obj
            break l
        when "arcCollider"
          if arcCircleIntersection obj, { x, y, r: 3 }
            attachEditor obj
            break l
  move() {}
  up() {}
}

let activeDrag: GameObject?

moveTool := {
  down({x, y}: Vec2, {objects, displayObjects, attachEditor, stage}: Editor): void
    activeTool = moveTool
    :l for obj of objects
      switch obj.type
        when "rayCollider"
          if rayCircleIntersection obj, obj.angle, { x, y, r: 3 }
            if dist(obj, { x, y }) < obj.r
              activeDrag = obj
              break l
        when "circleCollider", "circle"
          if circularCollision(obj.x, obj.y, obj.r, x, y, 3)
            activeDrag = obj
            break l
        when "arcCollider"
          if arcCircleIntersection obj, { x, y, r: 3 }
            activeDrag = obj
            break l
  move({x, y}: Vec2, {objects, displayObjects, updateDisplay, stage}: Editor): void
    if activeDrag
      activeDrag.x = x
      activeDrag.y = y
      updateDisplay activeDrag

  up(): void
    activeDrag = undefined
    activeTool = undefined
}

tools := [
  { name: "Create", tool: createTool }
  { name: "Move", tool: moveTool }
  { name: "Edit", tool: editTool }
] as const

type Tools = typeof tools

let activeTool: Tool?

export class Editor implements Scene
  stage = new Container
  //@ts-expect-error set in enter
  state: GameState

  displayObjects = new Map<GameObject, Container>()
  levels = getLevelData()
  level = @levels[0]
  objects = @level.objects

  updateDisplay = (obj: GameObject) =>
    if display := @displayObjects.get obj
      @stage.removeChild display
      newDisplay := makeDisplay obj
      @displayObjects.set obj, newDisplay
      @stage.addChild newDisplay

  deleteObject = (obj: GameObject) =>
    if display := @displayObjects.get obj
      @stage.removeChild display
    @objects.splice @objects.indexOf(obj), 1
    @displayObjects.delete obj

  objectEditor = ObjectEditor(@objects, @displayObjects, @updateDisplay, @deleteObject)
  attachEditor = @objectEditor.attach

  primaryTool: Tool = createTool
  secondaryTool: Tool = editTool

  toolSelector = ToolSelector(tools, (e, tool) =>
    switch e.button
      when 0
        @primaryTool = tool
        return 0xff0000
      when 2
        @secondaryTool = tool
        return 0x0000ff
    return 0xffffff
  , (tool) =>
    if tool is @primaryTool
      if tool is @secondaryTool
        return 0xff00ff
      return 0xff0000
    if tool is @secondaryTool
      return 0x0000ff
    return 0xffffff
  )

  tools: Tool[] = []

  @()
    @stage.addChild @objectEditor.container

    @stage.addChild @toolSelector

    addEventListener 'beforeunload', (e: BeforeUnloadEvent) =>
      @save()

    addEventListener 'keydown', (e: KeyboardEvent) =>
      let handled = false
      if e.metaKey or e.ctrlKey
        switch e.key
          when "z"
            handled = true
            if e.shiftKey
              console.log 'redo'
            else
              console.log 'undo'
          when "+"
            handled = true
            name := prompt "Level Name", "New Level"
            if name
              if @levels.find(.name is name)
                alert "Level already exists"
              else
                level := { name, objects: [] }
                @levels.push level
                @load name
          when "s"
            handled = true
            @save()
          when "o"
            handled = true
            present @levels.map {name} => {
              name
              trigger: =>
                @load name
            }
          when "e"
            handled = true
            @export()


      if handled
        e.preventDefault()

    @resetDisplay()

  update(dt: number): void
  draw(t: number): void

  enter(gameState: GameState): void
    @state = gameState

    playMusic ""

  exit(): GameState
    @state

  pointerDown(e: PointerEvent, pos: Vec2): void
    console.log "editor pointerDown"

    if e.defaultPrevented
      return

    if tool := switch e.button
        when 0
          @primaryTool
        when 2
          @secondaryTool

      e.preventDefault()
      tool.down(pos, @)

  pointerMove(e: PointerEvent, pos: Vec2): void
    if e.defaultPrevented
      return
    activeTool?.move pos, @

  pointerUp(e: PointerEvent, pos: Vec2): void
    if e.defaultPrevented
      return
    activeTool?.up pos, @

  action1(): void

  resetDisplay()
    @objectEditor.detach()
    @displayObjects.forEach (display) =>
      @stage.removeChild display
    @displayObjects.clear()

    for each object of @objects
      display := makeDisplay object
      @displayObjects.set object, display
      @stage.addChild display

  save()
    setLevelData @levels

  load(levelName: string)
    @level = @levels.find(.name is levelName) ?? @levels[0]
    @objects = @level.objects

    @resetDisplay()

  export()
    // copy to clipboard
    navigator.clipboard.writeText JSON.stringify @objects

function makeDisplay(object: GameObject)
  { x, y, r, type } := object
  container := new Container

  container.addChild switch type
    when "circle"
      new Graphics
        context: circleContext
        scale: r / 64
        x: x
        y: y
    when "arcCollider"
      new Graphics({ x, y, tint: 0xff00ff, alpha: 0.5 })
        .circle 0, 0, r
        .fill "transparent"
        .arc 0, 0, r, object.startAngle, object.endAngle
        .fill 0xffffff
    when "circleCollider"
      new Graphics {
        context: circleContext
        alpha: 0.5
        scale: r / 64
        tint: 0xff00ff
        x
        y
      }
    when "rayCollider"
      new Graphics {
        context: lineContext
        alpha: 0.5
        scale: {x: r, y: 1 }
        tint: 0xff00ff
        rotation: object.angle
        x
        y
      }

  return container

function ToolSelector (tools: Tools, onSelect: (e: FederatedPointerEvent, tool: Tool) => void, tintFor: (tool: Tool) => number)
  container := new Container
  container.x = 10
  container.y = 10

  buttons := tools.map (tool, i) =>
    x := 0
    y := i * 42
    button := Button {
      text: tool.name
      x
      y
      width: 100
      height: 40
      onSelect: (e) =>
        onSelect e, tool.tool

        updateTints()
    }

    button.on "pointerdown", (e) =>
      if e.button is 2
        console.log("debug", button.children[1])

    container.addChild button

    button

  // create object selector sub-buttons
  for each base, i of objectBases
    button := Button {
      text: base.type
      x: 120 + i * 44
      y: 0
      width: 40
      height: 40
      onSelect: (e) =>
        console.log(e, base)
        tools[0].tool.object = base
    }

    mask := new Graphics()
      .rect 0, 0, 40, 40
      .fill(0x000000)
    button.mask = mask
    button.addChild mask

    disp := makeDisplay base
    disp.scale.set(0.5)
    disp.x = 20
    disp.y = 20
    button.addChild disp
    container.addChild button

  updateTints := =>
    for each button, i of buttons
      button.tint = tintFor(tools[i].tool)
  updateTints()

  return container

dataKey := "stay-pegged:custom-levels"
export function getLevelData()
  levels: LevelData[] := JSON.parse localStorage.getItem(dataKey) ?? "[]"

  if levels.length is 0
    levels.push { name: "New Level", objects: [] }

  return levels

function setLevelData(levels: LevelData[])
  localStorage.setItem dataKey, JSON.stringify levels
