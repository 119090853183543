{
  Container
  Graphics
  type FederatedPointerEvent
} from pixi.js

{
  type LevelData
  type PegData
  makePegGraphic
} from ../../scenes/level.civet

{
  dist
  arcCircleIntersection
  circularCollision
  rayCircleIntersection
} from ../../util.civet

{
  circleContext
  lineContext
} from ../../display.civet

{
  PI
  atan2
  cos
  sin
} := Math

TAU := 2 * PI

export type Tool = {
  type: "create"
  object: GameObject
} | {
  type: "edit"
}

export type GameObject =
  | { type: "circle", x: number, y: number, r: number }
  | { type: "arcCollider", x: number, y: number, r: number, startAngle: number, endAngle: number }
  | { type: "circleCollider", x: number, y: number, r: number }
  | { type: "rayCollider", x: number, y: number, r: number, angle: number }

ControlPoint := (color: number, move: (e: FederatedPointerEvent) =>) =>
  control := new Graphics()
    .circle 0, 0, 8
    .fill color
    .stroke 0xffffff

  control.interactive = true
  control.on 'pointerdown', (e) =>
    e.preventDefault()

    // Use the root to capture pointermove events outside the control
    root .= e.currentTarget
    while root.parent
      root = root.parent

    root.on 'pointermove', move
    document.addEventListener 'pointerup', =>
      root.off 'pointermove', move

  control

export function ObjectEditor(
  objects: GameObject[]
  displayObjects:Map<GameObject, Container>
  updateDisplay: (object: GameObject) => void
  deleteObject: (object: GameObject) => void
)
  container := new Container
  container.zIndex = 1
  container.visible = false

  let source: GameObject?

  posControl := ControlPoint 0x000088, (e: FederatedPointerEvent) =>
    { x, y } := e.global
    posControl.x = x
    posControl.y = y

    if source
      dx := x - source.x
      dy := y - source.y

      distControl.x += dx
      distControl.y += dy

      source.x = x
      source.y = y

      updateDisplay source
      calcCollisions(objects, displayObjects)

  distControl := ControlPoint 0x880000, (e: FederatedPointerEvent) =>
    { x, y } := e.global
    distControl.x = x
    distControl.y = y

    if { type } := source
      switch type
        "circleCollider", "circle"
          source.r = dist(source, e.global)

          updateDisplay source
          calcCollisions(objects, displayObjects)

        "rayCollider"
          source.r = dist source, e.global
          source.angle = atan2(y - source.y, x - source.x)

          updateDisplay source
          calcCollisions(objects, displayObjects)

        "arcCollider"
          source.r = dist(source, e.global)
          angle := atan2(y - source.y, x - source.x)
          source.startAngle = angle - TAU / 16
          source.endAngle = angle + TAU / 16

          updateDisplay source
          calcCollisions(objects, displayObjects)

  container.addChild posControl
  container.addChild distControl

  detach := =>
    source = undefined
    container.visible = false

  document.addEventListener 'keydown', (e) =>
    if e.key is "Delete" and source
      deleteObject source
      detach()

  return {
    attach: (object: GameObject): void =>
      container.visible = true
      source = object

      { x, y, r, type } := object
      posControl.x = x
      posControl.y = y

      angle := switch type
        "rayCollider"
          object.angle
        "arcCollider"
          (object.startAngle + object.endAngle) / 2
        "circleCollider", "circle"
          0
        else
          0

      distControl.x = x + r * cos angle
      distControl.y = y + r * sin angle

    detach

    container
  }

isCircle := (object: GameObject): object is { type: "circle" } & GameObject => object.type is "circle"

export function calcCollisions(objects: GameObject[], displayObjects: Map<GameObject, Container>)
  circles := objects.filter isCircle
  colliders := objects.filter .type is not "circle"

  for each circle of circles
    if display := displayObjects.get circle
      display.tint = 0xffffff

      for each collider of colliders
        switch collider.type
          when "arcCollider"
            if arcCircleIntersection collider, circle
              display.tint = 0x00ff00

          when "circleCollider"
            { x: x1, y: y1, r: r1 } := collider
            { x: x2, y: y2, r: r2 } := circle
            if circularCollision(x1, y1, r1, x2, y2, r2)
              display.tint = 0x00ff00

          when "rayCollider"
            if { t } := rayCircleIntersection collider, collider.angle, circle
              if t < collider.r
                display.tint = 0x00ff00
