export type Vec2 = { x: number, y: number }
export type Circle = { x: number, y: number, r: number }

export dt := 1 / 60 // seconds

{
  PI
  atan2
  cos
  floor
  max
  min
  random
  sin
  sqrt
} := Math

export function noop() {}

export function rand(n: number)
  return floor random() * n

export function randomItem<T>(arr: T[])
  return arr[rand arr#]

export function clamp(n: number, low: number, high: number)
  return min max(n, low), high

export function exists<T>(value: T): value is NonNullable<T>
  value !== null and value !== undefined

/**
* Returns the distance between two points.
*/
export function dist({ x: x1, y: y1 }: Vec2, { x: x2, y: y2 }: Vec2)
  dx := x2 - x1
  dy := y2 - y1

  return sqrt(dx * dx + dy * dy)

/**
* Returns the collision normal from circle 2 to circle 1 if they are colliding.
*/
export function circularCollision(x1: number, y1: number, r1: number, x2: number, y2: number, r2: number)
  dx := x1 - x2
  dy := y1 - y2

  magnitude := Math.sqrt(dx * dx + dy * dy)

  if magnitude < r1 + r2
    return { x: dx / magnitude, y: dy / magnitude, t: magnitude }

  else
    return false as const

/**
https://en.wikipedia.org/wiki/Projectile_motion#Angle_%CE%B8_required_to_hit_coordinate_(x,_y)

NOTE: had to negate g and add PI to the result to get the correct angle
*/
export function angleOfReach(source: Vec2, target: Vec2, v: number, g: number)
  { x: x1, y: y1 } := source
  { x: x2, y: y2 } := target

  g = -g
  x := x2 - x1
  y := y2 - y1

  v2 := v * v

  // discriminant
  D := v2 * v2 - g * (g * x * x + 2 * y * v2)

  if D < 0
    // TODO: Is there a way to get the closest angle using arctan of the complex number?
    return false

  return atan2(v2 - sqrt(D), g * x) + PI

export function rayCircleIntersection<T extends Circle>(source: Vec2, angle: number, circle: T): false | { x: number, y: number, t: number, c: T }
  { x: x1, y: y1 } := source
  { x: x2, y: y2, r: radius } := circle

  dx := x2 - x1
  dy := y2 - y1

  vx := cos(angle)
  vy := sin(angle)

  p := dx * vx + dy * vy

  if p < 0
    return false

  q := dx * dx + dy * dy - p * p

  if q > radius * radius
    return false

  t := p - sqrt(radius * radius - q)

  return { x: x1 + vx * t, y: y1 + vy * t, t, c: circle }

export type ArcCollider = { x: number, y: number, r: number, startAngle: number, endAngle: number }

export function arcCircleIntersection<T extends Circle>(source: ArcCollider, circle: T): false | { t: number, c: T }
  { x: sourceX, y: sourceY, r: radius, startAngle, endAngle } := source

  b1x := cos(startAngle)
  b1y := sin(startAngle)

  x := circle.x - sourceX
  y := circle.y - sourceY
  r := circle.r

  d1 := -b1y * x + b1x * y

  b2x := cos(endAngle)
  b2y := sin(endAngle)

  d2 := -b2y * x + b2x * y

  if d1 > -r and d2 < r
    dist := sqrt(x * x + y * y)

    if dist < r + radius
      return { t: dist - r, c: circle }

  return false

/**
* Shuffles an array in place.
*/
export function shuffle<T>(array: T[])
  i .= array# - 1
  while i > 0
    j := rand(i + 1)
    [array[i], array[j]] = [array[j], array[i]]
    i--

  return array

type PhysicsObject
  x: number
  y: number
  radius: number
  velocity: { x: number, y: number }
  gravity: { x: number, y: number }
  rotation: number
  rotationVelocity: number

export function bounce(source: PhysicsObject, collision: Vec2)
  // Standard collision
  dot := source.velocity.x * collision.x + source.velocity.y * collision.y
  // Skip if the ball is already moving in the same direction as the normal
  if dot > 0
    return
  cross := source.velocity.x * collision.y - source.velocity.y * collision.x

  source.rotationVelocity = -cross / 60

  restitution := 0.95
  source.velocity.x -= 2 * dot * collision.x * restitution
  source.velocity.y -= 2 * dot * collision.y * restitution

  // move the ball out of the peg
  source.x += collision.x
  source.y += collision.y

export function physicsUpdate(source: PhysicsObject, dt: number)
  source.velocity.x += source.gravity.x * dt
  source.velocity.y += source.gravity.y * dt
  source.x += source.velocity.x * dt
  source.y += source.velocity.y * dt

  source.rotation += source.rotationVelocity * dt

  { radius: r } := source

  hitWall .= false

  // Side wall collisions
  if source.x < r and source.velocity.x < 0
    source.x = r
    source.velocity.x = -source.velocity.x
    hitWall = true
  if source.x > 1280 - r and source.velocity.x > 0
    source.x = 1280 - r
    source.velocity.x = -source.velocity.x
    hitWall = true

  return hitWall

function plotParabola(source: Vec2, angle: number, v: number, gravity: Vec2)
  velocity := { x: cos(angle) * v, y: sin(angle) * v }
  steps := 200
  t .= 0
  i .= 0

  { x, y } .= source

  results :=
    xs: new Float32Array steps
    ys: new Float32Array steps
    length: steps

  while i++ < steps
    t += dt
    velocity.x += gravity.x * dt
    velocity.y += gravity.y * dt
    x += velocity.x * dt
    y += velocity.y * dt

    results.xs[i] = x
    results.ys[i] = y

  return results
