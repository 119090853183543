{
  BitmapText
  Container
  Graphics
  Sprite
  Texture
  type TextOptions
  Cache
} from pixi.js

type { Game } from ../game.civet
type { Scene } from ./scene.civet

{ Button, ShadowedText } from ../ui.civet
{ Settings } from ./settings.civet
{ WorldMap } from ./world-map.civet

export class SpriteTest implements Scene
  stage := new Container
  game: Game

  update(_dt: number): void
  draw(_t: number): void

  enter(): void
    @game.playMusic ""

  pointerMove(_pos: unknown): void
  action1(): void

  @(@game: Game)
    //@ts-ignore
    cache := Cache._cache as Map<string, unknown>

    x .= 0
    y .= 0
    cache.forEach (value, key) =>
      if value instanceof Texture
        console.log key, value

        s := new Sprite value
        s.x = x
        s.y = y
        @stage.addChild s

        x += 32
        if x >= 1280
          x = 0
          y += 32
