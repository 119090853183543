{
  BitmapText
  Container
  Graphics
  Sprite
  Texture
} from pixi.js

{ Reward } from ./reward.civet
{ items: itemRewards } from ../item.civet

{ filter } from ../filter.civet
{ Ball } from ../ball.civet
{ BallSelector } from ../ball-selector.civet
{ Scoremeter } from ../scoremeter.civet

type { Projectile } from ../item.civet
type { Game, GameState } from ../game.civet
type { Scene } from ./scene.civet

{ NoiseBackground } from ../fx/noise-background.civet
{ HalftoneBackground } from ../fx/halftone-background.civet

{
  playMusic
  playSound
} from ../audio.civet

{
  circleContext
  nineSliceBorder
  updateBallGraphics
} from ../display.civet

{
  angleOfReach
  circularCollision
  dt
  rand
  shuffle
  type Vec2
} from ../util.civet

{
  PI
  cos
  floor
  min
  sin
  sqrt
} := Math

scoreTextIndex .= 0
scoreTextAges: number[] := new Array(128).fill(9999)
scoreTextPegs: PegData[] := new Array(128).fill(null)
scoreTextAmounts: number[] := new Array(128).fill(0)
scoreTextPool := [0...128].map =>
  text := new BitmapText
    text: ""
    style:
      fontFamily: "m5x7"
      fontSize: 15
    visible: false

victoryText := new BitmapText
  text: "EXTREME PEGGING!"
  style:
    fontFamily: "m5x7"
    fontSize: 30
  x: 640
  y: 360
  anchor: 0.5
  visible: false

victoryActive .= false

scoreTextRiseSpeed := 30 // pixels/s

function getScoreText(peg: PegData, amount: number)
  // Re-use and increment score if retriggered
  i .= scoreTextPegs.indexOf(peg)
  unless i >= 0 and scoreTextAges[i] < 1
    i = scoreTextIndex++ % scoreTextPool.length
    scoreTextPegs[i] = peg
    scoreTextAmounts[i] = 0

  scoreTextAges[i] = 0
  text := scoreTextPool[i]
  text.text = (scoreTextAmounts[i] += amount).toString()

  text.x = peg.x
  text.y = peg.y - 40
  text.anchor = 0.5
  text.scale = 2

  text.visible = true

  return text

function updateScoreTexts(dt: number): void
  for age, i of scoreTextAges
    if age < 1
      scoreTextAges[i] += dt
      scoreTextPool[i].y -= dt * scoreTextRiseSpeed
    else
      scoreTextPool[i].visible = false

export class Level implements Scene
  game: Game
  balls: Ball[] = []
  level = 1

  score = 0
  targetScore = 300
  multiplierBase = 0
  get multiplier()
    switch @multiplierBase
      < 3
        1
      <= 5
        2
      >= 6
        @multiplierBase - 3
      else
        1

  pegContainer: Container := new Container
  effectContainer := new Container
    zIndex: 1

  stage := new Container

  aimDots: Graphics[] := [0...20].map =>
    dot := new Graphics circleContext
    dot.tint = 0xff0000
    dot.scale = 1/8
    dot.alpha = 0.5
    dot

  ballGraphicsPool := [0...16].map =>
    ball := new Sprite
      anchor: 0.5
      scale: 2
      visible: false

    return ball

  launchVelocity := 300 // pixels/s
  launcherPosition := { x: 640, y: 64 }

  selector: ReturnType<typeof BallSelector>

  pegs: PegData[] := []
  /** Pegs that have been activated and will be cleared during or after the current throw */
  hits: Set<PegData> := new Set
  removeHitCooldown = 10
  toRemove: Set<PegData> := new Set
  pegGraphics: Map<PegData, Graphics> := new Map

  held = true
  launchAngle = 0
  activeBalls: Ball[] = []
  spentBalls: Ball[] = []

  projectiles: Projectile[] = []
  projectileGraphics: Map<Projectile, Container> = new Map

  scoremeter: ReturnType<typeof Scoremeter>
  scoreText: BitmapText
  multiplierText: BitmapText

  noiseBg: NoiseBackground
  halftoneBg: HalftoneBackground

  age = 0
  halfDiagonal: number
  mask: Graphics

  get @game.{width, height}

  @(@game: Game)
    { width, height } := @game
    background := new Sprite
      texture: Texture.WHITE
      width: width
      height: height

    @halfDiagonal := sqrt(width * width + height * height) / 2

    // TODO: this can't be set in the constructor for some reason
    background.filters = [ filter ]

    mask := @mask = new Graphics()
      .star(0, 0, 5, 64, 32)
      .fill(0xffffff)
    mask.x = width / 2
    mask.y = height / 2

    @stage.addChild mask
    @stage.mask = mask

    @stage.addChild background

    @halftoneBg := new HalftoneBackground @game.width, @game.height
    @stage.addChild @halftoneBg.container

    @noiseBg := new NoiseBackground @game.width, @game.height
    @stage.addChild  @noiseBg.container

    @scoreText = new BitmapText
      text: "00000000"
      style:
        fontFamily: "m5x7"
        fontSize: 30
      x: width - 260
      y: 0
      scale: 2
    @stage.addChild @scoreText

    @multiplierText = new BitmapText
      text: "x1"
      style:
        fontFamily: "m5x7"
        fontSize: 30
      x: width - 116
      y: 40
      scale: 2
      tint: 0xffa500
    @stage.addChild @multiplierText

    @stage.addChild @pegContainer

    @stage.addChild(@effectContainer)

    @selector = BallSelector()
    @selector.container.scale = 2
    @stage.addChild @selector.container

    @scoremeter = Scoremeter
      Texture.from nineSliceBorder
    @scoremeter.x = 1280 - 60
    @scoremeter.y = 20
    @scoremeter.scale = 2
    @stage.addChild @scoremeter

    for each ball of @ballGraphicsPool
      @stage.addChild ball

    for each dot of @aimDots
      @stage.addChild dot

  enter = ({@balls, @level}: GameState) =>
    playMusic 'azure'

    // TODO: these are shared between levels and its akward
    scoreTextPool.forEach @effectContainer.addChild .
    @effectContainer.addChild victoryText

    // reset balls
    @balls.forEach (ball) =>
      ball.x = 320
      ball.y = 0
      ball.rotation = 0
      ball.rotationVelocity = 0
      ball.velocity.x = 0
      ball.velocity.y = 0
      ball.active = true

    // Load custom level in place of level 1 if present
    let pegs
    if @level is 1
      data := loadLevel @level - 1

      if data
        { pegs, @targetScore } = data
      else
        { pegs, @targetScore } = loadLevel @level
    else
      { pegs, @targetScore } = loadLevel @level

    @addMultiplierPegs(pegs)

    @spentBalls.length = 0

    @pegContainer.removeChildren()
    @pegs.length = 0
    @score = 0
    @multiplierBase = 0
    victoryActive = false
    victoryText.visible = false

    pegs.forEach (peg) =>
      pegGraphic := makePegGraphic peg

      @pegContainer.addChild pegGraphic
      @pegs.push peg
      @pegGraphics.set peg, pegGraphic

    @aimAt({x: @launcherPosition.x, y: @launcherPosition.y + 1})

  exit = =>
    balls: @spentBalls.filter !.ephemeral
    level: @level + 1
    money: 0 // TODO

  update(dt: number)
    @noiseBg.update dt
    @halftoneBg.update dt
    @removeHitCooldown -= dt

    if @removeHitCooldown <= 0
      it := @hits.values().next()

      if !it.done
        @removePeg it.value
        @removeHitCooldown += 0.125
      else
        @removeHitCooldown = 10

    victoryText.visible = false

    if @score >= @targetScore
      victoryText.text = "EXTREME PEGGING!"
      victoryText.visible = true
      if victoryActive is false
        victoryActive = true
        playMusic 'circle'

    @projectiles.forEach .update(@, dt)
    @projectiles.filter(!.active).forEach @removeProjectile
    @projectiles = @projectiles.filter .active

    for each ball of @activeBalls
      ball.update @, dt

    for each ball of @activeBalls
      @handlePegCollisions(ball)

      // Deactivate ball when below the playfield
      if ball.y > @game.height + 100
        ball.active = false

      for each projectile of @projectiles
        projectile.handleBallCollision(ball)

    // remove pegs
    if @toRemove.size
      @playSoundAt "boop", [...@toRemove][0]

    for peg of @toRemove
      @pegs.splice @pegs.indexOf(peg), 1
      @pegContainer.removeChild @pegGraphics.get(peg)!
    @toRemove.clear()

    // remove inactive balls
    inactiveBalls := @activeBalls.filter !.active
    for each ball of inactiveBalls
      index := @balls.indexOf ball
      if index >= 0
        @balls.splice index, 1

    @spentBalls.push ...inactiveBalls
    @activeBalls = @activeBalls.filter .active

    i .= 0
    l1 := @activeBalls.length
    l2 := @ballGraphicsPool.length

    while i < l1
      ball := @ballGraphicsPool[i]
      updateBallGraphics @activeBalls[i++], ball
    while i < l2
      @ballGraphicsPool[i++].visible = false

    if @held
      if @currentBall
        @aimDots.forEach .visible = true

        @currentBall.x = @launcherPosition.x
        @currentBall.y = @launcherPosition.y

        updateBallGraphics @currentBall, @ballGraphicsPool[0]
      else if !victoryActive
        victoryText.text = "NO BALLS"
        victoryText.visible = true
    else
      @aimDots.forEach .visible = false

    unless @activeBalls.length
      @resolveThrow()

    @age += dt

  draw(t: number)
    filter.resources.timeUniforms.uniforms.uTime = t

    @projectileGraphics.forEach (container, projectile) =>
      projectile.draw(container as Graphics)

    updateScoreTexts(dt)

    victoryText.scale.x = 2 + sin(t * 10) * 0.1
    victoryText.scale.y = 2 + sin(t * 10) * 0.1

    @scoreText.text = @score.toString().padStart(8, "0")
    @multiplierText.text = "x" + @multiplier.toString()
    @scoremeter.value = @score / @targetScore

    @selector.updateTextures @balls

    t1 := @age
    q := min(t1 * t1 * t1, 1)
    @mask.rotation = -min(t1, 1) * PI * 2
    @mask.scale = q * @halfDiagonal / 32

  pointerMove(e: PointerEvent, pos: Vec2)
    @aimAt(pos)

  action1()
    if @held
      @release()

  playSoundAt = (sound: string, { x }: Vec2, volume=1) =>
    pan := (2 * x - @width) / @width
    playSound sound, 0, volume, pan

  addProjectile = (projectile: Projectile) =>
    @projectiles.push projectile
    { container } := projectile
    @effectContainer.addChild container
    @projectileGraphics.set projectile, container

  removeProjectile = (projectile: Projectile) =>
    @projectiles.splice @projectiles.indexOf(projectile), 1
    @effectContainer.removeChild @projectileGraphics.get(projectile)!
    @projectileGraphics.delete projectile

  increaseScore = (amount: number, peg: PegData) =>
    value := amount * @multiplier
    @score += value
    text := getScoreText(peg, value)

  handlePegCollisions = (projectile: Projectile) =>
    { x, y, radius: r } := projectile
    for peg of @pegs
      if collision := circularCollision(x, y, r, peg.x, peg.y, peg.r)
        switch peg.type
          when 'ghost'
            // TODO: This retriggers every frame with the bubbles
            @toRemove.add peg
            @triggerPeg peg, projectile
          when 'peg'
            // collision is the normal vector from center of ball to center of peg
            // reflect the velocity vector over the normal vector
            projectile.resolveCollision(@, collision)
            @hitSolid peg, projectile

        projectile.trigger(@, peg, collision)

        break

  aimAt = (pos: Vec2) =>
    if ball := @currentBall
      { launchVelocity, gravity } := ball

      a := angleOfReach @launcherPosition, pos, launchVelocity, gravity.y

      if a
        @launchAngle = a
        adjustAimDots @aimDots, @launcherPosition, a, launchVelocity, gravity

  get currentBall
    @balls[0]

  /**
  * Resolve a hit on a solid peg
  */
  hitSolid = (peg: PegData, source: Projectile) =>
    return if @toRemove.has peg

    @triggerPeg peg, source

    peg.hits--
    if peg.hits <= 0
      @hits.delete peg
      // shatter effect
      @toRemove.add peg
      return

    unless @hits.has peg
      @hits.add peg

      @pegGraphics.get(peg)!.tint = 0xffff00

  addMultiplierPegs = (pegs: PegData[]) =>
    shuffle(pegs.slice()).slice(0, 6).forEach (peg) =>
      peg.multiplier = 1

  triggerPeg = (peg: PegData, projectile: Projectile) =>
    if peg.multiplier
      @multiplierBase += peg.multiplier
      peg.multiplier = 0

    score := switch peg.type
      when 'peg'
        10
      when 'ghost'
        5
      else
        10

    projectile.source.xp++

    @increaseScore score, peg
    @playSoundAt 'boop', peg

  release = () =>
    if @balls.length
      if @held
        if ball := @currentBall
          @removeHitCooldown = 10
          ball.launch @launcherPosition, @launchAngle
          @activeBalls.push ball
          @held = false
          for each item of ball.items
            item.reset()
    else
      if victoryActive
        @game.replaceScene new Reward @game, itemRewards, 3, @game.popScene
      else
        @game.gameOver()

  resolveThrow = () =>
    @held = true
    @resolveHits()

  resolveHits = () =>
    // clear hits
    for hit of @hits
      index := @pegs.indexOf(hit)
      if index >= 0
        @pegs.splice index, 1
        @pegContainer.removeChild @pegGraphics.get(hit)!

    if @pegs.length !== @pegContainer.children.length
      debugger

    @hits.clear()

  removePeg = (peg: PegData): void =>
    @hits.delete peg
    index := @pegs.indexOf(peg)
    if index >= 0
      @pegs.splice @pegs.indexOf(peg), 1
      @pegContainer.removeChild @pegGraphics.get(peg)!

/**
Adjust aim dots to show the trajectory of the ball.
*/
function adjustAimDots(dots: Vec2[], source: Vec2, angle: number, v: number, gravity: Vec2)
  velocity := { x: cos(angle) * v, y: sin(angle) * v }
  t .= 0

  { x, y } .= source

  i .= 0
  l := dots.length
  while i < l
    for j .= 0; j < 5; j++
      t += dt
      velocity.x += gravity.x * dt
      velocity.y += gravity.y * dt
      x += velocity.x * dt
      y += velocity.y * dt

    dot := dots[i++]
    dot.x = x
    dot.y = y

  return dots

TAU := 2 * PI

export type PegData =
  type: 'peg' | 'ghost'
  x: number
  y: number
  r: number
  multiplier: number
  hits: number

export type LevelData =
  pegs: PegData[]
  targetScore: number

export function loadLevel(level: number): LevelData
  pegs: PegData[] := []
  r := 12

  level = (level % 3) + 1

  switch level
    when 0
      try
        return JSON.parse localStorage.getItem('customLevel')!
    when 1
      for i of [0...8]
        for j of [0...16]
          type := i % 2 ? 'ghost' : 'peg'

          x := j * 32 + 25 + (i % 2) * 12 + i * 9 + 3
          y := i * 32 + 100 + i + j

          pegs.push { type, x: x * 2, y: y * 2, r, multiplier: 0, hits: type is 'peg' ? 3 : 1 }

      return { pegs, targetScore: 300 }

    when 2
      for i of [1...8]
        n := i * i
        d := 20 * i
        phi := i * TAU / 9

        for j of [0...n]
          theta := j * TAU / n
          type := 'peg'

          x := 2 * (320 + d * cos(theta + phi) * 2)
          y := 2 * (180 + d * sin(theta + phi))

          pegs.push { type, x, y, r, multiplier: 0, hits: 3 }

      return { pegs, targetScore: 500 }

    when 3
      theta .= 0
      d .= 10
      for i of [1...160]
        x := 2 * (320 + d * cos(theta))
        y := 2 * (180 + d * sin(theta))
        theta += TAU / (8 + floor(i / 10))
        d += 2

        type := i % 2 ? 'ghost' : 'peg'

        pegs.push { type, x, y, r, multiplier: 0, hits: type is 'peg' ? 3 : 1 }

      return { pegs, targetScore: 1000 }


  return { pegs: [], targetScore: 0 }

export function makePegGraphic(peg: PegData)
  {type, x, y, r, multiplier} := peg

  pegGraphic := new Graphics circleContext
  pegGraphic.x = x
  pegGraphic.y = y
  pegGraphic.scale = r / 64

  if multiplier
    // orange peg
    pegGraphic.tint = 0xffa500
  else
    pegGraphic.tint = 0x00ffff

  if type is 'ghost'
    pegGraphic.alpha = 0.25

  return pegGraphic
