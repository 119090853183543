{
  Container
  Graphics
  BitmapText
} from pixi.js

type { Game, GameState } from ../game.civet

{ Level } from ./level.civet
{ Shop } from ./shop.civet
{ shuffle, rand, clamp, type Vec2 } from ../util.civet
{ floor } := Math

gridSize := 60

export class WorldMap
  stage := new Container
  game: Game
  //@ts-expect-error set in enter
  state: GameState
  currentRoom = -1
  interactive = false
  roomGfx: Map<number, Graphics> = new Map()
  startRooms: number[]
  paths: Map<number, number[]>
  visitedRooms: Set<number> = new Set()

  panActive = false

  roomLayer = new Container

  update(_dt: number): void
  draw(_t: number): void
    @roomGfx.forEach (gfx, roomNumber) =>
      if @visitedRooms.has(roomNumber)
        gfx.tint = 0x0080ff
      else if roomNumber is @currentRoom
        gfx.tint = 0x800080
      else if @canGoToRoom roomNumber
        gfx.tint = 0x20A040
      else
        gfx.tint = 0x808080

  enter(@state: GameState): void
    @interactive = true

  exit(): GameState
    @state

  interactionStart: Vec2?
  panLayerStart: Vec2?

  pointerDown(e: PointerEvent, pos: Vec2): void
    @panActive = true
    @interactionStart = pos
    @panLayerStart = {
      @roomLayer.x
      @roomLayer.y
    }

  pointerMove(e: PointerEvent, pos: Vec2): void
    if @panActive
      dx := pos.x - @interactionStart!.x
      dy := pos.y - @interactionStart!.y
      @roomLayer.x = floor @panLayerStart!.x + dx
      @roomLayer.y = floor @panLayerStart!.y + dy

  pointerUp(e: PointerEvent, pos: Vec2): void
    @panActive = false
  action1(): void

  goToRoom = (roomNumber: number) =>
    x := roomNumber % 7
    y := floor roomNumber / 7

    @visitedRooms.add roomNumber
    @currentRoom = roomNumber

    if y % 3 is 1
      @game.pushScene new Shop @game
    else
      @game.pushScene new Level @game

  canGoToRoom = (roomNumber: number) =>
    (or)
      @currentRoom is -1 and @startRooms.includes roomNumber
      @paths.get(@currentRoom)?.includes roomNumber

  @(@game: Game)
    background := new Graphics()
      .rect 0, 0, 1280, 720
      .fill 0xaeaeae
    @stage.addChild background

    { @startRooms, @paths } := generatePaths()

    @stage.addChild @roomLayer

    @paths.forEach (choices, roomNumber) =>
      x := roomNumber % 7
      y := floor roomNumber / 7
      choices.forEach (nextRoom) =>
        nextX := nextRoom % 7
        nextY := floor nextRoom / 7

        @roomLayer.addChild new Graphics()
          .moveTo x * gridSize + 20, y * gridSize + 20
          .lineTo nextX * gridSize + 20, nextY * gridSize + 20
          .stroke 0x000000

    for roomNumber of @paths.keys()
      x := roomNumber % 7
      y := floor roomNumber / 7

      roomGfx := new Graphics()
        .rect x * gridSize + 20, y * gridSize + 20, 40, 40
        .fill 0xffffff

      roomGfx.interactive = true
      roomGfx.onclick = () =>
        if @canGoToRoom(roomNumber)
          @goToRoom(roomNumber)

      @roomGfx.set roomNumber, roomGfx
      @roomLayer.addChild roomGfx

      if y % 3 is 1
        shopText := new BitmapText
          text: '$',
          style:
            fontFamily: 'm5x7',
            fontSize: 30
            fill: 0x000000

        shopText.x = x * gridSize + 20 + gridSize / 2 - shopText.width / 2
        shopText.y = y * gridSize + 20

        @roomLayer.addChild shopText

// starting out similar to slay the spire
// https://steamcommunity.com/sharedfiles/filedetails/?id=2830078257
// 7 wide 15 tall

function generatePaths()
  startRooms .= Array.from new Set [rand(7), rand(7), rand(7), rand(7)]

  // at least 2 start rooms
  if startRooms.length is 1
    startRooms = shuffle([0..6]).slice(-2)

  paths := new Map<number, number[]>()

  // create a path from each start room to the top
  startRooms.map (startRoom) =>
    roomNumber .= startRoom
    x .= roomNumber
    y .= 1
    while y < 15
      x += rand(3) - 1
      x = clamp x, 0, 6
      nextRoom := 7 * y + x
      if choices := paths.get(roomNumber)
        choices.push nextRoom
      else
        paths.set(roomNumber, [nextRoom])

      roomNumber = nextRoom
      y += 1

  return {
    startRooms,
    paths
  }
