{ Container, type Texture } from pixi.js

type { Vec2 } from ./util.civet
type { Item } from ./item.civet
type { Skill } from ./skill.civet
type { Level, PegData } from ./scenes/level.civet

{ items, type Projectile } from ./item.civet

{ bounce, physicsUpdate, randomItem, rand } from ./util.civet

{
  abs
  cos
  min
  sin
} := Math

/**
A thrown ball in the playfield.
*/
export class Ball implements Projectile
  name = "Ball"
  x = 320
  y = 0
  radius = 16
  rotation = 0
  rotationVelocity = 0
  velocity: Vec2 = { x: 0, y: 0 }
  items: Item[] = []
  skills: Skill[] = []
  active = true
  launchVelocity = 450
  gravity = { x: 0, y: 600 }
  ephemeral = false
  xp = 0
  lvl = 1

  source = @

  texture: Texture

  constructor(@texture: Texture, @name: string)
    // m4a1 := items.find .name is "M4A1"
    // @addItem m4a1!
    // pumpAction := items.find .name is "Pump Action"
    // @addItem pumpAction!

  get xpProgress(): number
    min 1, @xp / @xpToNextLevel

  get xpToNextLevel(): number
    XP_AMOUNTS[@lvl - 1]

  addItem(item: Item): void
    @items.push item.clone()

  launch(position: Vec2, angle: number)
    @x = position.x
    @y = position.y
    @velocity.x = cos(angle) * @launchVelocity
    @velocity.y = sin(angle) * @launchVelocity

  update(level: Level, dt: number): void
    physicsUpdate(@, dt)

    for each item of @items
      item.onUpdate?(level, @, dt)

  resolveCollision(level: Level, collision: Vec2): void
    pos := { x: @x + collision.x, y: @y + collision.y }
    level.playSoundAt "boing", pos, 3

    bounce(@, collision)

  trigger(level: Level, peg: PegData, collision: Vec2)
    shooting .= false
    // items/powerups
    for each item of @items
      // Only one gun item can be active at a time
      if item.type is 'gun'
        if shooting is false and item.ammo > 0
          shooting = true
          item.onHit(level, @, peg, collision)
        else
          // already shooting or out of ammo, go to next item
      else // other items trigger always
        item.onHit(level, @, peg, collision)

  onPegActivate(level: Level, peg: PegData): void
    for each skill of @skills
      skill.onPegActivate(level, @, peg)

  handleBallCollision(): void

  /** placeholder until projectile and ball update/draw is unified */
  container = new Container
  /** placeholder until projectile and ball update/draw is unified */
  draw(): void

XP_AMOUNTS := [
  10
  25
  75
  150
  250
  500
  1000
  2500
]

export NAMES := [
  // A Scanner Darkly
  [
    "Barris"
    "Bob"
    "Luckman"
    "Donna"
    "Fred"
    "Arctor"
    "Hank"
    "Bruce"
  ]

  // Rick and Morty
  [
    "Morty"
    "Rick"
    "Summer"
    "Beth"
    "Jerry"
    "Tammy"
  ]

  // Dostoevsky
  [
    "Sonya"
    "Marmeladov"
    "Luzhin"
    "Pulcheria"
    "Dounia"
    "Porfiry"
    "Zametov"
    "Nastasya"
  ]

  // The Wire
  [
    "McNulty"
    "Bunk"
    "Stringer"
    "Avon"
    "Omar"
    "Bodie"
    "D'Angelo"
    "Kima"
    "Daniels"
    "Bubbles"
    "Marlo"
  ]

  // X-Com
  [
    "Vahlen"
    "Shen"
    "Bradford"
    "Central"
    "Annette"
    "Zhang"
    "Van Doorn"
    "Friendly"
    "Furies"
    "Falcon"
    "Valkyrie"
    "Armand"
  ]

  // The Expanse
  [
    "Holden"
    "Naomi"
    "Amos"
    "Alex"
    "Miller"
    "Dawes"
    "Julie"
  ]

  // Firefly Series
  [
    "Mal"
    "Zoe"
    "Wash"
    "Jayne"
    "Kaylee"
    "Simon"
    "River"
    "Inara"
    "Book"
  ]

  // Blippi
  [
    "Steezy"
    "Stevin"
    "Blip"
    "Grossman"
  ]

  [ // C Town
    "Adam"
    "Nick"
    "Stav"
  ]
]
